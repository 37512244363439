import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import StyledComponentsRegistry from "@/src/lib/AntdRegistry"
import ApplicationRouter from "@/src/router";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import SwapWalletAnalyticsProvider from "./SwapWalletAnalyticsProvider"

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <StyledComponentsRegistry>
            <SwapWalletAnalyticsProvider>
                <BrowserRouter>
                    <ApplicationRouter/>
                </BrowserRouter>
            </SwapWalletAnalyticsProvider>
        </StyledComponentsRegistry>
    </React.StrictMode>
);

serviceWorkerRegistration.register();
