import {Button, ConfigProvider, Space, theme, Typography} from "antd";
import React from "react";
import config from "@/src/config";
import zuluTheme from "@/src/utils/theme";

const {Text} = Typography

function WalletButton(props) {
    const Icon = props.icon

    return (
        <ConfigProvider direction={config.dir()}>
            <Space
                onClick={() => !props.disabled && props.onClick()}
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 78,
                    gap: 0,
                }}
                direction="vertical"
            >
                {/*{button}*/}
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                colorPrimary: zuluTheme.token.colorInvert(1),
                                colorPrimaryHover: zuluTheme.token.colorInvert(.8),
                                colorPrimaryActive: zuluTheme.token.colorInvert(.7),
                                lineWidth: 0,
                            },
                        },
                    }}
                >
                    <Button
                        type={"primary"}
                        shape="circle"
                        disabled={props.disabled}
                        // size={100}
                        style={{
                            width: 50,
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 10,
                            cursor: "pointer",
                        }}
                    >
                        <Icon style={{fontSize: 20, color: zuluTheme.token.colorBgBase}}/>
                    </Button>
                </ConfigProvider>
                <Text style={{
                    fontSize: 13,
                    fontWeight: 400,
                    textAlign: 'center',
                }}>{props.text}</Text>
            </Space>
        </ConfigProvider>
    )
}

export default WalletButton
