const RU_LOCAL = {
    login: 'Войти / Зарегистрироваться',
    mainPageTitle: 'Ваш портал в мир криптовалюты',
    mainPageItems1: 'Эксклюзивный кошелек',
    mainPageItems2: 'Торговля',
    mainPageItems3: 'Удобство использования',
    mainPageItems4: 'Высокая безопасность',

    letsStart: 'Пусть начнется приключение!',
    letsStartSubtitle: 'Ваш цифровой кошелек с высочайшим уровнем безопасности',
    insertYourPhoneNumber: 'Введите свой номер мобильного телефона',
    start: 'Начать',
    logout: 'Выход',

    adChequeDescription: 'Используя рекламный чек, вы можете попросить пользователей посетить ваши каналы в обмен на выплату суммы приза. Кроме того, пользователи могут получить приз за обмен вашим чеком.',
    totalPrizeAmount: 'Общая сумма приза',
    activationPrize: 'Приз активации',
    referralPrize: 'Приз за реферал (Уровень Один)',
    referralPrizeLayer2: 'Приз за реферал (Уровень Два)',
    antiBot: 'Анти-бот',
    antiBotDescription: 'SwapWallet ловит ботов, используя CAPTCHA, а также идентифицирует и блокирует фальшивые аккаунты с помощью алгоритмов машинного обучения и анализа поведения пользователей.',
    dollarValue: 'Значение',
    supportCode: 'Код поддержки',
    invoiceCode: 'Идентификатор счета',
    swapwalletFee: 'Комиссия SwapWallet',
    invoiceTitle: 'Продукт',
    GIVEAWAY: 'Розыгрыш',
    AD_CHEQUE: 'Рекламный Чек',
    AD_CHEQUE_TOP_UP: 'Пополнение для Рекламного Чека',
    successfulPayment: 'Успешная оплата',
    reCharge: 'Перезарядить',
    delete: 'Удалить',
    new: 'Новый',

    applicationSettlement: 'Расчеты платежного шлюза',
    paymentTitleWebsite: 'Платежный шлюз криптовалюты SwapWallet',
    logoDescription: 'SwapWallet; Инновации в обмене криптовалюты',
    remainingTime: 'Оставшееся время:',
    paymentTitle: 'Торговец',
    paymentInfo: 'Информация о торговце',
    paymentWebsite: 'Веб-сайт торговца',
    howMuch: 'Сумма',
    verificationStatus: 'Подтверждение',
    verified: 'Проверено SwapWallet',
    notVerified: 'Не проверено',
    returningToSource: 'Возвращение на веб-сайт торговца...',
    paymentExpired: 'Платеж истек',
    returnToPayment: 'Вернуться',
    moreInfo: 'Дополнительная информация',

    addNewPhone: 'Добавить новый телефон',
    itTakesMoreTimeThanUsual: 'Процесс верификации занимает больше времени, чем обычно. Как только верификация будет завершена, мы вас уведомим.',
    confirmOwnerOfPhoneTitle: 'Подтвердите владение номером телефона',
    confirmOwnerOfPhoneDescription: 'Принадлежит ли мобильный номер, который у вас есть в Telegram, вам?',
    useOTPInstead: 'Чтобы добавить свой мобильный номер, используйте метод проверки по SMS',
    yes: 'Да',
    no: 'Нет',

    info: "Информация",
    itsLegalTelegramPremium: "- Telegram Premium будет активировано законным путем, мгновенно и без необходимости входа в вашу учетную запись.",
    youShouldHaveUsername: '- На момент активации вашей учетной записи должно быть имя пользователя.',
    youShouldNotToChangeUsername: '- Пожалуйста, воздержитесь от изменения вашего имени пользователя до завершения процесса.',
    telegramPremiumWasSuccessful: 'Операция прошла успешно, пожалуйста, не меняйте свое имя пользователя до подтверждения премиум-статуса Telegram',
    upgradeToPremium: 'Повысить до Премиума',
    'TG-PREMIUM-12M': '1 год',
    'TG-PREMIUM-6M': '6 месяцев',
    'TG-PREMIUM-3M': '3 месяца',
    backToHomeScreen: 'Вернуться на главный экран',

    descriptionTitle: 'Опыт лучшей покупки и продажи цифровой валюты с нами',
    description1Title: 'Эксклюзивный кошелек',
    description1Text: 'Покупайте и продавайте популярные криптовалюты, отслеживайте свой баланс в одном месте, используя выделенный кошелек и уникальный адрес для прямых депозитов криптовалюты',
    description2Title: 'Возможность подключения к майнерам',
    description2Text: 'Легко подключайте адрес своего кошелька к майнерам и выводите свои заработанные средства',
    description3Title: 'Высокая безопасность',
    description3Text: 'Вся информация будет храниться в зашифрованном виде на репутабельных центрах данных AWS',
    description4Title: 'Конкурентоспособные комиссии',
    description4Text: 'Наши комиссии очень низкие (0,1%) и уменьшаются с увеличением транзакций',
    description5Title: 'Оплата быстрыми картами',
    description5Text: 'Внесение и вывод в местной валюте с использованием всех карт участников Shetab',
    description6Title: 'Поддержка 24/7',
    description6Text: 'Одним из основных критериев выбора обмена цифровой валюты является его поддержка. Команда поддержки Zulutex доступна онлайн 24/7, чтобы помочь вам, обеспечивая возможность использования услуг обмена без проблем',

    gatewayOutOfOrder: 'Платежный шлюз недоступен, пожалуйста, используйте банковский перевод',
    contactUsInfo: '021228767753 | support@Zulutex.app',
    contactUsDetails: 'Онлайн-поддержка доступна круглосуточно. Вы также можете связаться с нами по телефону с понедельника по среду, с 10:00 до 16:00',
    copyright: 'Все права на этот веб-сайт принадлежат Zulutex (v1.0.0)',
    totalBalance: 'Баланс счета',

    paymentTransaction: 'Покупка в магазине',
    giveawayPrize: 'Получение приза (Airdrop)',
    adCheque: 'Активировать рекламный чек',
    adChequeReferral: 'Реферальный рекламный чек',
    creditReason: 'Тип приза',
    giveawayParticipate: 'Участие в лотерее',
    value: 'Стоимость (в долларах)',
    product: 'Продукт',
    paidWith: 'Оплаченная сумма',
    invoiceId: 'Идентификатор квитанции',
    shop: 'Магазин',

    giveawayEndTime: 'Дата окончания',
    giveawayTitle: 'Текст лотереи',
    banner: 'Баннер',
    titlePlaceholder: '🎁 Лотерея 1000 Tether... (markdown, максимальная длина: 500)',
    marketPlace: 'Магазин',
    giveaway: 'Лотерея',
    giveawayDescription: 'Подходящий инструмент для продвижения вашего канала, привлечения новых пользователей или вознаграждения текущих пользователей.',
    prizes: 'Призы',
    totalPrizes: 'Общая сумма призов',
    giveawayLimitations: 'Ограничения',
    tokenType: 'Тип приза',
    addPrize: 'Добавить приз',
    addLimit: 'Добавить ограничение',
    telegramPremium: 'Телеграм Премиум',
    telegramPremiumDescription: "Сразу и легально улучшите свой Телеграм до Премиум, без посредников, используя монеты TON.",
    productOutOfOrder: 'Этот продукт еще не активирован для вас, пожалуйста, свяжитесь с поддержкой.',

    links: 'Zulutex',
    about: 'О нас',
    tosTitle: 'Условия использования и политика конфиденциальности',
    levels: 'Уровни пользователя',

    loginToZulutex: "Вход в Zulutex",
    toStartInterPhoneNumber: "Пожалуйста, введите свой мобильный номер, чтобы начать",
    insertOtpCode: "Введите полученный одноразовый SMS-код",
    phoneNumberShouldBeForYour: "Мобильный номер должен принадлежать вам",
    beSureInDomainBefore: "Убедитесь, что вы находитесь в домене ",
    beSureInDomainAfter: "",

    loginTitle: "Единственный кошелек, который вам нужен",
    loginSubTitle: "Войдите в мир криптовалют всего несколькими кликами!",
    sendOtp: "Отправить код подтверждения",

    send: 'Отправить',
    receive: 'Получить',
    swap: 'Обмен',

    sharePhoneNumberRequest: 'Чтобы воспользоваться всеми услугами SwapWallet, пожалуйста, поделитесь своим мобильным номером телефона.',
    upgradeKycLevelRequest: 'Для внесения и снятия средств в риалах необходимо завершить процесс верификации личности.',

    security: 'Безопасность',
    settings: 'Настройки',
    creditCards: 'Кредитные карты',

    rialOperations: 'Операции с томанами',
    depositRial: 'Депозит томанов',
    withdrawRial: 'Вывод томанов',
    kyc: 'KYC',

    topup: 'Пополнить',
    topupDetails: 'У вас недостаточно средств для выполнения этой транзакции. Пожалуйста, воспользуйтесь одним из следующих вариантов для увеличения баланса своего кошелька.',

    verifyYourAccount: 'Подтвердите свою учетную запись',
    verifyYourAccountDescription: 'Для увеличения баланса с использованием кредитной карты ваша учетная запись должна быть подтверждена.',
    verifyAccountButton: 'Подтвердить',

    buyWithCreditCard: 'Купить с кредитной карты',
    buyWithWallet: 'Депозит с другого кошелька',


    copy: 'Копировать',
    share: 'Поделиться',
    sendFromAnotherWallet: 'Отправить с другого кошелька',

    comingSoon: 'Скоро в продаже',

    tonCoin: 'TonCoin',
    tether: 'Tether',
    tron: 'Tron',
    ethereum: 'Ethereum',
    binanceCoin: 'BinanceCoin',
    bitCoin: 'BitCoin',
    sonix: 'Sonix',

    currentLevel: 'Текущий уровень',
    'LEVEL_0': 'Базовый',
    'LEVEL_1_GLOBAL': 'Уровень 1',
    'LEVEL_1_IR': 'Уровень 1',
    'LEVEL_2_IR': 'Уровень 2',


    AYANDEH: 'Ayandeh',
    EGHTESAD_NOVIN: 'Eghtesad Novid',
    ANSAR: 'Ansar',
    IRAN_ZAMIN: 'Iran Zamin',
    PARSIAN: 'Parsian',
    PASARGAD: 'Pasargad',
    POST_BANK: 'Post Bank',
    TEJARAT: 'Tejarat',
    TOSEE_TAAVON: 'Tosee Taavon',
    TOSEE_SADERAT: 'Tosee Saderat',
    HEKMAT_IRANIAN: 'Hekmat Iranian',
    KHAVARMIANEH: 'Khavarmianeh',
    DAY: 'Day',
    RESALAT: 'Resalat',
    REFAH_KARGARAN: 'Refah Kargaran',
    SAMAN: 'Saman',
    BLUBANK: 'ḃluBank',
    SEPAH: 'Sepah',
    SARMAYEH: 'Sarmayeh',
    SINA: 'Sina',
    SHAHR: 'Shahr',
    SADERAT: 'Saderat',
    SANAT_MADAN: 'Sanat Madan',
    MEHR_IRAN: 'Mehr Iran',
    GHAVAMIN: 'Ghavamin',
    KARAAFARIN: 'Karafarin',
    KESHAVARZI: 'Keshavarzi',
    GARDESHGARI: 'Gardeshgari',
    MARKAZI: 'Markazi',
    MASKAN: 'Maskan',
    MELLAT: 'Mellat',
    MELLI: 'Melli',
    KOSAR: 'Kosar',
    MELALL: 'Melall',
    TOSEE: 'Tosee',

    SWAP_WALLET: 'Zulutex',
    TRUST_WALLET: 'Trust Wallet',
    METAMASK: 'Metamask',
    TON_KEEPER: 'Ton Keeper',
    TONHUB: 'Ton Hub',

    SIGNUP: 'Регистрация',
    PHONE_NUMBER: 'Подтвердите номер телефона',
    PERSONAL_INFORMATION: 'Подтвердите личную информацию',
    NATIONAL_ID_IMAGE: 'Изображение национального удостоверения личности',
    SELFIE_VIDEO: 'Видео с селфи',

    setPhoneNumber: 'Поделиться номером телефона',
    setPersonalInfo: 'Установить личную информацию',
    setCreditCard: 'Установить кредитную карту',
    setLocation: 'Установить местоположение',
    setHomePhoneNumber: 'Установить домашний номер телефона',
    setVideo: 'Установить видео с селфи',
    signupInWebsiteOrTelegram: 'Регистрация на веб-сайте или в Telegram',
    sorryPersonalInformationNotFound: 'Извините, но ваша информация не найдена',
    pleaseFillFormWithYourPersonalInfo: 'Пожалуйста, заполните форму своей личной информацией',
    pleaseInsertYourNationalCode: 'Пожалуйста, введите свой национальный идентификационный код',
    pleaseInsertYourBirthDate: 'Пожалуйста, введите свою дату рождения',
    iWillAcceptAllRules: 'Я прочитал и принимаю все условия использования услуг Zulutex.',
    confirm: 'Подтвердить',

    pleaseSelectCoin: 'Пожалуйста, выберите монету',
    pleaseOnlySendToThisNetwork: 'Отправляйте только __COIN__ (__NETWORK__) на этот адрес. Отправка других монет может привести к постоянной потере',
    withKYCYouHaveLowerLimits: 'С KYC у вас ниже лимиты на депозит и вывод',

    startKYC: 'Начать KYC',
    continueKYC: 'Продолжить процесс KYC',
    neededDocuments: 'Номер телефона, национальный-идентификатор, дата рождения',
    neededOneMinutes: '1 минута',
    depositRialCashInCode: '+ Без ограничений при использовании идентификационного кода депозита',
    withdrawRialLevelOne: 'Возможность вывода',
    depositRialLevelOne: 'Депозит фиата',
    depositCryptoLevelOne: 'Депозит крипты: без ограничений',
    withdrawCryptoLevelOne: 'Вывод крипты: $20 в день',

    cashInCodeNewOne: '- Используя идентифицированные депозиты, вы можете увеличивать свой баланс без ограничений через депозиты PAIA.',
    cashInCodeNewTwo: '- Идентифицированные депозиты подтверждаются автоматически, и нет необходимости информировать SwapWallet о вашем депозите.',
    cashInCodeNewThree: '- После депозита ваш баланс будет автоматически увеличен в течение часа после первого цикла PAIA.',

    cashInCodeWarningOne: '- Ни при каких обстоятельствах не используйте метод моста.',
    cashInCodeWarningTwo: '- Источник депозита должен принадлежать вам.',
    cashInCodeWarningThree: '- Ввод идентификатора депозита обязателен.',

    cashInCodeWarningTitle: 'Важные моменты перед идентифицированным депозитом',

    liveness: 'Проверка живости',
    canBeTakeTime: '(Загрузка данных может занять некоторое время, пожалуйста, будьте терпеливы)',
    ok: 'Ok',
    livenessLearning: 'Глядя в камеру, медленно поворачивайте голову, чтобы все точки стали зелеными',

    selfieVideo: 'Видео с селфи',
    livenessDescription: 'Держите камеру перед собой и медленно поворачивайте голову, пока весь круг не станет зеленым.',
    selfieVideoDescription: 'Глядя в камеру, нажмите кнопку записи и прочитайте текст ниже четким голосом',

    nationalIdScan: 'Сканирование документов',
    frontScanDescription: 'Пожалуйста, держите свою умную национальную ID-карту в указанной области. Используйте среду с хорошим освещением и темным фоном.',
    backScanDescription: 'Теперь поверните свою национальную ID-карту и, как и раньше, держите карту в указанной области. Используйте среду с хорошим освещением и темным фоном.',

    uploadDocuments: 'Загрузка',
    uploadDocumentsDescription: 'Загрузка информации на сервер, пожалуйста, будьте терпеливы и держите эту страницу открытой до завершения процесса',
    uploadDocumentsDone: 'После проверки информации специалистами мы свяжемся с вами через текстовое сообщение с результатами',
    uploadFailed: 'Ошибка при загрузке документов на сервер. Нажмите кнопку ниже, чтобы попробовать снова',
    back: 'Назад',

    uploadNationalId: 'Загрузить Документы',
    uploadNationalIdDescription: 'Пожалуйста, выберите высококачественное изображение лицевой и обратной стороны вашей национальной умной карты.',
    frontOfNationalId: '- Лицевая сторона удостоверения личности',
    backOfNationalId: '- Обратная сторона удостоверения личности',
    upload: 'Загрузить',

    neededDocuments2: 'НацИД и видео с селфи',
    neededOneMinutes2: '3 минуты',
    depositRialLevelOne2: 'Неограниченный депозит в фиате',
    depositCryptoLevelOne2: 'Вывод фиата',
    withdrawCryptoLevelOne2: 'Вывод криптовалюты: $2K ежедневно',

    verifyPhoneNumber: 'Подтвердить номер телефона',
    verifyPhoneNumberDesc: 'Подтвердите свой номер телефона, используя один из следующих методов.',
    verifyPhoneNumberDesc2: '(Номер телефона должен быть ваш)',
    verifyPhoneWithShareNumber: 'Подтвердить с использованием общего номера телефона',
    verifyPhoneWithSMS: 'Подтвердить с использованием SMS',

    kycUserInformation: 'Информация о пользователе',
    kycUserInformationDesc: 'Пожалуйста, заполните форму своей личной информацией.',
    kycUserInformationDesc2: '🔒 Ваши идентификационные данные зашифрованы и надежно хранятся у нас, и будут использоваться только для предотвращения отмывания денег и фишинговых атак. Кроме того, если данные противоречат системе гражданской регистрации, процесс верификации вашей личности столкнется с проблемами.',

    memoBasedAlertText: "Чтобы внести эту криптовалюту, необходимо ввести правильный комментарий в дополнение к адресу. В противном случае ваши средства будут потеряны.",
    memoBasedIConfirmed: "Я понял",
    memoBasedDepositWarning: "Обязательно введите комментарий для этой криптовалюты в дополнение к адресу. В противном случае ваши средства будут потеряны.",

    assets: 'Активы',

    tosHeader: 'Условия использования',
    tosHeaderDesc: 'Внимательно ознакомьтесь с условиями использования Zulutex перед подтверждением',
    tos: 'Пользователи сайта подтверждают, что все их действия в Zulutex должны соответствовать установленным рамкам, и они не будут заниматься никакой деятельностью за их пределами.\n' +
        '\n' +
        'Для использования услуг Zulutex требуется аутентификация, и пользователи обязаны загрузить свою правильную информацию. Очевидно, что в случае нарушения аутентификации ответственность лежит на нарушителе, и Zulutex имеет право прекратить предоставление услуг указанному пользователю и передать нарушения компетентным органам. Zulutex обязуется защищать персональную информацию своих пользователей.\n' +
        '\n' +
        'Пользователи Zulutex обязуются использовать услуги сайта Zulutex только для себя, и ответственность за использование услуг Zulutex кем-либо, кто не прошел процесс аутентификации, лежит на пользователе. Номер счета, объявленный на сайте, а также адреса кошельков для вывода криптовалюты, должны принадлежать пользователю, и пользователям запрещено предоставлять адрес кошелька, принадлежащий другим людям.\n' +
        '\n' +
        'Пользователи Zulutex соглашаются сотрудничать с командой Zulutex с целью обеспечения безопасности активов своего учетного записи, если это будет выявлено экспертами службы поддержки Zulutex. Также, согласно поручению судебных учреждений, вывод криптовалюты может быть ограничен в течение 24-72 рабочих часов после времени депозита.\n' +
        '\n' +
        'Пользователи Zulutex признают, что они осведомлены о рисках, связанных с инвестированием в цифровые валюты, и инвестируют и обмениваются с этими знаниями.\n' +
        '\n' +
        'Zulutex, как онлайн-рынок для обмена цифровыми валютами, не несет ответственности за способ, которым пользователи торгуют и результирующие прибыли и убытки.\n' +
        '\n' +
        'Любая небрежность пользователей в обеспечении безопасности информации, включая пароль пользователя, является ответственностью пользователя, и Zulutex не несет никакой ответственности. Настоятельно рекомендуется использовать сложный и безопасный пароль, а также код двухфакторной аутентификации.\n' +
        '\n' +
        'Zulutex гарантирует, что активы пользователей хранятся у них на хранение наилучшим образом и с наивысшими стандартами безопасности. В случае любой проблемы безопасности Zulutex обязан возместить ущерб.\n' +
        '\n' +
        'Если вы хотите вывести цифровые валюты, пользователь несет ответственность за предоставление правильного адреса кошелька. Zulutex не несет ответственности за любые проблемы, включая ошибки при вводе правильного адреса, ошибки в адресе, проблемы с целевым кошельком и блокировку активов пользователей в целевом кошельке.\n' +
        '\n' +
        'Zulutex не несет ответственности за депонирование токенов или монет на неправильной платформе или монет, которые не поддерживаются в Zulutex, и из-за механизма хранения в холодном хранилище эти токены невозможно извлечь с использованием кошелька сторонней компании. Поэтому пользователь несет ответственность за любой неправильный депозит, и у пользователя нет права предъявлять претензии.\n' +
        '\n' +
        'Пользователь признает, что, за исключением случаев обязательства Zulutex, у него не будет права предъявлять какие-либо требования и претензии к сайту Zulutex, менеджерам, сотрудникам и лицам, связанным с этим сайтом.\n' +
        '\n' +
        'В случае смерти пользователя Zulutex будет иметь право внести средства пользователя на счет компании, указанный пользователем при регистрации. Это поручено Zulutex для конвертации его в эквивалент в фиате по текущей цене и внесение его на указанный счет, и это право и привилегия предоставлены Zulutex пользователем. Следует отметить, что временным критерием для расчета цены продажи криптовалютных активов пользователя является представление положительных документов бенефициаром и подтверждение их подлинности юридическим отделом Zulutex. Знание и понимание положений настоящего пункта, пользователь открыл учетную запись пользователя в Zulutex, и пользователь, третьи лица, наследники или юридические представители не будут иметь права протестовать или предъявлять претензии в этом отношении в будущем.\n' +
        '\n',

    kycWasSuccessful: 'Ваша KYC прошла успешно',
    kycWasSuccessfulDesc: 'Поздравляем! Ваш уровень учетной записи повышен до "Уровень Один", на этом уровне вы можете депозитировать до 15 миллионов томанов ежедневно.',

    rialDeposit: 'Депозит томанов',

    kycWasNotSuccessful: 'KYC не прошла успешно',
    kycWasNotSuccessfulDesc: 'Пожалуйста, проверьте введенную вами информацию еще раз. Обратите внимание, что номер мобильного телефона должен принадлежать введенному национальному коду.',
    retry: 'Повторить',

    redirectingToPaymentGateway: 'Перенаправление на платежный шлюз',
    redirectToPaymentGateway: 'Перейти к платежному шлюзу',
    connectionFromOutsideOfIran: 'Подключение извне Ирана',
    sorryButYouShouldTurnOffYourVPN: 'Извините, подключение извне Ирана не разрешено платежными шлюзами. Пожалуйста, отключите свой VPN и нажмите кнопку ниже',

    returnToWallet: 'Вернуться в кошелек',
    returnToWebsite: 'Вернуться в кошелек',
    yourPaymentWasSuccessful: 'Ваш платеж прошел успешно',
    yourPaymentWasNotSuccessful: 'Ваш платеж не прошел успешно',
    yourWalletHasBeenCharged: 'Ваш кошелек был пополнен, вы можете вернуться в свой кошелек, нажав кнопку ниже',
    nowYouCanTurnVPNOnAgain: 'Теперь вы можете снова включить свой VPN',
    paymentId: 'Идентификатор платежа',
    orderId: 'Идентификатор заказа',
    contactSupport: 'Свяжитесь с поддержкой',
    ifAmountHasBeenDeductedItWillRefund: 'Если сумма была удержана с вашего счета, она будет возвращена на ваш счет в течение 72 часов',
    notePaymentShouldDoneUsingSelectedCreditCard: 'Примечание: Оплата должна быть произведена с использованием выбранной кредитной карты',

    connectionError: 'Ошибка подключения',
    connectionToPaymentGatewayFailed: 'Ошибка подключения к платежному шлюзу',
    pleaseSelectACardOrAddANewCard: 'Пожалуйста, выберите кредитную карту или добавьте новую',
    pleaseInsertTheAmount: 'Пожалуйста, вставьте сумму',
    pay: 'Оплатить',
    holdToPay: 'Удерживайте, чтобы оплатить',
    holdToSwapAndPay: 'Удерживайте, чтобы обменять и оплатить',
    holdToTopUp: 'Пополнить',
    youDoNotHaveEnoughMoneyToPay: 'У вас недостаточно денег для оплаты',
    minimumAmountStart: 'Приблизительно',
    minimumAmountEnd: 'с вашего баланса будет конвертировано в желаемую валюту по лучшему рыночному курсу для оплаты этого чека',
    cardNumberIsNotValid: 'Номер кредитной карты недействителен',
    cardSuccessfullyAdded: 'Кредитная карта успешно добавлена',
    cardFailedToAdd: 'Не удалось добавить кредитную карту',
    addANewCard: 'Добавить новую кредитную карту',
    warning: 'Предупреждение',
    youOnlyCanAddCreditCardThatIsForYou: 'В соответствии с указанием киберполиции для предотвращения фишинга и отмывания денег возможно добавление только карты, соответствующей вашему номеру мобильного телефона. Оплата возможна только с заранее определенной картой.',
    add: 'Добавить',
    cancel: 'Отмена',
    thereIsNoCreditCard: 'Нет кредитной карты',
    minimumDepositAmountIs150: 'Минимальная сумма депозита - 5 000 томанов',
    maximumDepositAmountIs25M: 'Максимальная сумма депозита - 25 миллионов томанов',

    addANewIban: 'Добавить новый IBAN',
    ibanSuccessfullyAdded: 'Новый IBAN успешно добавлен',
    youOnlyCanAddIbanThatIsForYou: 'Согласно приказу полиции ФАТА, чтобы предотвратить фишинг и отмывание денег, вы можете добавить только тот IBAN, который соответствует вашему номеру мобильного телефона. Расчет возможен только на ваш собственный счет.',
    thereIsNoIban: 'Нет доступных IBAN',
    minimumWithdrawAmountIs5: 'Минимальная сумма снятия - 5 000 томанов',
    maximumWithdrawAmountIs100M: 'Максимальная суточная сумма снятия - 100 миллионов томанов',
    pleaseSelectAIbanOrAddANewIban: 'Выберите существующий IBAN или добавьте новый',
    settle: 'Вывести',
    transactionFeeIsForBroker: "(Zulutex не является получателем этой комиссии)",
    receiverName: 'Имя получателя',
    ifPossibleWeUseInstantWithdraw: '⚡ При возможности расчет будет произведен мгновенно и в течение 15 минут.',
    receiveTime: 'Время зачисления',
    receiveTimeResult: 'В первом цикле ACH следующего периода',
    sentToBank: 'Внести на банковский счет',
    ibanNumber: 'IBAN получателя',
    gateway: 'Платежный шлюз',
    cashInCode: 'Код внесения',
    cashInCodeDescription: 'Отправляя сумму на указанный номер счета, пополняйте свой счет в Rial без ограничений. Обязательно введите созданный код в разделе идентификационного номера платежа на мобильном или интернет-банке или на банковском чеке. После внесения депозита сумма автоматически будет зачислена на ваш счет.',
    cashInCodeNumber: 'Код внесения:',
    iban: 'IBAN:',
    ibanNumberTooltip: 'Номер IBAN',
    ibanNumberTooltipCopied: 'Номер IBAN скопирован',
    accountNumber: 'Номер счета:',
    accountNumberTooltip: 'Номер счета',
    accountNumberTooltipCopied: 'Номер счета скопирован',
    accountHolderName: 'Имя держателя счета:',
    noticeUseCashInCode: 'Должен быть введен в разделе Код внесения',
    cashInCodeDescriptionTwo: 'Депозит возможен всеми методами оплаты, за исключением моста. Обратите внимание, что исходный счет должен принадлежать вам.',

    buyFromBankGateway: 'Депозит через Шлюз',
    cashInCodeName: 'Депозит через банковский перевод',
    ibanNumberSender: "IBAN отправителя",

    market: 'Рынок',
    payAmount: 'Сумма к оплате',
    receiveAmount: 'Сумма получения',
    tradeRate: 'Обменный курс',
    bankFee: 'Банковская комиссия',
    cardNumber: 'Номер кредитной карты',
    refId: 'Ref-Id',
    hash: 'Хеш транзакции',
    uniqueId: 'Уникальный идентификатор транзакции',

    sufficientBalance: 'Достаточный баланс',
    transactionWasSuccessful: 'Транзакция прошла успешно',
    transactionWasNotSuccessful: 'Транзакция не удалась',
    quickSwap: 'Быстрый обмен',
    fee: 'Комиссия',
    youPay: 'Вы платите',
    youReceive: 'Вы получаете',
    balance: 'Баланс',
    increaseBalanceByRequiredAmount: 'Увеличьте баланс на необходимую сумму',

    transferToAddress: 'Перевести на',
    receiveFromAddress: 'Получить от',
    swapTo: 'Обменять __SOURCE__ на __DEST__',
    thisCoinIsNotActiveYet: 'Эта монета еще не активна',
    thereIsNoTransaction: 'Нет транзакции',

    selectToken: 'Выбрать монету',
    selectNetwork: 'Выбрать сеть',
    selectNetworkPlaceHolder: 'Пожалуйста, выберите сеть для вывода',

    USER_DO_NOT_CONSIDER_NETWORK_FEE: "Недостаточно средств для оплаты комиссии. ",
    USER_DO_NOT_CONSIDER_NETWORK_FEE_APPLY: "Исправить",

    LIMIT_DAILY_WITHDRAW_EXCEEDED: 'Превышен ежедневный лимит вывода',
    LIMIT_MONTHLY_WITHDRAW_EXCEEDED: 'Превышен ежемесячный лимит вывода',
    INSUFFICIENT_USER_BALANCE: 'Недостаточный баланс пользователя',
    USER_BALANCE_LOCKED: 'Баланс пользователя заблокирован',

    TRANSACTION_CREATED: 'Создать транзакцию',
    TRANSACTION_CREATED_DONE: 'Транзакция создана',
    ACCEPTED_BY_ADMIN: 'Ожидание подтверждения администратора',
    ACCEPTED_BY_ADMIN_DONE: 'Транзакция принята',
    PUBLISHED_TO_NETWORK: 'Опубликовать транзакцию',
    PUBLISHED_TO_NETWORK_DONE: 'Транзакция опубликована',
    CONFIRMED_BY_NETWORK: 'Ожидание подтверждения сети',
    CONFIRMED_BY_NETWORK_DONE: 'Транзакция завершена',
    status: 'Статус',

    badInputValues: 'Неправильные входные данные',
    badWalletAddressFormat: 'Неверный формат кошелька',
    requestedAmountIsMoreThanYourWithdrawalLimit: 'Запрошенная сумма превышает ваш лимит вывода',
    walletAddress: 'Адрес кошелька',
    pleaseInsertAmountWithoutFee: 'Пожалуйста, вставьте сумму без комиссии',
    amount: 'Сумма',
    notePleaseSelectNetworkCarefully: 'Пожалуйста, выбирайте сеть внимательно. Ввод неверного адреса может привести к потере ваших финансовых ресурсов',
    continue: 'Продолжить',
    withdrawLimit: 'Лимит вывода',
    errorOccurred: 'Произошла ошибка',
    transactionFee: 'Комиссия за транзакцию',
    receivedAmount: 'Полученная сумма',
    balanceAfter: 'Баланс после',
    thisTransactionWillBeDoneWithoutFeeAndFast: 'Эта транзакция будет выполнена без комиссии и быстро',
    transfer: 'Перевод',
    networkConfirmationMayTakeLonger: 'Подтверждение сети может занять больше времени, сумма будет отправлена на кошелек назначения через несколько минут',
    ifAmountHasBeenDeductedContactSupport: 'Если сумма была удержана с вашего счета, свяжитесь с поддержкой',
    viewTransaction: 'Просмотреть транзакцию',

    interfaceLanguage: 'Язык интерфейса',
    apiKey: 'API-ключ',
    copyApiKey: 'Скопировать API-ключ',

    USDT: 'Tether',
    TRX: 'Tron',
    IRT: 'Томан',
    BTC: 'BitCoin',
    ETH: 'Ethereum',
    TON: 'TonCoin',
    BNB: 'BinanceCoin',
    SNX: 'Sonix',
    ADA: 'Cardano',
    AVAX: 'Avalanche',
    DOGE: 'DogeCoin',
    SHIB: 'Shiba Inu',
    SOL: 'Solana',
    XRP: 'Ripple',
    NOT: 'NotCoin',
    HMSTR: 'HamsterKombat',

    hamsterShareMessage: "Привет!\n" +
        "SwapWallet — это безопасный и надежный кошелек для Telegram с возможностью осуществления платежей в криптовалюте!\n" +
        "\n" +
        "В этом криптокошельке вы можете отправлять криптовалюту друзьям без комиссии, легко покупать и продавать, приобретать Telegram Premium и многое другое...\n" +
        "\n" +
        "🐹 Кстати, вы знали, что скоро выйдет Hamster Kombat? Вы можете продать свои игровые монеты здесь по мировым ценам.\n" +
        "🎁 Присоединяйтесь к SwapWallet сейчас, используя мою ссылку, чтобы получить скидку 10% на комиссию за транзакции Hamster.",

    hamsterBannerTextFirstLine: '40% от комиссионных сборов за сделки',
    hamsterBannerTextSecondLine: 'твоя доля за то, что порекомендовал нас своим друзьям',
    hamsterBannerLink: 'Нажмите, чтобы пригласить ›',

    hmsWarning: 'Как только возможность вывода монет из этой игры будет активирована, вы можете внести свои монеты в SwapWallet, используя информацию ниже, и быть среди первых, кто продаст свои монеты.',

    withdrawAndDepositLimitedWarning: 'Вывод и внесение этой монеты временно ограничены',

    toman: 'Томан',
    network: 'Сеть',
    date: 'Дата',
    receiverAddress: 'Адрес получателя',
    senderAddress: 'Адрес отправителя',
    showOnBlockScan: 'Показать в блокчейн-браузере',
    user: 'Пользователь',

    swapAndTransfer: 'Обмен и перевод',
    checkAndTransfer: 'Проверка и перевод',
    increaseBalance: 'Увеличение баланса',
    swapAndTransferDesc: 'У вас недостаточно средств для проведения этой транзакции. Нажав на опцию ниже, вы можете использовать ваши конвертируемые активы для получения желаемой монеты и, наконец, провести перевод.',
    youHaveNotEnoughBalanceEn: 'У вас недостаточно средств для обмена.',

    birthDate: 'Дата рождения',

    checkingUserInformation: 'Проверка информации о пользователе',
    checkingUserInformationDesc: 'Проверка информации о пользователе по системе национального идентификационного номера. Пожалуйста, подождите.',

    swapTourSourceTokenTitle: 'Тип исходной монеты',
    swapTourSourceTokenDescription: 'Нажав на эту опцию, вы можете изменить тип вашей платежной криптовалюты',
    swapTourDestTokenTitle: 'Тип целевой монеты',
    swapTourDestTokenDescription: 'Нажав на эту опцию, вы можете изменить тип полученной криптовалюты',
    swapTourSourceInputTitle: 'Сумма платежа',
    swapTourSourceInputDescription: 'Введите сумму исходной криптовалюты, которую вы хотите конвертировать здесь',
    swapTourDestInputTitle: 'Полученная сумма',
    swapTourDestInputDescription: 'Система найдет для вас самый низкий обменный курс',
    swapTourTotalAmountTitle: 'Общий баланс',
    swapTourTotalAmountDescription: 'Нажав на эту опцию, вы можете выбрать общий баланс исходной криптовалюты для конвертации',
    swapTourSwapButtonTitle: 'Обмен',
    swapTourSwapButtonDescription: 'Наконец, если вы согласны с обменным курсом, вы можете завершить свою конвертацию, нажав на эту опцию',
}

export default RU_LOCAL
