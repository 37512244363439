const storage = require('./storage')

import faLocal from "@/src/config/local/faLocal"
import enLocal from "@/src/config/local/enLocal"
import ruLocal from "@/src/config/local/ruLocal"
import zhLocal from "@/src/config/local/zhLocal"
import frLocal from "@/src/config/local/frLocal"

const dirMapping = {
    EN: 'ltr',
    FA: 'rtl',
    RU: 'ltr',
    ZH: 'ltr',
    FR: 'ltr',
}

const localMapping = {
    EN: enLocal,
    FA: faLocal,
    RU: ruLocal,
    ZH: zhLocal,
    FR: frLocal,
}

function getStoredLang() {
    const storedLang = storage.get('lang')
    const defaultLang = storage.get('defaultLang')
    return storedLang || defaultLang || 'FA'
}

function get() {
    const storedLang = getStoredLang().toUpperCase()
    return {
        lang: storedLang,
        dir: dirMapping[storedLang],
        local: localMapping[storedLang],
    }
}

function setDefaultLang(lang) {
    storage.set('defaultLang', lang)
}

function setLang(lang) {
    storage.set('lang', lang)
}

export default {
    local: () => get().local,
    dir: () => get().dir,
    lang: () => get().lang,
    setLang,
    setDefaultLang,
}
