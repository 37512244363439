const EN_LOCAL = {
    login: 'Login / Register',
    mainPageTitle: 'Your portal to the Crypto World',
    mainPageItems1: 'Exclusive Wallet',
    mainPageItems2: 'Trade',
    mainPageItems3: 'User-Friendly',
    mainPageItems4: 'High Security',

    letsStart: 'Let the Adventure Begin!',
    letsStartSubtitle: 'Your digital wallet with the highest level of security',
    insertYourPhoneNumber: 'Enter your mobile number',
    start: 'Start',

    title: 'Title',

    adChequeDescription: 'Using an advertising check, you can ask users to visit your channels in exchange for paying a prize amount. Additionally, users can receive a prize for sharing your check.',
    totalPrizeAmount: 'Total Prize Amount',
    activationPrize: 'Activation Prize',
    referralPrize: 'Referral Prize (Layer One)',
    referralPrizeLayer2: 'Referral Prize (Layer Two)',
    antiBot: 'Anti-bot',
    antiBotDescription: 'SwapWallet catches bots using CAPTCHA, and it also identifies and blocks fake accounts using machine learning algorithms and user behavior analysis.',
    dollarValue: 'Value',
    supportCode: 'Support Code',
    invoiceCode: 'Invoice ID',
    swapwalletFee: 'SwapWallet Fee',
    invoiceTitle: 'Product',
    GIVEAWAY: 'Giveaway',
    AD_CHEQUE: 'Advertising Cheque',
    AD_CHEQUE_TOP_UP: 'Top-Up for Advertising Cheque',
    successfulPayment: 'Payment Successful',
    reCharge: 'Recharge',
    delete: 'Delete',
    new: 'New',

    applicationSettlement: 'Payment Gateway Settlement',
    paymentTitleWebsite: 'Cryptocurrency Payment Gateway',
    logoDescription: 'SwapWallet; Easy Payment Service',
    remainingTime: 'Remaining Time:',
    paymentTitle: 'Merchant',
    paymentInfo: 'Merchant Information',
    paymentWebsite: 'Merchant Website',
    howMuch: 'Amount',
    verificationStatus: 'Verification',
    verified: 'SwapWallet Certified',
    notVerified: 'Not Verified',
    returningToSource: 'Returning to Merchant Website...',
    paymentExpired: 'Payment Expired',
    returnToPayment: 'Return',
    moreInfo: 'More Information',

    addNewPhone: 'Add New Phone',
    itTakesMoreTimeThanUsual: 'The verification process is taking longer than expected. As soon as the verification is done, we will let you know.',
    confirmOwnerOfPhoneTitle: 'Confirm Ownership of Phone Number',
    confirmOwnerOfPhoneDescription: 'Is the mobile number you have on Telegram yours?',
    useOTPInstead: 'To add your mobile number, use the SMS verification method',
    yes: 'Yes',
    no: 'No',

    paymentTransaction: 'Purchase from the store',
    giveawayPrize: 'Receiving a prize (Airdrop)',
    adCheque: 'Activate Ad-Cheque',
    adChequeReferral: 'Ad-Cheque Referral',
    creditReason: 'Type of prize',
    giveawayParticipate: 'Participation in the giveaway',
    value: 'Value (in dollars)',
    product: 'Product',
    paidWith: 'Amount paid',
    invoiceId: 'Receipt ID',
    shop: 'Marketplace',

    giveawayEndTime: 'End date',
    giveawayTitle: 'Giveaway text',
    banner: 'Banner',
    titlePlaceholder: '🎁 Giveaway 1000 Tether... (markdown, max-length: 500)',
    marketPlace: 'Marketplace',
    giveaway: 'Giveaway',
    giveawayDescription: 'A suitable tool for promoting your channel, attracting new users, or rewarding your current users.',
    prizes: 'Prizes',
    totalPrizes: 'Total prizes',
    giveawayLimitations: 'Limitations',
    tokenType: 'Prize type',
    addPrize: 'Add prize',
    addLimit: 'Add limitation',
    telegramPremium: 'Telegram Premium',
    telegramPremiumDescription: "Upgrade your Telegram to Premium instantly and legally, without intermediaries, using your wallet coins.",
    productOutOfOrder: 'This product is not yet active for you, please contact support.',

    info: "Information",
    itsLegalTelegramPremium: "- Telegram Premium will be activated legally, instantly, and without the need to log in to your account.",
    youShouldHaveUsername: '- At the time of activation, your account must have a username.',
    youShouldNotToChangeUsername: '- Please refrain from changing your username until the end of the process.',
    telegramPremiumWasSuccessful: 'The operation was successful, please do not change your username until the premium is confirmed by Telegram',
    upgradeToPremium: 'Upgrade to Premium',
    'TG-PREMIUM-12M': '1 Year',
    'TG-PREMIUM-6M': '6 Months',
    'TG-PREMIUM-3M': '3 Months',
    backToHomeScreen: 'Back to Home Screen',

    descriptionTitle: 'Experience the Best Buying and Selling of Digital Currency with Us',
    description1Title: 'Exclusive Wallet',
    description1Text: 'Buy and sell popular cryptocurrencies, track your balance in one place, with a dedicated wallet and unique address for direct cryptocurrency deposits',
    description2Title: 'Ability to Connect to Miners',
    description2Text: 'Easily connect your wallet address to your miners and cash out your earnings',
    description3Title: 'High Security',
    description3Text: 'All information will be stored encrypted on reputable AWS data centers',
    description4Title: 'Competitive Fees',
    description4Text: 'Our fees are very low (0.1%) and decrease with increasing transactions',
    description5Title: 'Payments with Fast Cards',
    description5Text: 'Deposit and withdraw in local currency using all Shetab member cards',
    description6Title: '24/7 Support',
    description6Text: 'One of the main criteria for choosing a digital currency exchange is its support. The Zulutex support team is available 24/7 online to assist you, ensuring you can use the exchange services without any issues',

    contactUsInfo: '021228767753 | support@zulutex.com',
    contactUsDetails: 'Online support is available 24/7. You can also contact us by phone from Saturday to Wednesday, 10 am to 4 pm',
    copyright: 'All rights to this website belong to Zulutex (v1.0.0)',
    totalBalance: 'Account Balance',

    links: 'Zulutex',
    about: 'About Us',
    tosTitle: 'Terms of Service and Privacy Policy',
    levels: 'User Levels',

    loginToSwapWallet: "Login to Zulutex",
    toStartInterPhoneNumber: "Please enter your mobile number to get started",
    insertOtpCode: "Enter the received one-time SMS code",
    phoneNumberShouldBeForYour: "The mobile number should belong to you",
    beSureInDomainBefore: "Make sure you are in the domain ",
    beSureInDomainAfter: "",

    loginTitle: "The only wallet you need",
    loginSubTitle: "Enter the world of cryptocurrencies with just a few clicks!",
    sendOtp: "Send Verification Code",

    send: 'Send',
    receive: 'Receive',
    swap: 'Swap',
    logout: 'Logout',

    sharePhoneNumberRequest: 'To use all SwapWallet services, please share your mobile phone number.',
    upgradeKycLevelRequest: 'To deposit and withdraw in Toman (IRT), it is necessary to complete your identity verification process.',

    security: 'Security',
    settings: 'Settings',
    creditCards: 'Credit Cards',

    rialOperations: 'Rial Operations',
    depositRial: 'Deposit Rial',
    withdrawRial: 'Withdraw Rial',
    kyc: 'KYC',

    topup: 'Top-Up',
    topupDetails: 'You don\'t have enough balance to do this transaction, please use one of the following options to increase your wallet balance.',

    verifyYourAccount: 'Verify Your Account',
    verifyYourAccountDescription: 'To increase your balance using a credit card, your account must be verified.',
    verifyAccountButton: 'Verify',

    buyWithCreditCard: 'Buy with Credit Card',
    buyWithWallet: 'Deposit from another wallet',


    copy: 'Copy',
    share: 'Share',
    sendFromAnotherWallet: 'Send from another wallet',

    comingSoon: 'Coming Soon',

    tonCoin: 'TonCoin',
    tether: 'Tether',
    tron: 'Tron',
    ethereum: 'Ethereum',
    binanceCoin: 'BinanceCoin',
    bitCoin: 'BitCoin',
    sonix: 'Sonix',

    currentLevel: 'Current Level',
    'LEVEL_0': 'Basic',
    'LEVEL_1_GLOBAL': 'Level 1',
    'LEVEL_1_IR': 'Level 1',
    'LEVEL_2_IR': 'Level 2',


    AYANDEH: 'Ayandeh',
    EGHTESAD_NOVIN: 'Eghtesad Novid',
    ANSAR: 'Ansar',
    IRAN_ZAMIN: 'Iran Zamin',
    PARSIAN: 'Parsian',
    PASARGAD: 'Pasargad',
    POST_BANK: 'Post Bank',
    TEJARAT: 'Tejarat',
    TOSEE_TAAVON: 'Tosee Taavon',
    TOSEE_SADERAT: 'Tosee Saderat',
    HEKMAT_IRANIAN: 'Hekmat Iranian',
    KHAVARMIANEH: 'Khavarmianeh',
    DAY: 'Day',
    RESALAT: 'Resalat',
    REFAH_KARGARAN: 'Refah Kargaran',
    SAMAN: 'Saman',
    BLUBANK: 'ḃluBank',
    SEPAH: 'Sepah',
    SARMAYEH: 'Sarmayeh',
    SINA: 'Sina',
    SHAHR: 'Shahr',
    SADERAT: 'Saderat',
    SANAT_MADAN: 'Sanat Madan',
    MEHR_IRAN: 'Mehr Iran',
    GHAVAMIN: 'Ghavamin',
    KARAAFARIN: 'Karafarin',
    KESHAVARZI: 'Keshavarzi',
    GARDESHGARI: 'Gardeshgari',
    MARKAZI: 'Markazi',
    MASKAN: 'Maskan',
    MELLAT: 'Mellat',
    MELLI: 'Melli',
    KOSAR: 'Kosar',
    MELALL: 'Melall',
    TOSEE: 'Tosee',

    SWAP_WALLET: 'Zulutex',
    TRUST_WALLET: 'Trust Wallet',
    METAMASK: 'Metamask',
    TON_KEEPER: 'Ton Keeper',
    TONHUB: 'Ton Hub',

    SIGNUP: 'Signup',
    PHONE_NUMBER: 'Verify Phone Number',
    PERSONAL_INFORMATION: 'Verify Personal Information',
    NATIONAL_ID_IMAGE: 'National ID Picture',
    SELFIE_VIDEO: 'Selfie Video',

    setPhoneNumber: 'Share Phone Number',
    setPersonalInfo: 'Set Personal Information',
    setCreditCard: 'Set Credit Card',
    setLocation: 'Set Location',
    setHomePhoneNumber: 'Set Home Phone Number',
    setVideo: 'Set Selfie Video',
    signupInWebsiteOrTelegram: 'Signup in Website or Telegram',
    sorryPersonalInformationNotFound: 'Sorry, but your information not found',
    pleaseFillFormWithYourPersonalInfo: 'Please fill the form with your personal information',
    pleaseInsertYourNationalCode: 'Please insert your national-id',
    pleaseInsertYourBirthDate: 'Please insert your birth date',
    iWillAcceptAllRules: 'I have read and accept all the terms and conditions of using Zulutex services.',
    confirm: 'Confirm',

    pleaseSelectCoin: 'Please select a coin',
    pleaseOnlySendToThisNetwork: 'Send only __COIN__ (__NETWORK__) to this address. Sending other coins may result in permanent loss',
    withKYCYouHaveLowerLimits: 'With KYC you have lower limits in deposit and withdrawal',

    startKYC: 'Start KYC',
    continueKYC: 'Continue KYC Process',
    neededDocuments: 'Phone Number, National-ID, Birth Date',
    neededOneMinutes: '1 Minute',
    depositRialCashInCode: '+ Unlimited with the use of cash-in-code',
    withdrawRialLevelOne: 'Fiat Withdraw: 100 million Tomans daily',
    depositRialLevelOne: 'Fiat Deposit',
    depositCryptoLevelOne: 'Deposit Crypto: Unlimited',
    withdrawCryptoLevelOne: 'Withdraw Crypto: $20 Daily',

    cashInCodeNewOne: '- By using identified deposits, you can increase your balance without any limitations through PAIA deposits.',
    cashInCodeNewTwo: '- Identified deposits are automatically confirmed, and there is no need to inform SwapWallet about your deposit.',
    cashInCodeNewThree: '- After the deposit, your balance will be automatically increased within a maximum of one hour after the first PAIA cycle.',

    cashInCodeWarningOne: '- Do not use the bridge method under any circumstances.',
    cashInCodeWarningTwo: '- The source account for the deposit must belong to you.',
    cashInCodeWarningThree: '- Entering the deposit identifier is mandatory.',

    cashInCodeWarningTitle: 'Important Points Before Identified Deposit',

    liveness: 'Liveness Verification',
    canBeTakeTime: '(Loading data may take some time, please be patient)',
    ok: 'Ok',
    livenessLearning: 'While looking at the camera, gently rotate your head around to make all points turn green',

    selfieVideo: 'Selfie Video',
    livenessDescription: 'Hold the camera in front of you and slowly turn your head around until the entire circle turns green.',
    selfieVideoDescription: 'While looking at the camera, press the record button and read the text below with a clear voice',

    nationalIdScan: 'Scan Documents',
    frontScanDescription: 'Please hold your smart national ID card in the designated area. Use an environment with proper lighting and a dark background.',
    backScanDescription: 'Now rotate your national ID card and, as before, hold the card in the designated area. Use an environment with proper lighting and a dark background.',

    uploadDocuments: 'Uploading',
    uploadDocumentsDescription: 'Uploading information to the server, please be patient and keep this page open until the process is complete',
    uploadDocumentsDone: 'After reviewing the information by experts, we will communicate the result to you through a text message',
    uploadFailed: 'Error in upload documents to the server. Click the button below to try again',

    back: 'Back',

    uploadNationalId: 'Upload Documents',
    uploadNationalIdDescription: 'Please select a high-quality image of the front and back of your national smart card.',
    frontOfNationalId: '- Front of National ID Card',
    backOfNationalId: '- Back of National ID Card',
    upload: 'Upload',

    assets: 'Assets',

    hamsterShareMessage: "Hello!\n" +
        "SwapWallet is a secure and reliable Telegram wallet with the ability to make payments with crypto!\n" +
        "\n" +
        "In this crypto wallet, you can send crypto to your friends without any fees, buy and sell with ease, buy Telegram Premium, and more...\n" +
        "\n" +
        "🐹 By the way, did you know that Hamster Kombat is about to be launched? You can sell your game coins here at the global price.\n" +
        "🎁 Join SwapWallet now using my link to get a 10% discount on Hamster transaction fees.\n",

    hamsterBannerTextFirstLine: '40% of trading fees',
    hamsterBannerTextSecondLine: 'is your share for referring us to your friends',
    hamsterBannerLink: 'Tap to invite ›',

    neededDocuments2: 'National-ID and Selfie Video',
    neededOneMinutes2: '3 Minutes',
    depositRialLevelOne2: 'Unlimited Fiat Deposit',
    depositCryptoLevelOne2: 'Fiat Withdraw',
    withdrawCryptoLevelOne2: 'Withdraw Crypto: $2K Daily',

    verifyPhoneNumber: 'Verify Phone Number',
    verifyPhoneNumberDesc: 'Verify your phone number using one of the following methods.',
    verifyPhoneNumberDesc2: '(Phone number must be yours)',
    verifyPhoneWithShareNumber: 'Verify with share phone number',
    verifyPhoneWithSMS: 'Verify with SMS',

    kycUserInformation: 'User Information',
    kycUserInformationDesc: 'Please fill the form with your personal information',
    kycUserInformationDesc2: '🔒 Your identity information is securely encrypted and stored with us, and will only be used to prevent money laundering and phishing attacks. Also, if there is a discrepancy with the civil registration system, your identity verification process will face problems.',

    memoBasedAlertText: "To deposit this cryptocurrency, you must enter the correct comment in addition to the address. Otherwise, your asset will be lost.",
    memoBasedIConfirmed: "I understand",
    memoBasedDepositWarning: "Be sure to enter the comment for this cryptocurrency in addition to the address. Otherwise, your asset will be lost.",
    hmsWarning: 'As soon as the possibility of withdrawing coins from this game is activated, you can deposit your coins into SwapWallet using the information below and be among the first to sell your coins.',
    tosHeader: 'Terms of Service',
    tosHeaderDesc: 'Please read the terms of use of Zulutex carefully before confirming',
    tos: 'Site users accept that all their activities in Zulutex must conform to the established framework, and they will not engage in any activities beyond this scope.\n' +
        '\n' +
        'Authentication is necessary to use Zulutex services and users are required to upload their correct information. It is obvious that in case of any violation in authentication, the responsibility rests with the violator and Zulutex has the right to stop providing services to the said user and refer violations to the competent authorities. Zulutex is obliged to protect the personal information of its users.\n' +
        '\n' +
        'Users of Zulutex undertake to use Zulutex site services only for themselves and the responsibility of using Zulutex services for anyone other than those who have not gone through the authentication process will be the responsibility of the user. The account number announced to the site as well as the address of the wallets for withdrawing cryptocurrency must belong to the user and users are not allowed to give the wallet address belonging to other people.\n' +
        '\n' +
        'Zulutex users agree to cooperate with the Zulutex team in order to maintain the security of their user account assets if they are diagnosed by Zulutex support experts. Also, according to the order of judicial institutions, the withdrawal of cryptocurrency may be limited for 24 to 72 working hours after the time of deposit.\n' +
        '\n' +
        'Users of Zulutex acknowledge that they are aware of the risks related to investing in digital currencies and invest and exchange with this knowledge.\n' +
        '\n' +
        'Zulutex, as an online marketplace for exchanging digital currencies, does not have any responsibility for the way users trade and the resulting profits and losses.\n' +
        '\n' +
        'Any negligence of users in maintaining security information, including the user\'s password, is the responsibility of the user and Zulutex will not bear any responsibility. It is strongly recommended to use a complex and secure password and two-factor authentication code.\n' +
        '\n' +
        'Zulutex ensures that users\' assets are kept in trust with them in the best way and with the highest possible security standards. In case of any security problem, Zulutex will be obliged to compensate the damage.\n' +
        '\n' +
        'If you want to withdraw digital currencies, the user is responsible for providing the correct wallet address. Zulutex will not be held responsible for any problems, including mistakes in entering the correct address, address errors, problems with the destination wallet, and blocking of users\' assets in the destination wallet.\n' +
        '\n' +
        'Zulutex has no responsibility for depositing tokens or coins on the wrong platform or coins that are not supported in Zulutex, and due to the cold storage mechanism, it is not possible to extract these tokens using a third-party wallet. Therefore, the user is responsible for any wrong deposit and the user has no right to complain.\n' +
        '\n' +
        'The user accepts that, except in the cases of Zulutex\'s commitment, he will not have the right to make any claims, demands and complaints against the Zulutex site, managers, employees and people related to this site.\n' +
        '\n' +
        'In case of the user\'s death, Zulutex will have the right to deposit the user\'s funds with the company to the account declared by the user at the time of registration. It was entrusted to Zulutex to convert it into fiat equivalent to the day\'s price and deposit it into the above account, and this right and privilege was granted to Zulutex by the user. It should be noted that the time criterion for calculating the selling price of the user\'s cryptocurrency assets is based on the presentation of positive documents by the beneficiary and confirmation of its authenticity by the Zulutex legal unit. With the knowledge and knowledge of the provisions of this paragraph, the user has opened a user account in Zulutex, and the user, third parties, heirs or legal representatives will not have the right to protest or claim in this regard in the future.\n' +
        '\n',

    kycWasSuccessful: 'Your KYC was successful',
    kycWasSuccessfulDesc: 'Congratulations! Your account level has been upgraded to "Level One", in this level you can deposit up to 15 million Tomans daily.',

    rialDeposit: 'Deposit Rial',

    kycWasNotSuccessful: 'KYC was not successful',
    kycWasNotSuccessfulDesc: 'Please check the information you entered again. Note that the mobile number must belong to the entered national code.',
    retry: 'Retry',

    redirectingToPaymentGateway: 'Redirecting to payment gateway',
    redirectToPaymentGateway: 'Redirect to payment gateway',
    connectionFromOutsideOfIran: 'Connection from outside of Iran',
    sorryButYouShouldTurnOffYourVPN: 'Sorry, connection from outside of Iran is not allowed by payment gateways. Please turn off your VPN and click blow button',

    returnToWallet: 'Return to Wallet',
    returnToWebsite: 'Return to Wallet',
    yourPaymentWasSuccessful: 'Your payment was successful',
    yourPaymentWasNotSuccessful: 'Your payment was not successful',
    yourWalletHasBeenCharged: 'Your wallet has been charged, you can return to your wallet by clicking the button below',
    nowYouCanTurnVPNOnAgain: 'Now you can turn on your VPN again',
    paymentId: 'Payment ID',
    orderId: 'Order ID',
    contactSupport: 'Contact Support',
    ifAmountHasBeenDeductedItWillRefund: 'If the amount has been deducted from your account, it will be refunded to your account within 72 hours',
    notePaymentShouldDoneUsingSelectedCreditCard: 'Note: Payment should be done using the selected credit card',

    connectionError: 'Connection Error',
    connectionToPaymentGatewayFailed: 'Connection to payment gateway failed',
    pleaseSelectACardOrAddANewCard: 'Please select a credit card or add a new one',
    pleaseInsertTheAmount: 'Please insert the amount',
    pay: 'Pay',
    holdToPay: 'Hold to Pay',

    holdToSwapAndPay: 'Hold to swap and pay',
    holdToTopUp: 'Top up',
    youDoNotHaveEnoughMoneyToPay: 'You do not have enough money to pay',
    minimumAmountStart: 'Approximately',
    minimumAmountEnd: 'from your balance will be converted to the desired currency at the best market rate to pay this receipt',

    cardNumberIsNotValid: 'Credit card number is not valid',
    cardSuccessfullyAdded: 'Credit card successfully added',
    cardFailedToAdd: 'Credit card failed to add',
    addANewCard: 'Add a new credit card',
    warning: 'Warning',
    youOnlyCanAddCreditCardThatIsForYou: 'Due to the order of the Cyber Police, to prevent phishing and money laundering, only the possibility of adding a card according to your mobile number is possible. Payment is only possible with a pre-defined card.',
    add: 'Add',
    cancel: 'Cancel',
    thereIsNoCreditCard: 'There is no credit card',
    minimumDepositAmountIs150: 'Minimum deposit amount is 5,000 Toman',
    maximumDepositAmountIs25M: 'Maximum deposit amount is 25M Toman',

    addANewIban: 'Add a New IBAN',
    ibanSuccessfullyAdded: 'New IBAN Successfully Added',
    youOnlyCanAddIbanThatIsForYou: 'According to the FATA police order, to prevent phishing and money laundering offenses, you can only add an IBAN corresponding to your mobile phone number. Settlement is only possible to your own account.',
    thereIsNoIban: 'No IBAN available',
    minimumWithdrawAmountIs5: 'Minimum withdrawal amount is 5,000 Tomans',
    maximumWithdrawAmountIs100M: 'Maximum daily withdrawal amount is 100 million Tomans',
    pleaseSelectAIbanOrAddANewIban: 'Select an existing IBAN or add a new one',
    settle: 'Withdraw',
    transactionFeeIsForBroker: '(Zulutex is not a beneficiary of this fee)',
    receiverName: 'Receiver Name',
    ifPossibleWeUseInstantWithdraw: '⚡ If possible, settlement will be done instantly and within less than 15 minutes.',
    receiveTime: 'Deposit Time',
    receiveTimeResult: 'In the first ACH cycle of the next period',
    sentToBank: 'Deposit to Bank Account',
    ibanNumber: 'Receiver\'s IBAN',
    ibanNumberSender: "Sender\'s IBAN",
    gateway: 'Payment Gateway',
    cashInCode: 'Deposit Code',
    cashInCodeDescription: 'By sending the amount to the specified account number, recharge your Rial account without any restrictions. Make sure to enter the created code in the payment ID section on mobile banking, internet banking, or bank payment slip. After deposit, the amount will be automatically credited to your account.',
    cashInCodeNumber: 'Deposit Code:',
    iban: 'IBAN:',
    ibanNumberTooltip: 'IBAN Number',
    ibanNumberTooltipCopied: 'IBAN Number Copied',
    accountNumber: 'Account Number:',
    accountNumberTooltip: 'Account Number',
    accountNumberTooltipCopied: 'Account Number Copied',
    accountHolderName: 'Account Holder Name:',
    noticeUseCashInCode: 'Must be entered in the Deposit Code section',
    cashInCodeDescriptionTwo: 'Deposit is possible with all payment methods, except for the bridge. Note that the source account must belong to you.',

    market: 'Market',
    payAmount: 'Pay Amount',
    receiveAmount: 'Receive Amount',
    tradeRate: 'Exchange Rate',
    bankFee: 'Bank Fee',
    cardNumber: 'Credit Card',
    refId: 'Ref-Id',
    hash: 'Transaction Hash',
    uniqueId: 'Transaction Id',

    sufficientBalance: 'Sufficient Balance',
    transactionWasSuccessful: 'Transaction was successful',
    transactionWasNotSuccessful: 'Transaction was not successful',
    quickSwap: 'Quick Swap',
    fee: 'Fee',
    youPay: 'You Pay',
    youReceive: 'You Receive',
    balance: 'Balance',
    increaseBalanceByRequiredAmount: 'Increase balance by required amount',

    transferToAddress: 'Transfer to',
    receiveFromAddress: 'Receive from',
    swapTo: 'Exchange __SOURCE__ to __DEST__',
    buyFromBankGateway: 'Deposit with Gateway',
    cashInCodeName: 'Deposit with Bank Transfer',
    thisCoinIsNotActiveYet: 'This coin is not active yet',
    thereIsNoTransaction: 'There is no transaction',

    selectToken: 'Select Coin',
    selectNetwork: 'Select Network',
    selectNetworkPlaceHolder: 'Please Select Withdraw Network',

    USER_DO_NOT_CONSIDER_NETWORK_FEE: "Insufficient balance to pay the fee. ",
    USER_DO_NOT_CONSIDER_NETWORK_FEE_APPLY: "Fix it",

    LIMIT_DAILY_WITHDRAW_EXCEEDED: 'Daily withdraw limit exceeded',
    LIMIT_MONTHLY_WITHDRAW_EXCEEDED: 'Monthly withdraw limit exceeded',
    INSUFFICIENT_USER_BALANCE: 'Insufficient user balance',
    USER_BALANCE_LOCKED: 'User balance locked',


    TRANSACTION_CREATED: 'Generate Transaction',
    TRANSACTION_CREATED_DONE: 'Transaction Created',
    ACCEPTED_BY_ADMIN: 'Pending for Accept',
    ACCEPTED_BY_ADMIN_DONE: 'Transaction Accepted',
    PUBLISHED_TO_NETWORK: 'Publish Transaction',
    PUBLISHED_TO_NETWORK_DONE: 'Transaction Published',
    CONFIRMED_BY_NETWORK: 'Waiting for Network Confirmation',
    CONFIRMED_BY_NETWORK_DONE: 'Transaction Completed',
    status: 'Status',

    badInputValues: 'Bad Input Values',
    badWalletAddressFormat: 'Bad Wallet Address Format',
    requestedAmountIsMoreThanYourWithdrawalLimit: 'Requested amount is more than your withdrawal limit',
    walletAddress: 'Wallet Address',
    pleaseInsertAmountWithoutFee: 'Please insert amount without fee',
    amount: 'Amount',
    notePleaseSelectNetworkCarefully: 'Please select network carefully. Entering the wrong address may result in the loss of your financial resources',
    continue: 'Continue',
    withdrawLimit: 'Withdraw Limit',
    errorOccurred: 'Error Occurred',
    transactionFee: 'Transaction Fee',
    receivedAmount: 'Received Amount',
    balanceAfter: 'Balance After',
    thisTransactionWillBeDoneWithoutFeeAndFast: 'This transaction will be done without fee and fast',
    transfer: 'Transfer',
    networkConfirmationMayTakeLonger: 'Network confirmation may take longer, the amount will be sent to the destination wallet in a few minutes',
    ifAmountHasBeenDeductedContactSupport: 'If the amount has been deducted from your account, please contact support',
    viewTransaction: 'View Transaction',

    interfaceLanguage: 'Interface Language',
    apiKey: 'API Key',
    copyApiKey: 'Copy API Key',

    USDT: 'Tether',
    TRX: 'Tron',
    IRT: 'Toman',
    BTC: 'BitCoin',
    ETH: 'Ethereum',
    TON: 'TonCoin',
    BNB: 'BinanceCoin',
    SNX: 'Sonix',
    ADA: 'Cardano',
    AVAX: 'Avalanche',
    DOGE: 'DogeCoin',
    SHIB: 'Shiba Inu',
    SOL: 'Solana',
    XRP: 'Ripple',
    NOT: 'NotCoin',
    HMSTR: 'HamsterKombat',

    withdrawAndDepositLimitedWarning: 'Withdrawal and deposit of this coin are temporarily limited',

    toman: 'Toman',
    network: 'Network',
    date: 'Date',
    receiverAddress: 'Receiver Address',
    senderAddress: 'Sender Address',
    showOnBlockScan: 'Show on Block Scan',
    user: 'User',

    swapAndTransfer: 'Swap & Transfer',
    checkAndTransfer: 'Check & Transfer',
    increaseBalance: 'Increase Balance',
    swapAndTransferDesc: 'You do not have enough balance of the desired coin to perform this transaction, by clicking on the option below, you can use your convertible assets to supply the desired coin and finally perform the transfer.',
    youHaveNotEnoughBalance: 'You do not have enough balance to swap.',

    birthDate: 'Birth Date',

    checkingUserInformation: 'Checking User Information',
    checkingUserInformationDesc: 'Checking User Information with National ID System, Please Wait.',

    gatewayOutOfOrder: 'Payment gateway out of order, please use bank transfer',
    swapTourSourceTokenTitle: 'Source Token Type',
    swapTourSourceTokenDescription: 'By clicking on this option, you can change the type of your payment cryptocurrency',
    swapTourDestTokenTitle: 'Destination Token Type',
    swapTourDestTokenDescription: 'By clicking on this option, you can change the type of your received cryptocurrency',
    swapTourSourceInputTitle: 'Payment Amount',
    swapTourSourceInputDescription: 'ter the amount of the source cryptocurrency that you want to convert here',
    swapTourDestInputTitle: 'Received Amount',
    swapTourDestInputDescription: 'The system finds the lowest exchange rate for you',
    swapTourTotalAmountTitle: 'Total Balance',
    swapTourTotalAmountDescription: 'By clicking on this option, you can select the total balance of the source cryptocurrency for conversion',
    swapTourSwapButtonTitle: 'Swap',
    swapTourSwapButtonDescription: 'Finally, if you agree with the exchange rate, you can finalize your conversion by clicking on this option',
}
export default EN_LOCAL
