class Lock {
    constructor() {
        this.queue = [];
        this.acquired = false;
    }

    async acquire() {
        if (!this.acquired) {
            this.acquired = true;
        } else {
            return new Promise((resolve) => {
                this.queue.push(resolve);
            });
        }
    }

    async release() {
        if (this.queue.length === 0 && this.acquired) {
            this.acquired = false;
            return;
        }

        const continuation = this.queue.shift();
        return new Promise((res) => {
            continuation();
            res();
        });
    }
}

class LockFactory {
    static locks = {}

    static getLock(name) {
        if (!LockFactory.locks[name]) {
            LockFactory.locks[name] = new Lock();
        }

        return LockFactory.locks[name];
    }
}

module.exports = LockFactory;