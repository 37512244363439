import React, {useEffect, useState} from 'react'
import {
    Alert,
    App,
    Avatar,
    Button,
    Col,
    ConfigProvider,
    Dropdown,
    Flex,
    Layout,
    List,
    message,
    Row,
    Space,
    Tag,
    Typography,
} from 'antd';
import WalletButton from '../../common/components/walletButton'
import {
    AppstoreAddOutlined, CreditCardOutlined,
    DownloadOutlined,
    IdcardOutlined,
    LinkOutlined,
    LockOutlined,
    LogoutOutlined,
    SettingOutlined,
    SwapOutlined,
    UploadOutlined,
    UserOutlined
} from '@ant-design/icons'
import WalletPrice from "./components/walletPrice";
import CoinRow from './components/coinRow'
import telegram from "@/src/utils/telegram";
import Exchange from "@/src/api/exchange";
import zuluTheme, {darkTheme} from '@/src/utils/theme'
import config from "@/src/config";
import CoinSelector from "./components/coinSelector";
import storage from '@/src/utils/storage'
import interfaceConfigs from "@/src/utils/interfaceConfigs";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAnalytics} from "use-analytics";
import hamsterBannerRtl from "@/public/images/other/hamster-banner-rtl.png";
import hamsterBannerLtr from "@/public/images/other/hamster-banner-ltr.png";

const {Link} = Typography

import(/* webpackPrefetch: true */ "@/src/app/deposit/page")
import(/* webpackPrefetch: true */ "@/src/app/withdraw/page")
import(/* webpackPrefetch: true */ "@/src/app/transactions/page")
import(/* webpackPrefetch: true */ "@/src/app/swap/page")
import(/* webpackPrefetch: true */ "@/src/app/settings/page")
import(/* webpackPrefetch: true */ "@/src/app/rialDeposit/page")

function routerPush(navigate, url) {
    navigate(url)
    telegram.showBackButton(navigate)
}

const {Text} = Typography

function MainPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const [userBalance, setUserBalance] = useState(storage.get(config.localStorage.userBalance))
    const [depositCoinSelectorOpen, setDepositCoinSelectorOpen] = useState(false)
    const [withdrawCoinSelectorOpen, setWithdrawCoinSelectorOpen] = useState(false)
    const [mainCurrency, setMainCurrency] = useState('$')
    const [userInformation, setUserInformation] = useState(storage.get(config.localStorage.userInfo))
    const [messageApi, contextHolder] = message.useMessage()
    const {identify, track} = useAnalytics()

    function getUserSourceMedium() {
        if (searchParams.get("ref")) {
            return 'REFERRAL'
        }

        if (searchParams.get("utm")) {
            return searchParams.get("utm")
        }

        return "-"
    }

    useEffect(() => {
        const referredBy = searchParams.get("ref")
        if (referredBy) {
            Exchange.updateReferrer(referredBy)
                .then(() => console.log("referred"))
                .catch((e) => console.error("error on update referrer", e))
        }
    }, []);

    useEffect(() => {
        const campaignId = searchParams.get("utm")
        if (campaignId) {
            Exchange.updateUtm(campaignId)
                .then(() => console.log("utm"))
                .catch((e) => console.error("error on update utm", e))
        }
    }, []);

    useEffect(() => {
        if (userInformation?.language) {
            interfaceConfigs.setLang(userInformation.language)
        }
    }, [userInformation]);

    useEffect(() => {
        if (userInformation == null) {
            return
        }

        identify(userInformation.id.toString(), {
            username: userInformation.username,
            firstName: userInformation.firstName,
            lastName: userInformation.lastName,
            displayName: userInformation.displayName,
            level: userInformation.level,
            language: userInformation.language,
            region: userInformation.region,
        })
    }, [userInformation])

    useEffect(() => {
        const defaultLang = searchParams.get('defaultLang')
        if (defaultLang) {
            interfaceConfigs.setDefaultLang(defaultLang)
        }
    }, [searchParams])

    // init
    useEffect(() => {
        updateWallet()
        updateUserInfo()

        telegram.beReady()
        telegram.expand()
        telegram.hideBackButton()
        telegram.setBackgroundColor(zuluTheme.token.colorBgBase)
        telegram.setHeaderColor(zuluTheme.token.colorBgBase)
        telegram.disableClosingConfirmation()

        // router.prefetch("/swap")
        // router.prefetch("/transactions")
        // router.prefetch("/deposit")
        // router.prefetch("/withdraw")
        // router.prefetch("/rial-deposit")
        // router.prefetch("/kyc")
        // router.prefetch("/settings")

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateWallet = async () => {
        try {
            const userBalance = await Exchange.getWallet()
            setUserBalance(userBalance)
            storage.set(config.localStorage.userBalance, userBalance)
        } catch (err) {
            if (err.response && [401, 403].includes(err.response.status)) {
                navigate('/login', {replace: true});
            }
            telegram.hapticForNotification('error')
            if (err?.response?.data?.error?.localizedMessage) {
                messageApi.info(err.response.data.error.localizedMessage)
                throw err
            } else {
                messageApi.info(config.local().connectionError)
                throw err
            }
        }
    }

    const updateUserInfo = async (ignoreCache = false) => {
        try {
            const userInformation = await Exchange.getUserInformation(ignoreCache)
            setUserInformation(userInformation)
            storage.set(config.localStorage.userInfo, userInformation)
        } catch (err) {
            telegram.hapticForNotification('error')
            if (err?.response?.data?.error?.localizedMessage) {
                messageApi.info(err.response.data.error.localizedMessage)
                throw err
            } else {
                messageApi.info(config.local().connectionError)
                throw err
            }
        }
    }

    const getUserDropDownMenu = () => {
        const items = [
            {
                key: '/kyc',
                label: (
                    <Space
                        style={{width: 150, justifyContent: 'space-between'}}>
                        <p>{config.local().kyc}</p>
                        <Tag bordered={true}>{config.local()[userInformation?.level] || 'پایه'}</Tag>
                    </Space>),
                icon: <IdcardOutlined/>,
            },
            {
                key: '/connect',
                label: 'Web3 Connect',
                icon: <LinkOutlined/>,
                disabled: true,
            },
            userInformation?.level.includes('IR') && {
                type: 'divider',
            },
            {
                key: 'security',
                label: config.local().security,
                icon: <LockOutlined/>,
                disabled: true,
            },
            {
                key: '/settings',
                label: config.local().settings,
                icon: <SettingOutlined/>,
                disabled: false,
            },
            !telegram.areWeInTelegramWebApp() &&
            {
                key: 'logout',
                label: config.local().logout,
                icon: <LogoutOutlined/>,
                disabled: false,
            },
        ].filter(x => x)
        return {
            items, onClick: ({key}) => {
                if (key === 'logout') {
                    storage.unset(config.localStorage.user)
                    storage.unset(config.localStorage.userInfo)
                    storage.unset(config.localStorage.userBalance)
                    storage.unset(config.localStorage.transactions)
                    navigate('/login', {replace: true})
                } else {
                    routerPush(navigate, key)
                }
            }
        }
    }

    const getRialOperationMenu = () => {
        const items = [
            {
                key: 'rial-deposit',
                label: (
                    <Link onClick={() => routerPush(navigate, '/rial-deposit/cash-in-code')}>{config.local().depositRial}</Link>
                ),
                icon: <DownloadOutlined/>,
                disabled: false,
            },
            {
                key: 'rial-withdraw',
                label: (
                    <Link onClick={() => routerPush(navigate, '/rial-withdraw')}>{config.local().withdrawRial}</Link>
                ),
                icon: <UploadOutlined/>,
                disabled: true,
            },
        ]

        // if (userInformation?.kyc.currentLevel === 'LEVEL-0') {
        //     items.unshift({
        //         key: 'kyc-needed',
        //         label: (
        //             <Link onClick={() => routerPush(router, "/kyc")}>{config.local().kyc}</Link>
        //         ),
        //         icon: <IdcardOutlined/>,
        //         disabled: false,
        //     })
        // }

        return {items}
    }

    const getCoinRowsNew = () => {
        if (!userBalance) {
            return <Link></Link>
        }

        const tokensCopy = JSON.parse(JSON.stringify(userBalance.tokens))
        return (
            <List
                split={true}
                itemLayout="horizontal"
                dataSource={
                    tokensCopy
                        .sort((a, b) => (b.available.amount.unit === "HMSTR") - (a.available.amount.unit === "HMSTR"))
                }
                style={{
                    width: '100%',
                }}
                renderItem={(tokenBalance, index) => {
                    const token = tokenBalance.available.amount.unit
                    const amount = Number(tokenBalance.available.amount.number)
                    const usdValue = Number(tokenBalance.available.values.find(i => i.unit === 'USD').number)
                    const irtValue = Number(tokenBalance.available.values.find(i => i.unit === 'IRT').number)
                    let latestPrice = Number(tokenBalance.marketData.findLast(i => i.destination == 'USDT' || i.source == 'USDT').marketData.latestPrice)
                    let changes = Number(tokenBalance.marketData.findLast(i => i.destination == 'USDT' || i.source == 'USDT').marketData.dayChange)
                    if (token === 'IRT') {
                        latestPrice = 1 / latestPrice
                        changes = -1 * changes
                    }

                    return (
                        <List.Item onClick={() => routerPush(navigate, `/transactions?coin=${token}`)}>
                            <CoinRow
                                key={token}
                                icon={config.iconMappingCirclePath[token]}
                                name={config.local()[token]}
                                unit={token}
                                amount={amount}
                                mainCurrency={mainCurrency}
                                usdtValue={usdValue}
                                irtValue={irtValue}
                                price={latestPrice}
                                changes={changes}
                            />
                        </List.Item>
                    )
                }}
            />
        )
    }

    const onWithdrawSelect = (token) => {
        if (token === "IRT") {
            return routerPush(navigate, `/rial-withdraw`)
        }

        const network = config.networks[token][0]
        routerPush(navigate, `/withdraw?token=${token}&network=${network}`);
    }

    const onDepositSelect = (token) => {
        if (token === "IRT") {
            return routerPush(navigate, `/rial-deposit`)
        }

        const network = config.networks[token][0]
        routerPush(navigate, `/deposit?token=${token}&network=${network}`);
    }

    const getAppBanner = () => {
        if (!telegram.areWeInTelegramWebApp()) {
            return <></>
        }

        const blockedUsers = [
            53602,
            67163,
            68521,
            53581,
        ]

        if (!userInformation || blockedUsers.includes(userInformation.id)) {
            return <></>
        }

        return (
            <ConfigProvider direction={config.dir()} theme={darkTheme}>
                <div style={{
                    width: '100%',
                    height: 100,
                    backgroundImage: `linear-gradient(to ${config.dir() === "ltr" ? "right" : "left"}, #ff6600, #ff940b)`,
                    borderRadius: 10,
                    position: "relative",
                    marginTop: 30,
                    cursor: "pointer",
                }}
                     onClick={() => {
                         const message = config.local().hamsterShareMessage
                         const link = `https://t.me/swapwalletbot/app?startapp=invite-${userInformation.id}--u-m3nmr`
                         telegram.shareToContacts(link, message)
                         track("clickShareHamsterButton", {source: getUserSourceMedium()})
                     }}
                >
                    <Text
                        style={{
                            fontWeight: 800,
                            fontSize: 12,
                            position: "absolute",
                            top: 15,
                            right: config.dir() === "rtl" && 25,
                            left: config.dir() === "ltr" && 25,
                            maxWidth: '80%',
                        }}
                    >
                        {config.local().hamsterBannerTextFirstLine}
                        <br/>
                        {config.local().hamsterBannerTextSecondLine}
                    </Text>
                    <Text
                        style={{
                            fontWeight: 200,
                            fontSize: 12,
                            position: "absolute",
                            bottom: 15,
                            right: config.dir() === "rtl" && 25,
                            left: config.dir() === "ltr" && 25,
                        }}
                    >
                        {config.local().hamsterBannerLink}
                    </Text>
                    <img
                        style={{
                            display: "block",
                            position: 'absolute',
                            bottom: 0,
                            width: 95,
                            left: config.dir() === "rtl" && 20,
                            right: config.dir() === "ltr" && 20,
                        }}
                        src={config.dir() === "rtl" ? hamsterBannerRtl : hamsterBannerLtr} alt={"hamster-logo"}
                    />
                </div>
            </ConfigProvider>
        )
    }

    const isUserFromIran = userInformation?.region === 'IR' || userInformation?.language === 'FA' || userInformation?.level?.includes("IR")

    return (
        <ConfigProvider direction={config.dir()} theme={zuluTheme}>
            <App>
                {contextHolder}
                <Layout style={{paddingBottom: 20, minHeight: '100vh'}}>
                    <CoinSelector type={"DEPOSIT"} onClick={onDepositSelect} isOpen={depositCoinSelectorOpen}
                                  onClose={() => setDepositCoinSelectorOpen(false)} userBalance={userBalance}
                                  isUserFromIran={isUserFromIran}/>
                    <CoinSelector type={"WITHDRAW"} onClick={onWithdrawSelect} isOpen={withdrawCoinSelectorOpen}
                                  onClose={() => setWithdrawCoinSelectorOpen(false)} userBalance={userBalance}
                                  isUserFromIran={isUserFromIran}/>
                    <Col span={22} offset={1} style={{marginTop: 20}}>
                        <Flex align={"center"} justify={"space-between"}>
                            <Dropdown menu={getUserDropDownMenu()}>
                                <Avatar size={32} style={{backgroundColor: '#7265E6'}}
                                        src={userInformation?.profileUrl}
                                        icon={<UserOutlined/>}/>
                            </Dropdown>
                            {/*<Link onClick={() => routerPush(navigate, "/shop")}><Button type={"dashed"} size={"small"} icon={<ShopOutlined />}>فروشگاه</Button></Link>*/}
                        </Flex>
                        <WalletPrice
                            onClick={() => userInformation?.level.includes('IR') && setMainCurrency(mainCurrency === '$' ? 'IRT' : '$')}
                            userBalance={userBalance} mainCurrency={mainCurrency}/>
                        <Space direction="horizon"
                               style={{
                                   marginTop: 30,
                                   width: '100%',
                                   justifyContent: 'space-around',
                                   alignItems: 'center'
                               }}>
                            <WalletButton onClick={() => setWithdrawCoinSelectorOpen(true)} size={50}
                                          icon={UploadOutlined}
                                          text={config.local().send}/>
                            <WalletButton onClick={() => setDepositCoinSelectorOpen(true)} size={50}
                                          icon={DownloadOutlined}
                                          text={config.local().receive}/>
                            <WalletButton
                                onClick={() => routerPush(navigate, `/swap?target=${userBalance?.tokens[0]?.available.amount.unit || 'USDT'}`)}
                                size={50} icon={SwapOutlined} text={config.local().swap}/>

                            {(userInformation?.level === 'LEVEL_1_IR' || userInformation?.level === 'LEVEL_2_IR') &&
                                <WalletButton onClick={() => routerPush(navigate, `/rial-deposit/cash-in-code`)} size={50}
                                              icon={CreditCardOutlined}
                                              text={config.local().depositRial}/>
                            }

                        </Space>

                        {/*<Contacts/>*/}

                        {getAppBanner()}

                        <Space style={{marginTop: 25, width: '100%', fontWeight: 600}} direction={"vertical"}>
                            <Text style={{fontSize: 14}}>{config.local().assets}</Text>
                            <Row>
                                {getCoinRowsNew()}
                            </Row>
                        </Space>
                    </Col>
                    <Flex justify={"center"}>
                        <Flex vertical justify={"center"} align={"center"}>
                            <Text type={"secondary"} style={{fontWeight: 100, fontSize: 9}}>v2.1.3</Text>
                            <Text type={"secondary"} style={{fontWeight: 100, fontSize: 8}}>Made with love by
                                Zulutex</Text>
                        </Flex>
                    </Flex>
                </Layout>
            </App>
        </ConfigProvider>
    )
}

export default MainPage
