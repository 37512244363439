import React, {useEffect, useState} from "react";
import {Space, Typography} from "antd";
import backgroundAnimation from "@/public/lottie/background.json";
import Lottie from "lottie-react";

const {Text} = Typography

export function DisplayWrapper(props) {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if (windowSize[0] > 768) {
        const phoneHeight = Math.min(1200, windowSize[1] - 100)
        const phoneWidth = Math.max(400, phoneHeight / 2)
        return (
            <div style={{
                width: windowSize[0],
                height: windowSize[1],
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                alignContent: 'center',
                alignItems: 'center',
            }}>
                <Space direction={"vertical"} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
                    <Lottie
                        animationData={backgroundAnimation}
                        loop={true}
                        style={{
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            position: 'absolute',
                            opacity: 0.05,
                        }}
                    />

                    <div style={{zIndex: 100}}></div>
                    <iframe
                        width={phoneWidth}
                        height={phoneHeight}
                        style={{
                            border: '1px solid white',
                            borderRadius: 5,
                            overflowY: 'hidden',
                            position: 'relative',
                            zIndex: 100,
                            backgroundColor: '#222',
                        }}
                        src={window.location.href}
                    ></iframe>
                    <Text style={{zIndex: 100, position: 'relative'}} type={"secondary"}>برای تجربه‌ی کاربری بهتر، از گوشی همراه خود استفاده کنید</Text>
                </Space>
            </div>
        )
    } else {
        return props.children
    }
}
