import React from "react";
import {App, ConfigProvider, Layout} from "antd";
import {createCache, extractStyle, StyleProvider} from "@ant-design/cssinjs";
import zuluTheme from "@/src/utils/theme";
import config from "@/src/config";

const StyledComponentsRegistry = ({children}) => {
    const cache = React.useMemo(() => createCache(), [createCache]);

    return (
        <StyleProvider cache={cache}>
            <style
                id="antd"
                dangerouslySetInnerHTML={{__html: extractStyle(cache, true)}}
            />
            <ConfigProvider theme={zuluTheme} direction={config.dir()}>
                <App>
                    <Layout>{children}</Layout>
                </App>
            </ConfigProvider>
        </StyleProvider>
    );
};

export default StyledComponentsRegistry;
