import common from './common'

const config = {
    ...common,
    api: {
        baseUrl: 'https://swapwallet.me/api',
    },
}

export default config;
