export function toPersianNumber(number) {
    const mapping = {0: '۰', 1: '۱', 2: '۲', 3: '۳', 4: '۴', 5: '۵', 6: '۶', 7: '۷', 8: '۸', 9: '۹'};
    return number.toString().split('').map(x => mapping[x] || x).join('')
}

export function separateNumber(number) {
    if (!number.toString().includes('.')) {
        return [number, null]
    }

    return number.toString().split('.')
}

export function roundWithPrecisionNumber(number, precision = 2) {
    return Math.round((number + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
}