import {v4 as uuidv4} from "uuid";
import telegram from "@/src/utils/telegram";

const FingerprintJS = require('@fingerprintjs/fingerprintjs')

function getDevicePersistentId() {
    let uuid = localStorage.getItem('__DEVICE_ID__')
    if (!uuid) {
        uuid = uuidv4()
        localStorage.setItem("__DEVICE_ID__", uuid)
    }
    return uuid
}

async function getFingerprint() {
    const fpPromise = FingerprintJS.load()
    return (await fpPromise).get()
}

async function getDeviceInfo() {
    const fp = await getFingerprint()
    fp.components.telegram = telegram.getTelegramDeviceInfo()
    return {
        persistentId: getDevicePersistentId(),
        fingerprint: fp.visitorId,
        spec: JSON.stringify(fp.components),
    }
}

export default {
    getDeviceInfo,
}
