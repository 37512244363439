import {Space, Typography} from "antd";
import React from "react";
import AmountNumber from '@/src/common/components/amountNumber'
import config from "@/src/config"

const {Title} = Typography;

function WalletPrice(props) {
    const userBalance = props.userBalance
    const amount = props.mainCurrency === '$' ?
        userBalance?.totalValue.available.find(i => i.unit === "USD").number
        : userBalance?.totalValue.available.find(i => i.unit === "IRT").number

    return (
        <>
            <Space onClick={props.onClick} direction="vertical"
                   style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <AmountNumber loading={!amount} size={40} unit={props.mainCurrency} amount={amount}/>
                <Title style={{fontWeight: 300, fontSize: 12, marginTop: -12}}>{config.local().totalBalance}</Title>
            </Space>
        </>
    )
}

export default WalletPrice
