const ZH_LOCAL = {
    login: '登录 / 注册',
    mainPageTitle: '通往加密世界的门户',
    mainPageItems1: '专属钱包',
    mainPageItems2: '交易',
    mainPageItems3: '用户友好',
    mainPageItems4: '高安全性',

    letsStart: '冒险开始！',
    letsStartSubtitle: '具有最高安全级别的数字钱包',
    insertYourPhoneNumber: '输入您的手机号码',
    start: '开始',

    adChequeDescription: '使用广告支票，您可以要求用户访问您的频道，以换取支付的奖金金额。此外，用户还可以通过分享您的支票获得奖励。',
    totalPrizeAmount: '总奖金金额',
    activationPrize: '激活奖金',
    referralPrize: '推荐奖金（第一层）',
    referralPrizeLayer2: '推荐奖金（第二层）',
    antiBot: '反机器人',
    antiBotDescription: 'SwapWallet使用CAPTCHA捕获机器人，还使用机器学习算法和用户行为分析识别和阻止假账户。',
    dollarValue: '价值',
    supportCode: '支持代码',
    invoiceCode: '发票编号',
    swapwalletFee: 'SwapWallet费用',
    invoiceTitle: '产品',
    GIVEAWAY: '赠品',
    AD_CHEQUE: '广告支票',
    AD_CHEQUE_TOP_UP: '广告支票充值',
    successfulPayment: '支付成功',
    reCharge: '充值',
    delete: '删除',
    new: '新建',

    applicationSettlement: '支付网关结算',
    paymentTitleWebsite: 'SwapWallet 加密货币支付网关',
    logoDescription: 'SwapWallet；加密货币交易的创新',
    remainingTime: '剩余时间：',
    paymentTitle: '商家',
    paymentInfo: '商家信息',
    paymentWebsite: '商家网站',
    howMuch: '金额',
    verificationStatus: '验证',
    verified: 'SwapWallet 已认证',
    notVerified: '未认证',
    returningToSource: '返回商家网站...',
    paymentExpired: '支付已过期',
    returnToPayment: '返回',
    moreInfo: '更多信息',

    addNewPhone: '添加新电话',
    itTakesMoreTimeThanUsual: '验证过程比预期的时间更长。 验证完成后，我们会通知您。',
    confirmOwnerOfPhoneTitle: '确认手机号码所有权',
    confirmOwnerOfPhoneDescription: '您在 Telegram 上的手机号码是您的吗？',
    useOTPInstead: '要添加您的手机号码，请使用短信验证方法',
    yes: '是',
    no: '否',

    info: "信息",
    itsLegalTelegramPremium: "- 电报高级将合法、即时地激活，无需登录您的帐户。",
    youShouldHaveUsername: '- 在激活时，您的帐户必须有一个用户名。',
    youShouldNotToChangeUsername: '- 请在流程结束之前避免更改您的用户名。',
    telegramPremiumWasSuccessful: '操作成功，请在电报确认高级会员身份之前不要更改您的用户名',
    upgradeToPremium: '升级到高级版',
    'TG-PREMIUM-12M': '1年',
    'TG-PREMIUM-6M': '6个月',
    'TG-PREMIUM-3M': '3个月',
    backToHomeScreen: '返回主屏幕',

    descriptionTitle: '与我们一起体验最佳的数字货币买卖体验',
    description1Title: '专属钱包',
    description1Text: '购买和出售热门加密货币，在一个地方跟踪您的余额，具有专用钱包和直接加密货币存款的唯一地址',
    description2Title: '连接到矿工的能力',
    description2Text: '轻松将您的钱包地址连接到矿工并提取您的收益',
    description3Title: '高安全性',
    description3Text: '所有信息将加密存储在值得信赖的AWS数据中心',
    description4Title: '竞争性费用',
    description4Text: '我们的费用非常低（0.1%），并随着交易增加而减少',
    description5Title: '快速卡付款',
    description5Text: '使用所有Shetab会员卡以本地货币存款和提款',
    description6Title: '24/7支持',
    description6Text: '选择数字货币交易所的主要标准之一是其支持。Zulutex支持团队全天候在线为您提供支持，确保您可以无任何问题地使用交易服务',

    contactUsInfo: '021228767753 | support@Zulutex.app',
    contactUsDetails: '在线支持全天候可用。您还可以在周六至周三的上午10点至下午4点之间通过电话与我们联系',
    copyright: '本网站的所有权属于Zulutex（v1.0.0）',
    totalBalance: '账户余额',

    links: 'Zulutex',
    about: '关于我们',
    tosTitle: '服务条款和隐私政策',
    levels: '用户等级',

    loginToZulutex: '登录到Zulutex',
    toStartInterPhoneNumber: '请输入您的手机号码开始',
    insertOtpCode: '输入收到的一次性短信代码',
    phoneNumberShouldBeForYour: '手机号码应属于您',
    beSureInDomainBefore: '确保您在域 ',
    beSureInDomainAfter: ' 中',

    loginTitle: '您唯一需要的钱包',
    loginSubTitle: '只需点击几下，进入加密货币的世界！',
    sendOtp: '发送验证码',

    send: '发送',
    receive: '接收',
    swap: '交换',
    logout: '登出',

    sharePhoneNumberRequest: '为了使用所有 SwapWallet 服务，请分享您的手机号码。',
    upgradeKycLevelRequest: '要进行里亚尔的存款和取款，需要完成您的身份验证过程。',

    security: '安全',
    settings: '设置',
    creditCards: '信用卡',

    rialOperations: '里亚尔操作',
    depositRial: '存款里亚尔',
    withdrawRial: '提款里亚尔',
    kyc: 'KYC',

    topup: '充值',
    topupDetails: '您的钱包余额不足以执行此交易，请使用以下选项之一增加您的钱包余额。',

    verifyYourAccount: '验证您的帐户',
    verifyYourAccountDescription: '要使用信用卡增加您的余额，必须验证您的帐户。',
    verifyAccountButton: '验证',

    buyWithCreditCard: '使用信用卡购买',
    buyWithWallet: '从另一个钱包存款',


    copy: '复制',
    share: '分享',
    sendFromAnotherWallet: '从另一个钱包发送',

    comingSoon: '即将推出',

    tonCoin: 'TonCoin',
    tether: 'Tether',
    tron: 'Tron',
    ethereum: 'Ethereum',
    binanceCoin: 'BinanceCoin',
    bitCoin: 'BitCoin',
    sonix: 'Sonix',

    currentLevel: '当前等级',
    'LEVEL_0': '基础',
    'LEVEL_1_GLOBAL': '一级',
    'LEVEL_1_IR': '一级',
    'LEVEL_2_IR': '二级',

    AYANDEH: 'Ayandeh',
    EGHTESAD_NOVIN: 'Eghtesad Novid',
    ANSAR: 'Ansar',
    IRAN_ZAMIN: 'Iran Zamin',
    PARSIAN: 'Parsian',
    PASARGAD: 'Pasargad',
    POST_BANK: 'Post Bank',
    TEJARAT: 'Tejarat',
    TOSEE_TAAVON: 'Tosee Taavon',
    TOSEE_SADERAT: 'Tosee Saderat',
    HEKMAT_IRANIAN: 'Hekmat Iranian',
    KHAVARMIANEH: 'Khavarmianeh',
    DAY: 'Day',
    RESALAT: 'Resalat',
    REFAH_KARGARAN: 'Refah Kargaran',
    SAMAN: 'Saman',
    BLUBANK: 'ḃluBank',
    SEPAH: 'Sepah',
    SARMAYEH: 'Sarmayeh',
    SINA: 'Sina',
    SHAHR: 'Shahr',
    SADERAT: 'Saderat',
    SANAT_MADAN: 'Sanat Madan',
    MEHR_IRAN: 'Mehr Iran',
    GHAVAMIN: 'Ghavamin',
    KARAAFARIN: 'Karafarin',
    KESHAVARZI: 'Keshavarzi',
    GARDESHGARI: 'Gardeshgari',
    MARKAZI: 'Markazi',
    MASKAN: 'Maskan',
    MELLAT: 'Mellat',
    MELLI: 'Melli',
    KOSAR: 'Kosar',
    MELALL: 'Melall',
    TOSEE: 'Tosee',

    SWAP_WALLET: 'Zulutex',
    TRUST_WALLET: 'Trust Wallet',
    METAMASK: 'Metamask',
    TON_KEEPER: 'Ton Keeper',
    TONHUB: 'Ton Hub',

    SIGNUP: '注册',
    PHONE_NUMBER: '验证手机号码',
    PERSONAL_INFORMATION: '验证个人信息',
    NATIONAL_ID_IMAGE: '国民身份证照片',
    SELFIE_VIDEO: '自拍视频',

    setPhoneNumber: '分享手机号码',
    setPersonalInfo: '设置个人信息',
    setCreditCard: '设置信用卡',
    setLocation: '设置位置',
    setHomePhoneNumber: '设置家庭电话号码',
    setVideo: '设置自拍视频',
    signupInWebsiteOrTelegram: '在网站或Telegram上注册',
    sorryPersonalInformationNotFound: '抱歉，未找到您的信息',
    pleaseFillFormWithYourPersonalInfo: '请使用您的个人信息填写表格',
    pleaseInsertYourNationalCode: '请插入您的国民身份证',
    pleaseInsertYourBirthDate: '请插入您的出生日期',
    iWillAcceptAllRules: '我已阅读并接受使用Zulutex服务的所有条款和条件。',
    confirm: '确认',

    pleaseSelectCoin: '请选择一种币种',
    pleaseOnlySendToThisNetwork: '只发送 __COIN__ （__NETWORK__） 到此地址。发送其他币种可能导致永久丧失',
    withKYCYouHaveLowerLimits: '使用KYC，您在存款和提款方面的限制更低',

    startKYC: '开始KYC',
    continueKYC: '继续KYC流程',
    neededDocuments: '手机号码，国民身份证，出生日期',
    neededOneMinutes: '1分钟',
    depositRialCashInCode: '+ 使用存款标识码无限制',
    withdrawRialLevelOne: '可以每天提取100百万伊朗里亚尔',
    depositRialLevelOne: '法定货币存款',
    depositCryptoLevelOne: '加密货币存款：无限制',
    withdrawCryptoLevelOne: '提款加密货币：每日20美元',

    cashInCodeNewOne: '- 使用识别存款，您可以通过PAIA存款在没有任何限制的情况下增加余额。',
    cashInCodeNewTwo: '- 识别存款会自动确认，您无需通知SwapWallet您的存款。',
    cashInCodeNewThree: '- 存款后，您的余额将在PAIA第一个周期后一小时内自动增加。',

    cashInCodeWarningOne: '- 在任何情况下都不要使用桥接方法。',
    cashInCodeWarningTwo: '- 存款的来源账户必须属于您。',
    cashInCodeWarningThree: '- 输入存款识别码是强制性的。',

    cashInCodeWarningTitle: '识别存款前的重要提示',

    liveness: '活体验证',
    canBeTakeTime: '（加载数据可能需要一些时间，请耐心等待）',
    ok: '好的',
    livenessLearning: '在看着相机的同时，轻轻转动头部，使所有点变绿',

    selfieVideo: '自拍视频',
    livenessDescription: '将相机放在面前，缓慢转动头部，直到整个圆圈变绿。',
    selfieVideoDescription: '在看着相机的同时，按下录制按钮，并用清晰的声音读出下面的文本',

    nationalIdScan: '扫描文件',
    frontScanDescription: '请将您的智能国民身份证卡放在指定区域。在适当的光线和黑暗背景的环境中使用。',
    backScanDescription: '现在旋转您的国民身份证，如前所述，将卡放在指定区域。在适当的光线和黑暗背景的环境中使用。',

    uploadDocuments: '上传中',
    uploadDocumentsDescription: '正在将信息上传到服务器，请耐心等待并保持此页面打开，直到过程完成',
    uploadDocumentsDone: '专家审核信息后，我们将通过短信向您通报结果',
    uploadFailed: '上传文件到服务器时出错。单击下面的按钮重试',

    back: '返回',

    uploadNationalId: '上传文件',
    uploadNationalIdDescription: '请选择您国民身份证正反面的高质量图像。',
    frontOfNationalId: '- 国民身份证正面',
    backOfNationalId: '- 国民身份证背面',
    upload: '上传',

    neededDocuments2: '国民身份证和自拍视频',
    neededOneMinutes2: '3分钟',
    depositRialLevelOne2: '无限法定货币存款',
    depositCryptoLevelOne2: '法定货币提款',
    withdrawCryptoLevelOne2: '提款加密货币：每日2,000美元',

    verifyPhoneNumber: '验证手机号码',
    verifyPhoneNumberDesc: '使用以下方法验证您的手机号码。',
    verifyPhoneNumberDesc2: '（手机号码必须属于您）',
    verifyPhoneWithShareNumber: '通过分享电话号码进行验证',
    verifyPhoneWithSMS: '通过短信进行验证',

    kycUserInformation: '用户信息',
    kycUserInformationDesc: '请使用您的个人信息填写表格',
    kycUserInformationDesc2: '🔒 您的身份信息将被安全加密并保存在我们这里，仅用于防止洗钱和钓鱼攻击。此外，如果与民事登记系统存在差异，您的身份验证过程将面临问题。',

    memoBasedAlertText: "要存入这种加密货币，除了地址之外，还必须输入正确的备注。否则，您的资产将会丢失。",
    memoBasedIConfirmed: "我明白了",
    memoBasedDepositWarning: "除了地址外，一定要输入该加密货币的备注。否则，您的资产将会丢失。",

    assets: '资产',

    tosHeader: '服务条款',
    tosHeaderDesc: '在确认之前，请仔细阅读Zulutex的使用条款',
    tos: '网站用户接受Zulutex所有活动必须符合设定的框架，并且不得从事任何超出此范围的活动。\n' +
        '使用Zulutex服务需要进行身份验证，用户需要上传正确的信息。很明显，如果在身份验证中违反任何规定，则责任由违规者承担，并且Zulutex有权停止向该用户提供服务，并将违规行为提交给有关部门。Zulutex有责任保护其用户的个人信息。\n' +
        'Zulutex的用户承诺仅为自己使用Zulutex站点服务，对于未经身份验证的人使用Zulutex服务的责任将由用户负责。向站点公布的帐户号码以及提取加密货币的钱包地址必须属于用户，不允许用户提供属于其他人的钱包地址。\n' +
        'Zulutex用户同意在被Zulutex支持专家诊断时，与Zulutex团队合作以维护其用户帐户资产的安全性。此外，根据司法机构的命令，在存款后的24至72个工作小时内，可能限制提取加密货币的时间。\n' +
        'Zulutex用户承认他们知道与投资数字货币相关的风险，并在这种知识的基础上进行投资和交换。\n' +
        '作为数字货币交易的在线市场，Zulutex对用户的交易方式以及由此产生的利润和损失没有任何责任。\n' +
        '用户在维护安全信息（包括用户密码）方面的任何疏忽都是用户的责任，Zulutex将不承担任何责任。强烈建议使用复杂和安全的密码和两步验证代码。\n' +
        'Zulutex确保用户的资产以最佳方式和最高可能的安全标准保管。在发生任何安全问题的情况下，Zulutex将有责任赔偿损害。\n' +
        '如果要提取数字货币，用户有责任提供正确的钱包地址。Zulutex不对包括输入正确地址的错误、地址错误、目标钱包的问题以及用户资产在目标钱包中的阻塞等问题负责。\n' +
        'Zulutex对在错误的平台上存入代币或币种或者在Zulutex不支持的币种上存入代币或币种，以及由于冷库机制的原因，使用第三方钱包无法提取这些代币的任何责任概不负责。因此，用户对任何错误存款负责，用户无权投诉。\n' +
        '用户接受，除非涉及Zulutex的承诺，否则他将没有权利对Zulutex站点、管理人员、员工和与该站点相关的人提出任何索赔、要求和投诉。\n' +
        '在用户死亡的情况下，Zulutex有权将用户的资金存入公司在注册时用户声明的账户中。Zulutex被委托将其转换为当天价格的法定等值物并存入上述账户，并且该权利和特权由用户授予Zulutex。应注意的是，用户的加密货币资产的卖出价格计算的时间标准是基于受益人提交积极文件并由Zulutex法务部门确认其真实性。在了解并理解本段规定的情况下，用户在Zulutex中开设了用户帐户，用户、第三方、继承人或法定代表在将来不得对此提出抗议或投诉。\n',

    kycWasSuccessful: '您的KYC成功了',
    kycWasSuccessfulDesc: '恭喜！您的帐户级别已升级为“一级”，在此级别中，您每天最多可以存款1,500万Tomans。',

    rialDeposit: '存款里亚尔',

    kycWasNotSuccessful: 'KYC失败了',
    kycWasNotSuccessfulDesc: '请再次检查您输入的信息。请注意，手机号码必须属于输入的国民代码。',
    retry: '重试',

    redirectingToPaymentGateway: '重定向到支付网关',
    redirectToPaymentGateway: '重定向到支付网关',
    connectionFromOutsideOfIran: '来自伊朗以外的连接',
    sorryButYouShouldTurnOffYourVPN: '抱歉，支付网关不允许来自伊朗以外的连接。请关闭您的VPN，然后单击下面的按钮',

    returnToWallet: '返回到钱包',
    returnToWebsite: '返回到钱包',
    yourPaymentWasSuccessful: '您的付款成功',
    yourPaymentWasNotSuccessful: '您的付款未成功',
    yourWalletHasBeenCharged: '您的钱包已充值，您可以通过单击下面的按钮返回到您的钱包',
    nowYouCanTurnVPNOnAgain: '现在您可以再次打开VPN',
    paymentId: '支付ID',
    orderId: '订单ID',
    contactSupport: '联系支持',
    ifAmountHasBeenDeductedItWillRefund: '如果金额已从您的帐户扣除，它将在72小时内退还到您的帐户',
    notePaymentShouldDoneUsingSelectedCreditCard: '注意：应使用选择的信用卡进行付款',

    paymentTransaction: '从商店购买',
    giveawayPrize: '获得奖品（空投）',
    creditReason: '奖品类型',
    adCheque: '激活广告支票',
    adChequeReferral: '广告支票推荐',
    giveawayParticipate: '参与抽奖',
    value: '价值（美元）',
    product: '产品',
    paidWith: '支付金额',
    invoiceId: '收据编号',
    shop: '商店',

    giveawayEndTime: '结束日期',
    giveawayTitle: '抽奖文本',
    banner: '横幅',
    titlePlaceholder: '🎁 抽奖1000 Tether...（markdown，最大长度：500）',
    marketPlace: '市场',
    giveaway: '抽奖',
    giveawayDescription: '用于推广您的频道、吸引新用户或奖励当前用户的合适工具。',
    prizes: '奖品',
    totalPrizes: '总奖金',
    giveawayLimitations: '限制',
    tokenType: '奖品类型',
    addPrize: '添加奖品',
    addLimit: '添加限制',
    telegramPremium: 'Telegram高级版',
    telegramPremiumDescription: "使用TON硬币，无中介，即时合法升级您的Telegram到高级版。",
    productOutOfOrder: '此产品尚未激活，请联系支持。',

    gatewayOutOfOrder: '支付网关故障，请使用银行转账',
    connectionError: '连接错误',
    connectionToPaymentGatewayFailed: '连接到支付网关失败',
    pleaseSelectACardOrAddANewCard: '请选择信用卡或添加新卡',
    pleaseInsertTheAmount: '请插入金额',
    pay: '支付',
    holdToPay: '按住支付',
    holdToSwapAndPay: '按住以交换和支付',
    holdToTopUp: '充值',
    youDoNotHaveEnoughMoneyToPay: '您没有足够的钱支付',
    minimumAmountStart: '大约',
    minimumAmountEnd: '将从您的余额中转换为所需货币，以最佳市场汇率支付此收据',
    cardNumberIsNotValid: '信用卡号无效',
    cardSuccessfullyAdded: '信用卡成功添加',
    cardFailedToAdd: '信用卡添加失败',
    addANewCard: '添加新信用卡',
    warning: '警告',
    youOnlyCanAddCreditCardThatIsForYou: '由于网络警察的命令，为防止网络钓鱼和洗钱，只有可以根据您的手机号码添加卡的可能性。只能使用预定义的卡进行付款。',
    add: '添加',
    cancel: '取消',
    thereIsNoCreditCard: '没有信用卡',
    minimumDepositAmountIs150: '最低存款金额为5,000 Toman',
    maximumDepositAmountIs25M: '最大存款金额为25M Toman',

    addANewIban: '添加新的IBAN',
    ibanSuccessfullyAdded: '成功添加新的IBAN',
    youOnlyCanAddIbanThatIsForYou: '根据FATA警察的命令，为防止网络钓鱼和洗钱犯罪，您只能添加与您的手机号码相对应的IBAN。结算仅可到您自己的账户。',
    thereIsNoIban: '没有可用的IBAN',
    minimumWithdrawAmountIs5: '最低提款金额为5,000 Toman',
    maximumWithdrawAmountIs100M: '每日最大提款金额为100百万Toman',
    pleaseSelectAIbanOrAddANewIban: '选择现有的IBAN或添加一个新的',
    settle: '提款',
    transactionFeeIsForBroker: '(Zulutex不是此费用的受益者)',
    receiverName: '收款人姓名',
    ifPossibleWeUseInstantWithdraw: '⚡ 如果可能，结算将立即进行，不到15分钟内完成。',
    receiveTime: '存款时间',
    receiveTimeResult: '在下一个周期的第一个ACH周期内',
    sentToBank: '存入银行账户',
    ibanNumber: '收款人的IBAN',
    gateway: '支付网关',
    cashInCode: '存款码',
    cashInCodeDescription: '通过将金额发送到指定的账户号码，无限制地为您的Rial账户充值。确保在手机银行，网上银行或银行支付单的付款ID部分输入创建的代码。存款后，金额将自动记入您的账户。',
    cashInCodeNumber: '存款码：',
    iban: 'IBAN：',
    ibanNumberTooltip: 'IBAN号码',
    ibanNumberTooltipCopied: 'IBAN号码已复制',
    accountNumber: '账户号码：',
    accountNumberTooltip: '账户号码',
    accountNumberTooltipCopied: '账户号码已复制',
    accountHolderName: '账户持有人姓名：',
    noticeUseCashInCode: '必须输入在存款码部分',
    cashInCodeDescriptionTwo: '除桥梁外，可以使用所有付款方法进行存款。请注意，源账户必须属于您自己。',

    market: '市场',
    payAmount: '支付金额',
    receiveAmount: '收款金额',
    tradeRate: '汇率',
    bankFee: '银行费用',
    cardNumber: '信用卡号',
    refId: 'Ref-Id',
    hash: '交易哈希',
    uniqueId: '交易唯一标识',

    sufficientBalance: '余额充足',
    transactionWasSuccessful: '交易成功',
    transactionWasNotSuccessful: '交易失败',
    quickSwap: '快速交换',
    fee: '费用',
    youPay: '您支付',
    youReceive: '您接收',
    balance: '余额',
    increaseBalanceByRequiredAmount: '按所需金额增加余额',

    transferToAddress: '转账至',
    receiveFromAddress: '接收自',
    swapTo: '交换 __SOURCE__ 至 __DEST__',
    thisCoinIsNotActiveYet: '此币种尚未激活',
    thereIsNoTransaction: '没有交易',

    buyFromBankGateway: '使用网关存款',
    cashInCodeName: '使用银行转账存款',
    ibanNumberSender: "汇款人的IBAN",

    selectToken: '选择币种',
    selectNetwork: '选择网络',
    selectNetworkPlaceHolder: '请选择提款网络',

    USER_DO_NOT_CONSIDER_NETWORK_FEE: "余额不足以支付费用。",
    USER_DO_NOT_CONSIDER_NETWORK_FEE_APPLY: "修复它",

    LIMIT_DAILY_WITHDRAW_EXCEEDED: '超过每日提款限制',
    LIMIT_MONTHLY_WITHDRAW_EXCEEDED: '超过每月提款限制',
    INSUFFICIENT_USER_BALANCE: '用户余额不足',
    USER_BALANCE_LOCKED: '用户余额已锁定',

    TRANSACTION_CREATED: '生成交易',
    TRANSACTION_CREATED_DONE: '交易已创建',
    ACCEPTED_BY_ADMIN: '等待管理员接受',
    ACCEPTED_BY_ADMIN_DONE: '交易已被接受',
    PUBLISHED_TO_NETWORK: '发布交易',
    PUBLISHED_TO_NETWORK_DONE: '交易已发布',
    CONFIRMED_BY_NETWORK: '等待网络确认',
    CONFIRMED_BY_NETWORK_DONE: '交易完成',
    status: '状态',

    badInputValues: '错误的输入值',
    badWalletAddressFormat: '错误的钱包地址格式',
    requestedAmountIsMoreThanYourWithdrawalLimit: '请求的金额超过了您的提款限制',
    walletAddress: '钱包地址',
    pleaseInsertAmountWithoutFee: '请插入不带费用的金额',
    amount: '金额',
    notePleaseSelectNetworkCarefully: '请仔细选择网络。输入错误的地址可能导致您的财务资源丧失',
    continue: '继续',
    withdrawLimit: '提款限制',
    errorOccurred: '发生错误',
    transactionFee: '交易费',
    receivedAmount: '接收金额',
    balanceAfter: '余额之后',
    thisTransactionWillBeDoneWithoutFeeAndFast: '此交易将在没有费用和快速的情况下完成',
    transfer: '转账',
    networkConfirmationMayTakeLonger: '网络确认可能需要更长时间，金额将在几分钟内发送到目标钱包',
    ifAmountHasBeenDeductedContactSupport: '如果金额已从您的帐户扣除，请联系支持',
    viewTransaction: '查看交易',

    interfaceLanguage: '界面语言',
    apiKey: 'API密钥',
    copyApiKey: '复制API密钥',

    USDT: 'Tether',
    TRX: 'Tron',
    IRT: 'Toman',
    BTC: 'BitCoin',
    ETH: 'Ethereum',
    TON: 'TonCoin',
    BNB: 'BinanceCoin',
    SNX: 'Sonix',
    ADA: 'Cardano',
    AVAX: 'Avalanche',
    DOGE: 'DogeCoin',
    SHIB: 'Shiba Inu',
    SOL: 'Solana',
    XRP: 'Ripple',
    NOT: 'NotCoin',
    HMSTR: 'HamsterKombat',

    hamsterShareMessage: "你好！\n" +
        "SwapWallet是一个安全可靠的Telegram钱包，可以使用加密货币进行支付！\n" +
        "\n" +
        "在这个加密钱包中，你可以免费向朋友发送加密货币，轻松买卖，加购Telegram Premium等等。\n" +
        "\n" +
        "🐹 顺便说一下，你知道Hamster Kombat即将上线吗？你可以在这里以全球价格出售你的游戏币。\n" +
        "🎁 现在使用我的链接加入SwapWallet，可获得Hamster交易手续费10%的折扣。",

    hamsterBannerTextFirstLine: '40%的交易费用',
    hamsterBannerTextSecondLine: '是你推荐我们给朋友的奖励',
    hamsterBannerLink: '点击邀请 ›',

    hmsWarning: '一旦可以从这个游戏中提取硬币，您就可以使用以下信息将硬币存入 SwapWallet，并成为第一批出售硬币的人之一。',

    withdrawAndDepositLimitedWarning: '此币种的提现和存款暂时受限',

    toman: '里亚尔',
    network: '网络',
    date: '日期',
    receiverAddress: '接收方地址',
    senderAddress: '发送方地址',
    showOnBlockScan: '在区块扫描上显示',
    user: '用户',

    swapAndTransfer: '交换和转账',
    checkAndTransfer: '检查和转账',
    increaseBalance: '增加余额',
    swapAndTransferDesc: '您没有足够的目标币种余额来执行此交易，通过点击下面的选项，您可以使用可转换的资产来补充目标币种，最终执行转账。',
    youHaveNotEnoughBalanceEn: '您没有足够的余额来进行交换。',

    birthDate: '出生日期',

    checkingUserInformation: '正在检查用户信息',
    checkingUserInformationDesc: '正在使用国民ID系统检查用户信息，请稍候。',

    swapTourSourceTokenTitle: '源代币类型',
    swapTourSourceTokenDescription: '点击此选项，您可以更改支付加密货币的类型',
    swapTourDestTokenTitle: '目标代币类型',

    swapTourDestTokenDescription: "通过点击此选项，您可以更改接收到的加密货币的类型",
    swapTourSourceInputTitle: "付款金额",
    swapTourSourceInputDescription: "在此输入您要转换的源加密货币的金额",
    swapTourDestInputTitle: "接收金额",
    swapTourDestInputDescription: "系统为您找到最低的兑换率",
    swapTourTotalAmountTitle: "总余额",
    swapTourTotalAmountDescription: "通过点击此选项，您可以选择要转换的源加密货币的总余额",
    swapTourSwapButtonTitle: "交换",
    swapTourSwapButtonDescription: "最后，如果您同意兑换率，可以通过点击此选项完成转换"
}
export default ZH_LOCAL
