import config from '../config'
import axios from "axios";
import telegram from '../utils/telegram'
import TinyCache from 'tinycache'
import {getUserInfo} from '../utils/user'
import storage from "@/src/utils/storage";
import device from "@/src/utils/device";
import LockFactory from "@/src/utils/Lock";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const cache = new TinyCache()
const ttl = 60 * 1000

const KEYS = {
    CREDIT_CARDS: 'CREDIT_CARDS',
    BALANCE: 'BALANCE',
    SWAP_RATE: 'SWAP_RATE',
    USER_INFO: 'USER_INFO',
    FREQUENT_CONTACTS: 'FREQUENT_CONTACTS',
    RECENT_INTERACTIONS: 'RECENT_INTERACTIONS',
    WITHDRAW_OPTIONS: 'WITHDRAW_OPTIONS',
    USER_KYC_INFO: 'USER_KYC_INFO',
    IBAN: 'IBAN',
    CASH_IN_CODE: 'CASH_IN_CODE',
}

class Exchange {
    client = null
    uploadInfo = {}

    customHeaders = config.env === 'development' ? {
        "cf-connecting-ip": "1.1.1.1",
        "cf-ipcountry": "IR",
    } : {}

    async _getNewClient() {
        const authToken = getUserInfo().token
        if (authToken) {
            return axios.create({
                baseURL: `${config.api.baseUrl}`,
                timeout: 30000,
                headers: {
                    Authorization: `Bearer ${getUserInfo().token}`,
                    ...this.customHeaders,
                }
            })
        }

        if (telegram.areWeInTelegramWebApp()) {
            const r = await axios.post(`${config.api.baseUrl}/v1/authentication/telegram-webapp`, {
                telegramData: telegram.getInitData(),
                deviceInformation: await device.getDeviceInfo(),
            }, {headers: this.customHeaders})

            const token = r.data.result.token
            storage.set('user', {token: token});
            return axios.create({
                baseURL: `${config.api.baseUrl}`,
                timeout: 30000,
                headers: {
                    Authorization: `Bearer ${token}`,
                    ...this.customHeaders
                }
            })
        }

        return axios.create({
            baseURL: `${config.api.baseUrl}`,
            timeout: 30000,
            headers: this.customHeaders,
        })
    }

    async getClient() {
        const lock = LockFactory.getLock("get-api-client")

        await lock.acquire()
        try {
            if (!this.client) {
                this.client = await this._getNewClient() // TODO: re-auth if needed
            }
            return this.client
        } finally {
            await lock.release()
        }
    }

    removeBalanceCache() {
        cache.del(KEYS.BALANCE)
    }

    async captchaRequest(referredById, chequeId) {
        const client = await this.getClient()
        const r = await client.post('/v1/ad-cheque/captcha/request', {
            referredById,
            chequeId,
            deviceInformation: await device.getDeviceInfo(),
        })
    }

    async resolveChequeCaptcha(referredById, chequeId, captchaToken) {
        const client = await this.getClient()
        const r = await client.post('/v1/ad-cheque/captcha/verify', {
            referredById,
            chequeId,
            captchaToken,
            deviceInformation: await device.getDeviceInfo(),
        })
    }

    async getUserBalance(ignoreCache = false) {
        if (!ignoreCache && cache.get(KEYS.BALANCE)) {
            return cache.get(KEYS.BALANCE)
        }

        const client = await this.getClient()
        const r = await client.get('/v1/user/balance')

        cache.put(KEYS.BALANCE, r.data.result)
        return r.data.result
    }

    async newPhoneSendOtp(phone) {
        const client = await this.getClient()
        const r = await client.post('/v1/user/new-phone/otp', {
            region: "IR",
            number: phone,
            deviceInformation: await device.getDeviceInfo(),
        })
    }

    async sendOtp(phone) {
        const r = await axios.post(`${config.api.baseUrl}/v1/authentication/otp`, {
            region: "IR",
            number: phone,
            deviceInformation: await device.getDeviceInfo(),
        })
    }

    async updateLanguage(language) {
        const client = await this.getClient()
        await client.patch(`${config.api.baseUrl}/v1/user`, {language})
    }

    async verifyPhoneSendOtp(phone, otp) {
        const client = await this.getClient()
        const r = await client.post('/v1/user/new-phone/otp/verify', {
            region: "IR",
            number: phone,
            code: otp,
            deviceInformation: await device.getDeviceInfo(),
        })
    }

    async verifyOtp(phone, otp) {
        const r = await axios.post(`${config.api.baseUrl}/v1/authentication/otp/verify`, {
            region: "IR",
            number: phone,
            code: otp,
            deviceInformation: await device.getDeviceInfo(),
        })
        storage.set('user', {token: r.data.result.token});

        this.client = axios.create({
            baseURL: `${config.api.baseUrl}`,
            timeout: 30000,
            headers: {
                Authorization: `Bearer ${getUserInfo().token}`,
            }
        })
    }

    async updateReferrer(userId) {
        const client = await this.getClient()
        await client.patch('/v1/user/referrer', {userId})
    }

    async updateUtm(campaignCode) {
        const client = await this.getClient()
        await client.patch('/v1/user/utm', {campaignCode})
    }

    async getUserInformation(ignoreCache = false) {
        if (cache.get(KEYS.USER_INFO) && !ignoreCache) {
            return cache.get(KEYS.USER_INFO)
        }

        const client = await this.getClient()
        const r = await client.get('/v1/user/whoami')

        cache.put(KEYS.USER_INFO, r.data.result)
        return r.data.result
    }

    async getFrequentContacts(type, page = 0, limit = 20, ignoreCache = false) {
        const key = `${KEYS.FREQUENT_CONTACTS}:${page}:${limit}`
        if (cache.get(key) && !ignoreCache) {
            return cache.get(key)
        }

        const client = await this.getClient()
        const r = await client.get(`/v1/contacts/frequent?type=${type}&page=${page}&limit=${20}`)

        cache.put(key, r.data.result)
        return r.data.result
    }

    async getRecentWalletInteractions(networks, page = 0, limit = 20, ignoreCache = false) {
        const key = `wallet-${KEYS.RECENT_INTERACTIONS}:${page}:${limit}-${networks.sort().join('-')}`
        if (cache.get(key) && !ignoreCache) {
            return cache.get(key)
        }

        const client = await this.getClient()
        const r = await client.get(`/v1/contacts/recent/wallet?networks=${networks.join(',')}&page=${page}&limit=${20}`)

        cache.put(key, r.data.result)
        return r.data.result
    }

    async getRecentUsersInteractions(page = 0, limit = 20, ignoreCache = false) {
        const key = `users-${KEYS.RECENT_INTERACTIONS}:${page}:${limit}`
        if (cache.get(key) && !ignoreCache) {
            return cache.get(key)
        }

        const client = await this.getClient()
        const r = await client.get(`/v1/contacts/recent/users?page=${page}&limit=${20}`)

        cache.put(key, r.data.result)
        return r.data.result
    }

    async clearRecentInteractions(type) {
        //users wallet
        const client = await this.getClient()
        const r = await client.delete(`/v1/contacts/recent/${type}`)
        return r.data.result
    }

    async _getWithdrawOptions(client, token, address) {
        if (!address) {
            return client.get(`/v1/wallet/withdraw/${token}/options`)
        }
        return client.get(`/v1/wallet/withdraw/${token}/${address}/options`)
    }

    async getWithdrawOptions(token, address, ignoreCache) {
        const key = `${KEYS.WITHDRAW_OPTIONS}:${token}:${address || null}`
        if (cache.get(key) && !ignoreCache) {
            return cache.get(key)
        }

        const client = await this.getClient()
        const r = await this._getWithdrawOptions(client, token, address)

        cache.put(key, r.data.result)
        return r.data.result
    }

    async getWallet(ignoreCache = false) {
        if (!ignoreCache && cache.get(KEYS.BALANCE)) {
            return cache.get(KEYS.BALANCE)
        }

        const client = await this.getClient()
        const r = await client.get('/v1/user/balance')

        cache.put(KEYS.BALANCE, r.data.result)
        return r.data.result
    }

    async getKycInformation(ignoreCache = false) {
        if (cache.get(KEYS.USER_KYC_INFO) && !ignoreCache) {
            return cache.get(KEYS.USER_KYC_INFO)
        }

        const client = await this.getClient()
        const r = await client.get('/v1/kyc')

        cache.put(KEYS.USER_KYC_INFO, r.data.result)
        return r.data.result
    }

    async updatePersonalInformation(nationalId, birthDate) {
        const client = await this.getClient()
        await client.post('/v1/kyc/personal-information', {
            nationalId,
            birthDate
        })
        cache.del(KEYS.USER_KYC_INFO)
        cache.del(KEYS.USER_INFO)
    }

    retrySendFailedDocuments() {
        for (let key of Object.keys(this.uploadInfo)) {
            const {error, blob} = this.uploadInfo[key]
            if (error) {
                this.uploadInfo[key].error = false
                this.uploadInfo[key].loaded = 0
                this.uploadInfo[key].total = 1
                this.uploadDocumentVideo(blob, key)
            }
        }
    }

    getUploadStatus() {
        let hasActive = false
        for (let key of Object.keys(this.uploadInfo)) {
            const {error, total, loaded} = this.uploadInfo[key]

            if (error) {
                return "exception"
            }

            if (total !== loaded) {
                hasActive = true
            }
        }

        if (hasActive) {
            return "active"
        }
        return "success"
    }

    getUploadPercentage() {
        let total = 0
        let loaded = 0

        Object.keys(this.uploadInfo).map(key => {
            total += this.uploadInfo[key].total
            loaded += this.uploadInfo[key].loaded
        })

        if (total === 0) {
            return 100
        }

        let percent = Math.floor((loaded * 100) / total);
        return Math.max(0, percent)
    }

    async uploadDocumentVideo(blob, type) {
        const client = await this.getClient()

        const form = new FormData();
        form.append('type', type)
        form.append('data', blob)

        this.uploadInfo[type] = {
            blob: blob,
            error: false,
            loaded: 0,
            total: 1,
        }

        try {
            await client.post('/v1/kyc/documents', form, {
                timeout: 600000,
                headers: {"Content-Type": "multipart/form-data"},
                onUploadProgress: progressEvent => {
                    const {loaded, total} = progressEvent;
                    this.uploadInfo[type].loaded = loaded
                    this.uploadInfo[type].total = total
                },
            })
        } catch (e) {
            if (e?.response?.data?.error?.codeString === "DUPLICATE_KYC_DOCUMENT") {
                cache.del(KEYS.USER_KYC_INFO)
                cache.del(KEYS.USER_INFO)
                this.uploadInfo[type].loaded = this.uploadInfo[type].total
            } else {
                this.uploadInfo[type].error = true
            }
        } finally {
            cache.del(KEYS.USER_KYC_INFO)
            cache.del(KEYS.USER_INFO)
        }
    }

    async getRialWithdrawPreview(iban, amount) {
        const client = await this.getClient()
        const r = await client.get(`/v1/bank/withdraw/preview?iban=${iban}&amount=${amount}`)
        return r.data.result
    }

    async rialWithdraw(iban, amount, fee) {
        const client = await this.getClient()
        const r = await client.post(`/v1/bank/withdraw`, {iban, amount, fee})
        return r.data.result
    }

    async getUserIban() {
        if (cache.get(KEYS.IBAN)) {
            return cache.get(KEYS.IBAN)
        }

        const client = await this.getClient()
        const r = await client.get('/v1/bank/iban')

        cache.put(KEYS.IBAN, r.data.result, ttl)
        return r.data.result
    }

    async addIban(number) {
        const client = await this.getClient()
        await client.post('/v1/bank/iban', {number})
        cache.del(KEYS.IBAN)
        cache.del(KEYS.USER_INFO)
    }

    async createNewAdCheque(body) {
        const client = await this.getClient()
        const r = await client.post('/v1/ad-cheque', body)
        return r.data.result
    }

    async getUserAdCheques() {
        const client = await this.getClient()
        const r = await client.get('/v1/ad-cheque')
        return r.data.result
    }

    async payAdCheque(id) {
        const client = await this.getClient()
        const r = await client.post(`/v1/ad-cheque/${id}/pay`)
        return r.data.result
    }

    async getChequeWithId(id) {
        const client = await this.getClient()
        const r = await client.get(`/v1/ad-cheque/${id}`)
        return r.data.result
    }

    async getChequeStats(id) {
        const client = await this.getClient()
        const r = await client.get(`/v1/ad-cheque/${id}/stats`)
        return r.data.result
    }

    async topUpAdCheque(id, amount) {
        const client = await this.getClient()
        const r = await client.post(`/v1/ad-cheque/${id}/top-up`, {amount})
        return r.data.result
    }

    async deleteCheque(id) {
        const client = await this.getClient()
        const r = await client.delete(`/v1/ad-cheque/${id}`)
        return r.data.result
    }

    async getProducts() {
        const client = await this.getClient()
        const r = await client.get(`/v1/shop/product`)
        return r.data.result
    }

    async checkCanBuyTelegramPremium(months) {
        const client = await this.getClient()
        const r = await client.get(`/v1/fragment/telegram/premium/recipient?months=${months}`)
        return r.data.result
    }

    async newJetton(productId) {
        const client = await this.getClient()
        const r = await client.post(`/v1/jetton`, {
            productId,
        })
        return r.data.result
    }

    async getJetton(id) {
        const client = await this.getClient()
        const r = await client.get(`/v1/jetton/${id}`)
        return r.data.result
    }

    async redeemJetton(id) {
        const client = await this.getClient()
        const r = await client.post(`/v1/jetton/${id}/redeem`)
        return r.data.result
    }

    async createNewGiveaway(banner, data) {
        const client = await this.getClient()

        const form = new FormData();
        form.append('dataString', JSON.stringify(data))
        form.append('banner', banner, 'image')

        const r = await client.post('/v1/giveaway', form)
        return r.data.result
    }

    async getCashInCodeInfo() {
        if (cache.get(KEYS.CASH_IN_CODE)) {
            return cache.get(KEYS.CASH_IN_CODE)
        }

        const client = await this.getClient()
        const r = await client.get('/v1/bank/cash-in-code')

        cache.put(KEYS.CASH_IN_CODE, r.data.result)
        return r.data.result
    }

    async getUserCreditCards() {
        if (cache.get(KEYS.CREDIT_CARDS)) {
            return cache.get(KEYS.CREDIT_CARDS)
        }

        const client = await this.getClient()
        const r = await client.get('/v1/bank/credit-card')

        cache.put(KEYS.CREDIT_CARDS, r.data.result, ttl)
        return r.data.result
    }

    async addCreditCard(number) {
        const client = await this.getClient()
        await client.post('/v1/bank/credit-card', {number})
        cache.del(KEYS.CREDIT_CARDS)
        cache.del(KEYS.USER_INFO)
    }

    async getUserDepositWallet(token, network) {
        const [net, contract] = network.split("-")
        const client = await this.getClient()
        const r = await client.get(`/v1/wallet/deposit/addresses?network=${net}&token=${token}&contract=${contract}`)
        return r.data.result
    }

    async getWithdrawLimits(token) {
        const client = await this.getClient()
        const r = await client.get(`/v1/wallet/withdraw/limit?token=${token}`)
        return r.data.result
    }

    async getInvoice(id) {
        const client = await this.getClient()
        const r = await client.get(`/v1/invoice/${id}`)
        return r.data.result
    }

    async getPaymentInfo(id) {
        const client = await this.getClient()
        const r = await client.get(`/v1/payment/invoice/${id}`)
        return r.data.result
    }

    async finalizedApplicationInvoice(id) {
        const client = await this.getClient()
        const r = await client.post(`/v1/payment/invoice/${id}/pay`)
        return r.data.result
    }

    async payInvoice(id, amount) {
        const client = await this.getClient()
        const r = await client.post(`/v1/invoice/pay`, {id, amount})
        return r.data.result
    }

    async getWithdrawPreview(network, token, address, amount) {
        const [net, contract] = network.split("-")
        const client = await this.getClient()
        const r = await client.get(`/v1/wallet/withdraw/preview`, {
            params: {
                network: net,
                token,
                address,
                amount,
                contract,
                memo: null,
            }
        })
        return r.data.result
    }

    async validateAndNormalizeAddress(network, address) {
        const [net, contract] = network.split("-")
        const client = await this.getClient()
        const r = await client.get(`/v1/utils/wallet/address/normalized`, {
            params: {
                network: net,
                contract,
                address,
            }
        })
        return r.data.result.address
    }

    async withdrawCrypto(network, token, address, amount, fee, contract, memo) {
        cache.del(KEYS.BALANCE)
        const client = await this.getClient()
        const r = await client.post(`/v1/wallet/withdraw`, {network, token, address, amount, fee, contract, memo})
        return r.data.result
    }

    async transfer(userId, amount, token, fee) {
        cache.del(KEYS.BALANCE)
        const client = await this.getClient()
        const r = await client.post(`/v1/wallet/transfer`, {
            "amount": amount,
            "token": token,
            "userId": userId,
            "fee": "0",
        })
        return r.data.result
    }

    async getTransactions(token, afterId, limit = 1000000) {
        const client = await this.getClient()
        const r = await client.get(`/v1/transaction`, {
            params: {token, afterId, limit}
        })

        return r.data.result
    }

    async getTransaction(transactionId) {
        const client = await this.getClient()
        const r = await client.get(`/v1/transaction/${transactionId}`)
        return r.data.result
    }

    async getSwapMarketInformation(sourceAmount, sourceCoin, destAmount, destCoin) {
        const client = await this.getClient()
        const r = await client.post('/v1/market/otc/price', {
            sourceToken: sourceCoin,
            destinationToken: destCoin,
            sourceAmount: sourceAmount,
            destinationAmount: destAmount,
        })

        return r.data.result
    }

    async doSwap(swapToken) {
        const client = await this.getClient()
        const r = await client.post('/v1/market/otc/order', {
            swapToken,
        })

        cache.del(KEYS.BALANCE)
        return r.data.result
    }

    //////////////// OLD

    async getSwapRate(coinA, coinB) {
        const cacheKey = `${KEYS.SWAP_RATE}/${coinA}/${coinB}`
        if (cache.get(cacheKey)) {
            return cache.get(cacheKey)
        }

        const client = await this.getClient()
        const r = await client.get('/swap/rate', {
            headers: {'x-telegram-data': telegram.getInitData()},
            params: {
                coinA,
                coinB,
            }
        })

        cache.put(cacheKey, r.data)
        return r.data
    }

    async requestToSharePhoneNumber() {
        const client = await this.getClient()
        await client.post('/kyc/sharePhoneNumber', {})
        cache.del(KEYS.USER_INFO)
    }

    async getIpInfo() {
        const client = await this.getClient()
        const r = await client.get(`${config.api.baseUrl}/public/v1/utils/ip`, {
            timeout: 3000,
        })

        return r.data
    }

    async getTelegramUtmParameters(utmCode) {
        const client = await this.getClient();
        const r = await client.get(`${config.api.baseUrl}/v1/utm/${utmCode}`);
        return r.data.result;
    }
}

const exchange = new Exchange()
export default exchange
