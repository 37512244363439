const FR_LOCAL = {
    login: 'Connexion / Inscription',
    mainPageTitle: 'Votre portail vers le monde de la Crypto',
    mainPageItems1: 'Portefeuille exclusif',
    mainPageItems2: 'Échange',
    mainPageItems3: 'Convivial',
    mainPageItems4: 'Haute sécurité',

    letsStart: 'Que l\'aventure commence !',
    letsStartSubtitle: 'Votre portefeuille numérique avec le plus haut niveau de sécurité',
    insertYourPhoneNumber: 'Entrez votre numéro de téléphone portable',
    start: 'Démarrer',

    adChequeDescription: 'En utilisant un chèque publicitaire, vous pouvez demander aux utilisateurs de visiter vos canaux en échange du paiement d\'un montant de prix. De plus, les utilisateurs peuvent recevoir un prix pour avoir partagé votre chèque.',
    totalPrizeAmount: 'Montant Total du Prix',
    activationPrize: 'Prix d\'Activation',
    referralPrize: 'Prix de Référence (Niveau Un)',
    referralPrizeLayer2: 'Prix de Référence (Niveau Deux)',
    antiBot: 'Anti-bot',
    antiBotDescription: 'SwapWallet attrape les bots en utilisant CAPTCHA, et il identifie également et bloque les faux comptes en utilisant des algorithmes d\'apprentissage automatique et une analyse du comportement des utilisateurs.',
    dollarValue: 'Valeur',
    supportCode: 'Code de Support',
    invoiceCode: 'Identifiant de Facture',
    swapwalletFee: 'Frais de SwapWallet',
    invoiceTitle: 'Produit',
    GIVEAWAY: 'Don',
    AD_CHEQUE: 'Chèque Publicitaire',
    AD_CHEQUE_TOP_UP: 'Recharge pour Chèque Publicitaire',
    successfulPayment: 'Paiement Réussi',
    reCharge: 'Recharge',
    delete: 'Supprimer',
    new: 'Nouveau',

    applicationSettlement: 'Règlement de la passerelle de paiement',
    paymentTitleWebsite: 'Passerelle de paiement de cryptomonnaie SwapWallet',
    logoDescription: 'SwapWallet ; Innovation dans l\'échange de cryptomonnaie',
    remainingTime: 'Temps restant :',
    paymentTitle: 'Marchand',
    paymentInfo: 'Informations sur le marchand',
    paymentWebsite: 'Site web du marchand',
    howMuch: 'Montant',
    verificationStatus: 'Vérification',
    verified: 'Certifié par SwapWallet',
    notVerified: 'Non vérifié',
    returningToSource: 'Retour au site web du marchand...',
    paymentExpired: 'Paiement expiré',
    returnToPayment: 'Retour',
    moreInfo: 'Plus d\'informations',

    addNewPhone: 'Ajouter un nouveau numéro de téléphone',
    itTakesMoreTimeThanUsual: 'Le processus de vérification prend plus de temps que prévu. Dès que la vérification est terminée, nous vous en informerons.',
    confirmOwnerOfPhoneTitle: 'Confirmer la propriété du numéro de téléphone',
    confirmOwnerOfPhoneDescription: 'Le numéro de téléphone portable que vous avez sur Telegram vous appartient-il?',
    useOTPInstead: 'Pour ajouter votre numéro de téléphone portable, utilisez la méthode de vérification par SMS',
    yes: 'Oui',
    no: 'Non',

    info: "Information",
    itsLegalTelegramPremium: "- Telegram Premium sera activé légalement, instantanément et sans avoir besoin de vous connecter à votre compte.",
    youShouldHaveUsername: '- Au moment de l\'activation, votre compte doit avoir un nom d\'utilisateur.',
    youShouldNotToChangeUsername: '- Veuillez vous abstenir de changer votre nom d\'utilisateur jusqu\'à la fin du processus.',
    telegramPremiumWasSuccessful: 'L\'opération a réussi, veuillez ne pas changer votre nom d\'utilisateur jusqu\'à ce que le premium soit confirmé par Telegram',
    upgradeToPremium: 'Passer à Premium',
    'TG-PREMIUM-12M': '1 an',
    'TG-PREMIUM-6M': '6 mois',
    'TG-PREMIUM-3M': '3 mois',
    backToHomeScreen: 'Retour à l\'écran d\'accueil',

    descriptionTitle: 'Découvrez les meilleures transactions d\'achat et de vente de devises numériques avec nous',
    description1Title: 'Portefeuille exclusif',
    description1Text: 'Achetez et vendez des cryptomonnaies populaires, suivez votre solde en un seul endroit, avec un portefeuille dédié et une adresse unique pour les dépôts directs de cryptomonnaie',
    description2Title: 'Capacité à se connecter aux mineurs',
    description2Text: 'Connectez facilement votre adresse de portefeuille à vos mineurs et retirez vos gains',
    description3Title: 'Haute sécurité',
    description3Text: 'Toutes les informations seront stockées de manière cryptée dans des centres de données AWS réputés',
    description4Title: 'Frais compétitifs',
    description4Text: 'Nos frais sont très bas (0,1 %) et diminuent avec l\'augmentation des transactions',
    description5Title: 'Paiements avec des cartes rapides',
    description5Text: 'Déposez et retirez en monnaie locale en utilisant toutes les cartes membres Shetab',
    description6Title: 'Support 24/7',
    description6Text: 'L\'un des critères principaux pour choisir une plateforme d\'échange de devises numériques est son support. L\'équipe de support de Zulutex est disponible 24/7 en ligne pour vous aider, vous assurant ainsi de pouvoir utiliser les services d\'échange sans aucun problème',

    contactUsInfo: '021228767753 | support@zulutex.com',
    contactUsDetails: 'Le support en ligne est disponible 24/7. Vous pouvez également nous contacter par téléphone du samedi au mercredi, de 10h à 16h',
    copyright: 'Tous les droits de ce site appartiennent à Zulutex (v1.0.0)',
    totalBalance: 'Solde du compte',

    links: 'Zulutex',
    about: 'À propos de nous',
    tosTitle: 'Conditions d\'utilisation et politique de confidentialité',
    levels: 'Niveaux d\'utilisateur',

    loginToZulutex: "Se connecter à Zulutex",
    toStartInterPhoneNumber: "Veuillez entrer votre numéro de téléphone portable pour commencer",
    insertOtpCode: "Saisissez le code SMS à usage unique reçu",
    phoneNumberShouldBeForYour: "Le numéro de téléphone portable doit vous appartenir",
    beSureInDomainBefore: "Assurez-vous d'être dans le domaine ",
    beSureInDomainAfter: "",

    loginTitle: "Le seul portefeuille dont vous avez besoin",
    loginSubTitle: "Entrez dans le monde des cryptomonnaies en quelques clics seulement !",
    sendOtp: "Envoyer le code de vérification",

    send: 'Envoyer',
    receive: 'Recevoir',
    swap: 'Échanger',
    logout: 'Déconnexion',

    sharePhoneNumberRequest: 'Pour utiliser tous les services de SwapWallet, veuillez partager votre numéro de téléphone portable.',
    upgradeKycLevelRequest: 'Pour effectuer des dépôts et des retraits en Rial, il est nécessaire de compléter votre processus de vérification d\'identité.',

    security: 'Sécurité',
    settings: 'Paramètres',
    creditCards: 'Cartes de crédit',

    rialOperations: 'Opérations en rial',
    depositRial: 'Déposer des rials',
    withdrawRial: 'Retirer des rials',
    kyc: 'KYC',

    topup: 'Recharger',
    topupDetails: 'Vous n\'avez pas assez de solde pour effectuer cette transaction, veuillez utiliser l\'une des options suivantes pour augmenter le solde de votre portefeuille.',

    verifyYourAccount: 'Vérifiez votre compte',
    verifyYourAccountDescription: 'Pour augmenter votre solde avec une carte de crédit, votre compte doit être vérifié.',
    verifyAccountButton: 'Vérifier',

    buyWithCreditCard: 'Acheter avec une carte de crédit',
    buyWithWallet: 'Dépôt depuis un autre portefeuille',


    copy: 'Copier',
    share: 'Partager',
    sendFromAnotherWallet: 'Envoyer depuis un autre portefeuille',

    comingSoon: 'Prochainement',

    tonCoin: 'TonCoin',
    tether: 'Tether',
    tron: 'Tron',
    ethereum: 'Ethereum',
    binanceCoin: 'BinanceCoin',
    bitCoin: 'BitCoin',
    sonix: 'Sonix',

    currentLevel: 'Niveau actuel',
    'LEVEL_0': 'Basique',
    'LEVEL_1_GLOBAL': 'Niveau 1',
    'LEVEL_1_IR': 'Niveau 1',
    'LEVEL_2_IR': 'Niveau 2',


    AYANDEH: 'Ayandeh',
    EGHTESAD_NOVIN: 'Eghtesad Novid',
    ANSAR: 'Ansar',
    IRAN_ZAMIN: 'Iran Zamin',
    PARSIAN: 'Parsian',
    PASARGAD: 'Pasargad',
    POST_BANK: 'Post Bank',
    TEJARAT: 'Tejarat',
    TOSEE_TAAVON: 'Tosee Taavon',
    TOSEE_SADERAT: 'Tosee Saderat',
    HEKMAT_IRANIAN: 'Hekmat Iranian',
    KHAVARMIANEH: 'Khavarmianeh',
    DAY: 'Day',
    RESALAT: 'Resalat',
    REFAH_KARGARAN: 'Refah Kargaran',
    SAMAN: 'Saman',
    BLUBANK: 'ḃluBank',
    SEPAH: 'Sepah',
    SARMAYEH: 'Sarmayeh',
    SINA: 'Sina',
    SHAHR: 'Shahr',
    SADERAT: 'Saderat',
    SANAT_MADAN: 'Sanat Madan',
    MEHR_IRAN: 'Mehr Iran',
    GHAVAMIN: 'Ghavamin',
    KARAAFARIN: 'Karafarin',
    KESHAVARZI: 'Keshavarzi',
    GARDESHGARI: 'Gardeshgari',
    MARKAZI: 'Markazi',
    MASKAN: 'Maskan',
    MELLAT: 'Mellat',
    MELLI: 'Melli',
    KOSAR: 'Kosar',
    MELALL: 'Melall',
    TOSEE: 'Tosee',

    SWAP_WALLET: 'Zulutex',
    TRUST_WALLET: 'Trust Wallet',
    METAMASK: 'Metamask',
    TON_KEEPER: 'Ton Keeper',
    TONHUB: 'Ton Hub',

    SIGNUP: 'Inscription',
    PHONE_NUMBER: 'Vérifier le numéro de téléphone',
    PERSONAL_INFORMATION: 'Vérifier les informations personnelles',
    NATIONAL_ID_IMAGE: 'Image de la carte d\'identité nationale',
    SELFIE_VIDEO: 'Vidéo selfie',

    setPhoneNumber: 'Partager le numéro de téléphone',
    setPersonalInfo: 'Configurer les informations personnelles',
    setCreditCard: 'Configurer la carte de crédit',
    setLocation: 'Configurer l\'emplacement',
    setHomePhoneNumber: 'Configurer le numéro de téléphone fixe',
    setVideo: 'Configurer la vidéo selfie',
    signupInWebsiteOrTelegram: 'Inscription sur le site web ou sur Telegram',
    sorryPersonalInformationNotFound: 'Désolé, mais vos informations n\'ont pas été trouvées',
    pleaseFillFormWithYourPersonalInfo: 'Veuillez remplir le formulaire avec vos informations personnelles',
    pleaseInsertYourNationalCode: 'Veuillez insérer votre numéro d\'identité nationale',
    pleaseInsertYourBirthDate: 'Veuillez insérer votre date de naissance',
    iWillAcceptAllRules: 'J\'ai lu et j\'accepte toutes les conditions d\'utilisation des services de Zulutex.',
    confirm: 'Confirmer',

    pleaseSelectCoin: 'Veuillez sélectionner une cryptomonnaie',
    pleaseOnlySendToThisNetwork: 'Envoyez uniquement __COIN__ (__NETWORK__) à cette adresse. L\'envoi d\'autres cryptomonnaies peut entraîner une perte permanente',
    withKYCYouHaveLowerLimits: 'Avec le KYC, vous avez des limites inférieures de dépôt et de retrait',

    startKYC: 'Commencer le KYC',
    continueKYC: 'Continuer le processus KYC',
    neededDocuments: 'Numéro de téléphone, carte d\'identité nationale, date de naissance',
    neededOneMinutes: '1 minute',
    depositRialCashInCode: '+ Illimité avec l\'utilisation du code d\'identification de dépôt',
    withdrawRialLevelOne: 'retrait en rials iraniens : 100 millions de tomans par jour',
    depositRialLevelOne: 'Dépôt fiat',
    depositCryptoLevelOne: 'Dépôt crypto : illimité',
    withdrawCryptoLevelOne: 'Retrait crypto : 20 $ par jour',

    cashInCodeNewOne: '- En utilisant des dépôts identifiés, vous pouvez augmenter votre solde sans aucune limitation via des dépôts PAIA.',
    cashInCodeNewTwo: '- Les dépôts identifiés sont automatiquement confirmés, et il n\'est pas nécessaire d\'informer SwapWallet de votre dépôt.',
    cashInCodeNewThree: '- Après le dépôt, votre solde sera automatiquement augmenté dans un délai maximum d\'une heure après le premier cycle PAIA.',

    cashInCodeWarningOne: '- Ne pas utiliser la méthode du pont en aucune circonstance.',
    cashInCodeWarningTwo: '- Le compte source du dépôt doit vous appartenir.',
    cashInCodeWarningThree: '- Saisir l\'identifiant du dépôt est obligatoire.',

    cashInCodeWarningTitle: 'Points Importants Avant le Dépôt Identifié',

    liveness: 'Vérification de la vivacité',
    canBeTakeTime: '(Le chargement des données peut prendre un certain temps, veuillez patienter)',
    ok: 'OK',
    livenessLearning: 'En regardant la caméra, tournez doucement la tête autour pour que tous les points deviennent verts',

    selfieVideo: 'Vidéo selfie',
    livenessDescription: 'Tenez la caméra devant vous et tournez lentement la tête autour jusqu\'à ce que tout le cercle devienne vert.',
    selfieVideoDescription: 'En regardant la caméra, appuyez sur le bouton d\'enregistrement et lisez le texte ci-dessous avec une voix claire',

    nationalIdScan: 'Numérisation des documents',
    frontScanDescription: 'Veuillez tenir votre carte d\'identité nationale intelligente dans la zone désignée. Utilisez un environnement avec un éclairage approprié et un fond sombre.',
    backScanDescription: 'Tournez maintenant votre carte d\'identité nationale et, comme précédemment, tenez la carte dans la zone désignée. Utilisez un environnement avec un éclairage approprié et un fond sombre.',

    uploadDocuments: 'Téléchargement',
    uploadDocumentsDescription: 'Téléchargement des informations sur le serveur, veuillez patienter et maintenir cette page ouverte jusqu\'à la fin du processus',
    uploadDocumentsDone: 'Après examen des informations par des experts, nous vous communiquerons le résultat par SMS',
    uploadFailed: 'Erreur lors du téléchargement des documents sur le serveur. Cliquez sur le bouton ci-dessous pour réessayer',

    back: 'Retour',

    uploadNationalId: 'Télécharger des documents',
    uploadNationalIdDescription: 'Veuillez sélectionner une image de haute qualité du recto et du verso de votre carte d\'identité nationale intelligente.',
    frontOfNationalId: '- Recto de la carte d\'identité nationale',
    backOfNationalId: '- Verso de la carte d\'identité nationale',
    upload: 'Télécharger',

    neededDocuments2: 'Carte d\'identité nationale et vidéo selfie',
    neededOneMinutes2: '3 minutes',
    depositRialLevelOne2: 'Dépôt illimité en fiat',
    depositCryptoLevelOne2: 'Retrait fiat',
    withdrawCryptoLevelOne2: 'Retrait crypto : 2 000 $ par jour',

    verifyPhoneNumber: 'Vérifier le numéro de téléphone',
    verifyPhoneNumberDesc: 'Vérifiez votre numéro de téléphone en utilisant l\'une des méthodes suivantes.',
    verifyPhoneNumberDesc2: '(Le numéro de téléphone doit être le vôtre)',
    verifyPhoneWithShareNumber: 'Vérifier avec le partage du numéro de téléphone',
    verifyPhoneWithSMS: 'Vérifier avec SMS',

    kycUserInformation: 'Informations utilisateur',
    kycUserInformationDesc: 'Veuillez remplir le formulaire avec vos informations personnelles',
    kycUserInformationDesc2: '🔒 Vos informations d\'identité sont cryptées de manière sécurisée et conservées chez nous, et seront uniquement utilisées pour prévenir le blanchiment d\'argent et les attaques de phishing. De plus, en cas de divergence avec le système d\'enregistrement civil, votre processus de vérification d\'identité rencontrera des problèmes.',

    memoBasedAlertText: "Pour déposer cette cryptomonnaie, vous devez entrer le commentaire correct en plus de l'adresse. Sinon, votre actif sera perdu.",
    memoBasedIConfirmed: "Je comprends",
    memoBasedDepositWarning: "Assurez-vous d'entrer le commentaire pour cette cryptomonnaie en plus de l'adresse. Sinon, votre actif sera perdu.",

    assets: 'Actifs',

    tosHeader: 'Conditions d\'utilisation',
    tosHeaderDesc: 'Veuillez lire attentivement les conditions d\'utilisation de Zulutex avant de confirmer',

    tos: 'Les utilisateurs du site acceptent que toutes leurs activités sur Zulutex doivent respecter le cadre établi, et ils ne participeront à aucune activité en dehors de ce cadre.\n' +
        '\n' +
        'L\'authentification est nécessaire pour utiliser les services de Zulutex et les utilisateurs doivent télécharger leurs informations correctes. Il est évident que en cas de violation de l\'authentification, la responsabilité incombe au contrevenant et Zulutex a le droit de cesser de fournir des services à l\'utilisateur en question et de signaler les violations aux autorités compétentes. Zulutex est tenu de protéger les informations personnelles de ses utilisateurs.\n' +
        '\n' +
        'Les utilisateurs de Zulutex s\'engagent à utiliser les services du site Zulutex uniquement pour eux-mêmes et la responsabilité de l\'utilisation des services de Zulutex pour toute personne n\'ayant pas suivi le processus d\'authentification incombera à l\'utilisateur. Le numéro de compte annoncé sur le site ainsi que l\'adresse des portefeuilles pour le retrait de crypto-monnaie doivent appartenir à l\'utilisateur et il n\'est pas autorisé aux utilisateurs de donner l\'adresse du portefeuille appartenant à d\'autres personnes.\n' +
        '\n' +
        'Les utilisateurs de Zulutex acceptent de coopérer avec l\'équipe Zulutex afin de maintenir la sécurité de leurs actifs de compte utilisateur s\'ils sont diagnostiqués par les experts de soutien de Zulutex. De plus, selon l\'ordre des institutions judiciaires, le retrait de crypto-monnaie peut être limité de 24 à 72 heures ouvrables après le moment du dépôt.\n' +
        '\n' +
        'Les utilisateurs de Zulutex reconnaissent qu\'ils sont conscients des risques liés à l\'investissement dans les devises numériques et investissent et échangent en toute connaissance de cause.\n' +
        '\n' +
        'Zulutex, en tant que marché en ligne pour l\'échange de devises numériques, n\'a aucune responsabilité quant à la manière dont les utilisateurs commercent et aux profits et pertes qui en résultent.\n' +
        '\n' +
        'Toute négligence des utilisateurs dans la conservation des informations de sécurité, y compris le mot de passe de l\'utilisateur, relève de la responsabilité de l\'utilisateur et Zulutex ne portera aucune responsabilité. Il est fortement recommandé d\'utiliser un mot de passe complexe et sécurisé ainsi qu\'un code d\'authentification à deux facteurs.\n' +
        '\n' +
        'Zulutex garantit que les actifs des utilisateurs sont conservés en toute confiance avec eux de la meilleure manière possible et selon les normes de sécurité les plus élevées possibles. En cas de problème de sécurité, Zulutex sera tenu de compenser les dommages.\n' +
        '\n' +
        'Si vous souhaitez retirer des devises numériques, l\'utilisateur est responsable de fournir la bonne adresse du portefeuille. Zulutex ne sera pas tenu responsable de problèmes tels que des erreurs dans la saisie de l\'adresse correcte, des erreurs d\'adresse, des problèmes avec le portefeuille de destination et le blocage des actifs des utilisateurs dans le portefeuille de destination.\n' +
        '\n' +
        'Zulutex n\'a aucune responsabilité pour le dépôt de jetons ou de pièces sur la mauvaise plateforme ou pour des pièces qui ne sont pas prises en charge dans Zulutex, et en raison du mécanisme de stockage à froid, il n\'est pas possible d\'extraire ces jetons à l\'aide d\'un portefeuille tiers. Par conséquent, l\'utilisateur est responsable de tout dépôt incorrect et l\'utilisateur n\'a pas le droit de se plaindre.\n' +
        '\n' +
        'L\'utilisateur accepte que, sauf dans les cas d\'engagement de Zulutex, il n\'aura pas le droit de formuler des réclamations, des demandes et des plaintes contre le site Zulutex, les gestionnaires, les employés et les personnes liées à ce site.\n' +
        '\n' +
        'En cas de décès de l\'utilisateur, Zulutex aura le droit de déposer les fonds de l\'utilisateur auprès de la société sur le compte déclaré par l\'utilisateur au moment de l\'inscription. Il a été confié à Zulutex de le convertir en équivalent fiduciaire du prix du jour et de le déposer sur le compte mentionné ci-dessus, et ce droit et ce privilège ont été accordés à Zulutex par l\'utilisateur. Il convient de noter que le critère de temps pour calculer le prix de vente des actifs de crypto-monnaie de l\'utilisateur est basé sur la présentation de documents positifs par le bénéficiaire et la confirmation de son authenticité par l\'unité légale de Zulutex. Avec la connaissance et la compréhension des dispositions de ce paragraphe, l\'utilisateur a ouvert un compte utilisateur dans Zulutex, et l\'utilisateur, les tiers, les héritiers ou les représentants légaux n\'auront pas le droit de protester ou de revendiquer à cet égard à l\'avenir.\n' +
        '\n',

    kycWasSuccessful: 'Votre KYC a réussi',
    kycWasSuccessfulDesc: 'Félicitations ! Votre niveau de compte a été mis à niveau vers "Niveau un", à ce niveau vous pouvez déposer jusqu\'à 15 millions de Tomans par jour.',

    rialDeposit: 'Dépôt en Rial',

    kycWasNotSuccessful: 'KYC n\'a pas réussi',
    kycWasNotSuccessfulDesc: 'Veuillez vérifier les informations que vous avez saisies à nouveau. Notez que le numéro de mobile doit appartenir au code national saisi.',
    retry: 'Réessayer',

    redirectingToPaymentGateway: 'Redirection vers la passerelle de paiement',
    redirectToPaymentGateway: 'Redirection vers la passerelle de paiement',
    connectionFromOutsideOfIran: 'Connexion depuis l\'extérieur de l\'Iran',
    sorryButYouShouldTurnOffYourVPN: 'Désolé, la connexion depuis l\'extérieur de l\'Iran n\'est pas autorisée par les passerelles de paiement. Veuillez désactiver votre VPN et cliquer sur le bouton ci-dessous',

    paymentTransaction: 'Achat dans le magasin',
    giveawayPrize: 'Réception d\'un prix (Airdrop)',
    adCheque: 'Activer le chèque publicitaire',
    adChequeReferral: 'Référence du chèque publicitaire',
    creditReason: 'Type de prix',
    giveawayParticipate: 'Participation à la loterie',
    value: 'Valeur (en dollars)',
    product: 'Produit',
    paidWith: 'Montant payé',
    invoiceId: 'Identifiant de la facture',
    shop: 'Magasin',

    giveawayEndTime: 'Date de fin',
    giveawayTitle: 'Texte de la loterie',
    banner: 'Bannière',
    titlePlaceholder: '🎁 Loterie 1000 Tether... (markdown, longueur maximale : 500)',
    marketPlace: 'Place de marché',
    giveaway: 'Loterie',
    giveawayDescription: 'Un outil adapté pour promouvoir votre chaîne, attirer de nouveaux utilisateurs ou récompenser vos utilisateurs actuels.',
    prizes: 'Prix',
    totalPrizes: 'Total des prix',
    giveawayLimitations: 'Limitations',
    tokenType: 'Type de prix',
    addPrize: 'Ajouter un prix',
    addLimit: 'Ajouter une limitation',
    telegramPremium: 'Telegram Premium',
    telegramPremiumDescription: "Améliorez instantanément et légalement votre Telegram Premium sans intermédiaire, en utilisant les pièces TON.",
    productOutOfOrder: 'Ce produit n\'est pas encore actif pour vous, veuillez contacter le support.',

    returnToWallet: 'Retour au portefeuille',
    returnToWebsite: 'Retour au portefeuille',
    yourPaymentWasSuccessful: 'Votre paiement a réussi',
    yourPaymentWasNotSuccessful: 'Votre paiement n\'a pas réussi',
    yourWalletHasBeenCharged: 'Votre portefeuille a été crédité, vous pouvez retourner à votre portefeuille en cliquant sur le bouton ci-dessous',
    nowYouCanTurnVPNOnAgain: 'Maintenant vous pouvez à nouveau activer votre VPN',
    paymentId: 'ID de paiement',
    orderId: 'ID de commande',
    contactSupport: 'Contacter le support',
    ifAmountHasBeenDeductedItWillRefund: 'Si le montant a été déduit de votre compte, il sera remboursé sur votre compte dans les 72 heures',
    notePaymentShouldDoneUsingSelectedCreditCard: 'Note : Le paiement doit être effectué avec la carte de crédit sélectionnée',

    gatewayOutOfOrder: 'Passerelle de paiement hors service, veuillez utiliser le virement bancaire',
    connectionError: 'Erreur de connexion',
    connectionToPaymentGatewayFailed: 'Échec de la connexion à la passerelle de paiement',
    pleaseSelectACardOrAddANewCard: 'Veuillez sélectionner une carte de crédit ou en ajouter une nouvelle',
    pleaseInsertTheAmount: 'Veuillez insérer le montant',
    pay: 'Payer',
    holdToPay: 'Maintenez pour payer',
    holdToSwapAndPay: 'Maintenez pour échanger et payer',
    holdToTopUp: 'Recharger',
    youDoNotHaveEnoughMoneyToPay: 'Vous n\'avez pas assez d\'argent pour payer',
    minimumAmountStart: 'Environ',
    minimumAmountEnd: 'de votre solde sera converti en devise souhaitée au meilleur taux du marché pour payer ce reçu',
    cardNumberIsNotValid: 'Le numéro de carte de crédit n\'est pas valide',
    cardSuccessfullyAdded: 'La carte de crédit a été ajoutée avec succès',
    cardFailedToAdd: 'Échec de l\'ajout de la carte de crédit',
    addANewCard: 'Ajouter une nouvelle carte de crédit',
    warning: 'Avertissement',
    youOnlyCanAddCreditCardThatIsForYou: 'En raison de l\'ordre de la Cyber Police, pour éviter le phishing et le blanchiment d\'argent, seule la possibilité d\'ajouter une carte selon votre numéro de mobile est possible. Le paiement n\'est possible qu\'avec une carte prédéfinie.',
    add: 'Ajouter',
    cancel: 'Annuler',
    thereIsNoCreditCard: 'Il n\'y a pas de carte de crédit',
    minimumDepositAmountIs150: 'Le montant minimum de dépôt est de 5 000 Toman',
    maximumDepositAmountIs25M: 'Le montant maximum de dépôt est de 25 millions de Toman',

    addANewIban: 'Ajouter un nouveau IBAN',
    ibanSuccessfullyAdded: 'Nouveau IBAN ajouté avec succès',
    youOnlyCanAddIbanThatIsForYou: 'Selon l\'ordre de la police FATA, afin de prévenir les infractions de phishing et de blanchiment d\'argent, vous ne pouvez ajouter qu\'un IBAN correspondant à votre numéro de téléphone portable. Le règlement n\'est possible que sur votre propre compte.',
    thereIsNoIban: 'Pas d\'IBAN disponible',
    minimumWithdrawAmountIs5: 'Le montant minimum de retrait est de 5 000 Tomans',
    maximumWithdrawAmountIs100M: 'Le montant maximum de retrait quotidien est de 100 millions de Tomans',
    pleaseSelectAIbanOrAddANewIban: 'Sélectionnez un IBAN existant ou ajoutez-en un nouveau',
    settle: 'Retirer',
    transactionFeeIsForBroker: "(Zulutex ne bénéficie pas de cette taxe)",
    receiverName: 'Nom du destinataire',
    ifPossibleWeUseInstantWithdraw: '⚡ Si possible, le règlement sera effectué instantanément et en moins de 15 minutes.',
    receiveTime: 'Heure de réception',
    receiveTimeResult: 'Dans le premier cycle ACH de la période suivante',
    sentToBank: 'Dépôt sur le compte bancaire',
    ibanNumber: 'IBAN du destinataire',
    gateway: 'Passerelle de paiement',
    cashInCode: 'Code de dépôt',
    cashInCodeDescription: 'En envoyant le montant au numéro de compte spécifié, rechargez votre compte en Rials sans aucune restriction. Assurez-vous d\'entrer le code créé dans la section ID de paiement sur la banque mobile, la banque en ligne ou le bordereau de paiement bancaire. Après le dépôt, le montant sera automatiquement crédité sur votre compte.',
    cashInCodeNumber: 'Code de dépôt :',
    iban: 'IBAN :',
    ibanNumberSender: "IBAN de l'expéditeur",
    cashInCodeName: 'Dépôt par virement bancaire',
    ibanNumberTooltip: 'Numéro IBAN',
    ibanNumberTooltipCopied: 'Numéro IBAN copié',
    accountNumber: 'Numéro de compte :',
    accountNumberTooltip: 'Numéro de compte',
    accountNumberTooltipCopied: 'Numéro de compte copié',
    accountHolderName: 'Nom du titulaire du compte :',
    noticeUseCashInCode: 'Doit être saisi dans la section Code de dépôt',
    cashInCodeDescriptionTwo: 'Le dépôt est possible avec toutes les méthodes de paiement, à l\'exception du pont. Notez que le compte source doit vous appartenir.',

    market: 'Marché',
    payAmount: 'Montant à payer',
    receiveAmount: 'Montant à recevoir',
    tradeRate: 'Taux de change',
    bankFee: 'Frais bancaires',
    cardNumber: 'Numéro de carte de crédit',
    refId: 'Ref-Id',
    hash: 'Hachage de la transaction',
    uniqueId: 'Identifiant unique de la transaction',

    sufficientBalance: 'Solde suffisant',
    transactionWasSuccessful: 'La transaction a réussi',
    transactionWasNotSuccessful: 'La transaction n\'a pas réussi',
    quickSwap: 'Échange rapide',
    fee: 'Frais',
    youPay: 'Vous payez',
    youReceive: 'Vous recevez',
    balance: 'Solde',
    increaseBalanceByRequiredAmount: 'Augmenter le solde du montant requis',

    transferToAddress: 'Transférer vers',
    receiveFromAddress: 'Recevoir de',
    swapTo: 'Échanger __SOURCE__ contre __DEST__',
    buyFromBankGateway: 'Dépôt avec passerelle bancaire',
    thisCoinIsNotActiveYet: 'Cette crypto-monnaie n\'est pas encore active',
    thereIsNoTransaction: 'Il n\'y a aucune transaction',

    selectToken: 'Sélectionner la crypto-monnaie',
    selectNetwork: 'Sélectionner le réseau',
    selectNetworkPlaceHolder: 'Veuillez sélectionner le réseau de retrait',

    USER_DO_NOT_CONSIDER_NETWORK_FEE: "Solde insuffisant pour payer les frais. ",
    USER_DO_NOT_CONSIDER_NETWORK_FEE_APPLY: "Corrigez cela",

    LIMIT_DAILY_WITHDRAW_EXCEEDED: 'Limite de retrait quotidien dépassée',
    LIMIT_MONTHLY_WITHDRAW_EXCEEDED: 'Limite de retrait mensuel dépassée',
    INSUFFICIENT_USER_BALANCE: 'Solde utilisateur insuffisant',
    USER_BALANCE_LOCKED: 'Solde utilisateur verrouillé',


    TRANSACTION_CREATED: 'Générer une transaction',
    TRANSACTION_CREATED_DONE: 'Transaction créée',
    ACCEPTED_BY_ADMIN: 'En attente d\'acceptation',
    ACCEPTED_BY_ADMIN_DONE: 'Transaction acceptée',
    PUBLISHED_TO_NETWORK: 'Publier la transaction',
    PUBLISHED_TO_NETWORK_DONE: 'Transaction publiée',
    CONFIRMED_BY_NETWORK: 'En attente de confirmation du réseau',
    CONFIRMED_BY_NETWORK_DONE: 'Transaction terminée',
    status: 'Statut',

    badInputValues: 'Mauvaises valeurs d\'entrée',
    badWalletAddressFormat: 'Format incorrect de l\'adresse de portefeuille',
    requestedAmountIsMoreThanYourWithdrawalLimit: 'Le montant demandé est supérieur à votre limite de retrait',
    walletAddress: 'Adresse du portefeuille',
    pleaseInsertAmountWithoutFee: 'Veuillez insérer le montant sans frais',
    amount: 'Montant',
    notePleaseSelectNetworkCarefully: 'Veuillez sélectionner le réseau avec soin. Entrer une adresse incorrecte peut entraîner la perte de vos ressources financières',
    continue: 'Continuer',
    withdrawLimit: 'Limite de retrait',
    errorOccurred: 'Erreur survenue',
    transactionFee: 'Frais de transaction',
    receivedAmount: 'Montant reçu',
    balanceAfter: 'Solde après',
    thisTransactionWillBeDoneWithoutFeeAndFast: 'Cette transaction sera effectuée sans frais et rapidement',
    transfer: 'Transfert',
    networkConfirmationMayTakeLonger: 'La confirmation du réseau peut prendre plus de temps, le montant sera envoyé au portefeuille de destination dans quelques minutes',
    ifAmountHasBeenDeductedContactSupport: 'Si le montant a été déduit de votre compte, veuillez contacter le support',
    viewTransaction: 'Voir la transaction',

    interfaceLanguage: 'Langue de l\'interface',
    apiKey: 'Clé API',
    copyApiKey: 'Copier la clé API',

    USDT: 'Tether',
    TRX: 'Tron',
    IRT: 'Toman',
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    TON: 'TonCoin',
    BNB: 'BinanceCoin',
    SNX: 'Sonix',
    ADA: 'Cardano',
    AVAX: 'Avalanche',
    DOGE: 'DogeCoin',
    SHIB: 'Shiba Inu',
    SOL: 'Solana',
    XRP: 'Ripple',
    NOT: 'NotCoin',
    HMSTR: 'HamsterKombat',

    withdrawAndDepositLimitedWarning: 'Le retrait et le dépôt de cette pièce sont temporairement limités',

    toman: 'Toman',
    network: 'Réseau',
    date: 'Date',
    receiverAddress: 'Adresse du destinataire',
    senderAddress: 'Adresse de l\'expéditeur',
    showOnBlockScan: 'Afficher sur Block Scan',
    user: 'Utilisateur',

    hamsterShareMessage: "Bonjour!\n" +
        "SwapWallet est un portefeuille Telegram sécurisé et fiable avec la possibilité de faire des paiements en crypto!\n" +
        "\n" +
        "Dans ce portefeuille crypto, vous pouvez envoyer des cryptos à vos amis sans aucun frais, acheter et vendre facilement, acheter Telegram Premium, et plus encore...\n" +
        "\n" +
        "🐹 Au fait, saviez-vous que Hamster Kombat est sur le point d'être lancé? Vous pouvez vendre vos pièces de jeu ici au prix mondial.\n" +
        "🎁 Rejoignez SwapWallet maintenant en utilisant mon lien pour obtenir une réduction de 10 % sur les frais de transaction de Hamster.",

    hamsterBannerTextFirstLine: '40% des frais de transaction',
    hamsterBannerTextSecondLine: 'est votre part pour nous avoir recommandé à vos amis',
    hamsterBannerLink: 'Cliquez pour inviter ›',

    hmsWarning: 'Dès que la possibilité de retirer des pièces de ce jeu est activée, vous pouvez déposer vos pièces dans SwapWallet en utilisant les informations ci-dessous et être parmi les premiers à vendre vos pièces.',

    swapAndTransfer: 'Échange & Transfert',
    checkAndTransfer: 'Vérifier & Transférer',
    increaseBalance: 'Augmenter le solde',

    swapAndTransferDesc: 'Vous n\'avez pas assez de solde de la crypto-monnaie souhaitée pour effectuer cette transaction. En cliquant sur l\'option ci-dessous, vous pouvez utiliser vos actifs convertibles pour approvisionner la crypto-monnaie souhaitée et enfin effectuer le transfert.',
    youHaveNotEnoughBalanceEn: 'Vous n\'avez pas assez de solde pour effectuer un échange.',

    birthDate: 'Date de naissance',

    checkingUserInformation: 'Vérification des informations de l\'utilisateur',
    checkingUserInformationDesc: 'Vérification des informations de l\'utilisateur avec le système national d\'identification, veuillez patienter.',

    swapTourSourceTokenTitle: 'Type de jeton source',
    swapTourSourceTokenDescription: 'En cliquant sur cette option, vous pouvez changer le type de votre crypto-monnaie de paiement',
    swapTourDestTokenTitle: 'Type de jeton de destination',
    swapTourDestTokenDescription: 'En cliquant sur cette option, vous pouvez changer le type de votre crypto-monnaie reçue',
    swapTourSourceInputTitle: 'Montant du paiement',
    swapTourSourceInputDescription: 'Entrez le montant de la crypto-monnaie source que vous souhaitez convertir ici',
    swapTourDestInputTitle: 'Montant reçu',
    swapTourDestInputDescription: 'Le système trouve le taux de change le plus bas pour vous',
    swapTourTotalAmountTitle: 'Solde total',
    swapTourTotalAmountDescription: 'En cliquant sur cette option, vous pouvez sélectionner le solde total de la crypto-monnaie source à convertir',
    swapTourSwapButtonTitle: 'Échanger',
    swapTourSwapButtonDescription: 'Enfin, si vous êtes d\'accord avec le taux de change, vous pouvez finaliser votre conversion en cliquant sur cette option',
}
export default FR_LOCAL
