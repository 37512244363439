import {Card, Row, Skeleton, Space, theme} from "antd";
import React, {useState} from "react";
import {roundWithPrecisionNumber, separateNumber} from "@/src/utils/persian";
import AmountNumber from "@/src/common/components/amountNumber";
import Color from "color"

const {useToken} = theme;

function CoinRow(props) {
    const {token} = useToken();
    const [hovered, setHovered] = useState(false)

    const amountNumberSeparated = separateNumber(roundWithPrecisionNumber(props.amount))
    const changePrice = roundWithPrecisionNumber(props.changes)
    const changeColor = changePrice >= 0 ? token.colorSuccess : token.colorError
    const priceNumberSeparated = props.price.toFixed(2)
    const amountPriceNumberSeparated = separateNumber(roundWithPrecisionNumber(Number(props.usdtValue)))

    return (
        <>
            <Row style={{width: '100%', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'}}>
                <Space>
                    <div style={{
                        borderRadius: 20,
                        width: 50,
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img alt='crypto-icon' width={43} height={43} src={props.icon}/>
                    </div>
                    <Space direction='vertical'>
                        <p style={{fontWeight: 400, fontSize: 16, marginBottom: -10}}>{props.name}</p>
                        <Space>
                            <span dir='ltr' style={{color: changeColor, fontWeight: 400, fontSize: 10}}>
                                (
                                {changePrice >= 0 ? '+' : ''}
                                <AmountNumber amount={changePrice} unit={'%'} noSpaceSuffix={true} size={9}
                                              twoLevel={false} fontWeight={100} opacity={1}/>
                                )
                            </span>
                            <span dir='ltr' style={{fontWeight: 200, fontSize: 12, marginRight: -5}}>
                                <AmountNumber amount={props.price} unit={'$'} size={9} twoLevel={false} fontWeight={100}
                                              opacity={1}/>
                            </span>
                        </Space>
                    </Space>
                </Space>
                <Space>
                    <Space direction='vertical' align={"end"}>
                        <p style={{marginBottom: -10}}>
                            <AmountNumber amount={props.amount} unit={props.unit} size={17} twoLevel={true}
                                          fontWeight={500}
                                          opacity={1}/>
                        </p>
                        {!!props.amount &&
                            <AmountNumber amount={props.mainCurrency === '$' ? props.usdtValue : props.irtValue}
                                          unit={props.mainCurrency} size={12} twoLevel={true} fontWeight={200}
                                          opacity={0.9}/>}
                    </Space>
                </Space>
            </Row>
        </>
        // </Card>
    )
}

export default CoinRow
