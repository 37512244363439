// import fonts from "@/src/utils/fonts"
import telegram from "@/src/utils/telegram"
import {theme} from 'antd';
import './fonts/iranYekan/fontiran.css'
import "@fontsource/varela-round";
import './fonts/sfr/sfr.css'

const telegramTheme = telegram.getTheme()

function isLightColor(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}

// default theme is dark!
const defaultDarkMode = true
const darkMode = telegramTheme ? !isLightColor(telegramTheme.bg_color) : defaultDarkMode

export const DEFAULT_BORDER_RADIUS = 0.625;
export const TOP_ITEM_BORDER_RADIUS = {
    topRight: DEFAULT_BORDER_RADIUS,
    topLeft: DEFAULT_BORDER_RADIUS,
    bottomRight: 0,
    bottomLeft: 0,
}
export const BOTTOM_ITEM_BORDER_RADIUS = {
    topRight: 0,
    topLeft: 0,
    bottomRight: DEFAULT_BORDER_RADIUS,
    bottomLeft: DEFAULT_BORDER_RADIUS,
}
export const ROUNDED_ITEM_BORDER_RADIUS = {
    topRight: DEFAULT_BORDER_RADIUS,
    topLeft: DEFAULT_BORDER_RADIUS,
    bottomRight: DEFAULT_BORDER_RADIUS,
    bottomLeft: DEFAULT_BORDER_RADIUS,
}

export const getBorderRadiusStyle = (borderRadius) => {
    return `${borderRadius?.topLeft}rem ${borderRadius?.topRight}rem
                            ${borderRadius?.bottomRight}rem ${borderRadius?.bottomLeft}rem`
}

export const DEFAULT_ICON_SIZE = '1.75rem';

let darkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
        fontFamily: 'SFR, IRANYekanX, Arial', // TODO: Fix it
        colorBgBase: '#1C1C1C',
        colorInvertLight: 'rgba(255, 255, 255, 0.1)',
        colorInvert: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    },
}

const lightTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        fontFamily: 'SFR, IRANYekanX, Arial', // TODO: Fix it
        colorBgBase: '#FFFFFF',
        colorInvertLight: 'rgba(0, 0, 0, 0.1)',
        colorInvert: (opacity) => `rgba(28, 28, 28, ${opacity})`,
    },
}

let zuluTheme = darkMode ? darkTheme : lightTheme

export {darkTheme, lightTheme}
export default zuluTheme
