import interfaceConfigs from "@/src/utils/interfaceConfigs"

const PREFIX_PATH = process.env.REACT_APP_ENV === 'development' ? "" : ""

const config = {
    env: process.env.REACT_APP_ENV,
    local: interfaceConfigs.local,
    dir: interfaceConfigs.dir,
    lang: interfaceConfigs.lang,
    enableAnalytics: false,
    storageTelegramVersion: 'v11',
    storageWebVersion: 'v6',
    minimumDeposit: 5000,
    maximumDeposit: 25000000,
    minimumRialWithdraw: 5000,
    maximumRialWithdraw: 100000000,
    mockApi: false,
    bot: {
        url: 'https://t.me/zulutexBot',
    },
    supportBot: {
        url: 'https://t.me/zulutexSupportBot',
    },
    localStorage: {
        userBalance: 'userBalance',
        userInfo: 'userInfo',
        transactions: 'transactions',
        user: 'user',
        memoBasedDepositAlert: 'memoBasedDepositAlert',
        frequentContacts: 'frequentContacts',
        recentContacts: 'recentContacts',
        withdrawOptions: 'withdrawOptions',
    },
    decimals: {
        '$': 2,
        'USDT': 6,
        'TRX': 6,
        'IRT': 0,
        'BNB': 18,
        'BTC': 8,
        'ETH': 18,
        'TON': 9,
        'SNX': 6,
        'NOT': 0,
        'ADA': 18,
        'AVAX': 4,
        'DOGE': 1,
        'SHIB': 0,
        'SOL': 18,
        'XRP': 6,
    },
    humanReadableDecimals: {
        '$': 2,
        'USDT': 2,
        'TRX': 2,
        'IRT': 0,
        'BNB': 4,
        'BTC': 5,
        'ETH': 5,
        'TON': 2,
        'SNX': 2,
        'NOT': 0,
        'ADA': 2,
        'AVAX': 2,
        'DOGE': 1,
        'SHIB': 0,
        'SOL': 2,
        'XRP': 1,
    },
    disabledWithdrawCoins: [
        'HMSTR',
    ],
    disabledSwapCoins: [
        'HMSTR',
    ],
    networks: {
        'USDT': ['TRON-TRC20'],
        'TRX': ['TRON'],
        'ETH': ['ETHEREUM'],
        'BNB': ['BSC'],
        'TON': ['TON'],
        'NOT': ['TON'],
        'HMSTR': ['TON'],
    },
    iconMappingCirclePath: {
        'USDT': `${PREFIX_PATH}/images/coins/circle/tether.svg`,
        'USD': `${PREFIX_PATH}/images/coins/circle/usd.svg`,
        'TRX': `${PREFIX_PATH}/images/coins/circle/tron.svg`,
        'IRT': `${PREFIX_PATH}/images/coins/circle/irt.svg`,
        'BTC': `${PREFIX_PATH}/images/coins/circle/bitcoin.svg`,
        'ETH': `${PREFIX_PATH}/images/coins/circle/ethereum.svg`,
        'TON': `${PREFIX_PATH}/images/coins/circle/ton.svg`,
        'BNB': `${PREFIX_PATH}/images/coins/circle/bnb.svg`,
        'SNX': `${PREFIX_PATH}/images/coins/circle/sonix.svg`,
        'ADA': `${PREFIX_PATH}/images/coins/circle/ada.svg`,
        'AVAX': `${PREFIX_PATH}/images/coins/circle/avax.svg`,
        'DOGE': `${PREFIX_PATH}/images/coins/circle/doge.svg`,
        'SHIB': `${PREFIX_PATH}/images/coins/circle/shib.svg`,
        'SOL': `${PREFIX_PATH}/images/coins/circle/sol.svg`,
        'XRP': `${PREFIX_PATH}/images/coins/circle/xrp.svg`,
        'NOT': `${PREFIX_PATH}/images/coins/circle/not.svg`,
        'HMSTR': `${PREFIX_PATH}/images/coins/circle/hmstr.png`,
    },
    enableCoins: {
        IRT: true,
        USDT: true,
        NOT: true,
        TRX: true,
        ETH: true,
        BNB: true,
        TON: true,
        BTC: true,

        HMSTR: true,
        ADA: true,
        AVAX: true,
        DOGE: true,
        SHIB: true,
        SOL: true,
        XRP: true,

        SNX: false,
    },

    bankInformation: [
        {
            code: "502938",
            ibanCode: "066",
            enName: "DAY",
        },
        {
            code: "636214",
            ibanCode: "062",
            enName: 'AYANDEH',
        },
        {
            code: "627412",
            ibanCode: "055",
            enName: 'EGHTESAD_NOVIN',
        },
        {
            code: "627381",
            ibanCode: "063",
            enName: 'ANSAR',
        },
        {
            code: "505785",
            ibanCode: "069",
            enName: 'IRAN_ZAMIN',
        },
        {
            code: "622106",
            ibanCode: "054",
            enName: 'PARSIAN',
        },
        {
            code: "627884",
            ibanCode: "054",
            enName: 'PARSIAN',
        },
        {
            code: "502229",
            ibanCode: "057",
            enName: 'PASARGAD',
        },
        {
            code: "639347",
            ibanCode: "057",
            enName: 'PASARGAD',
        },
        {
            code: "627760",
            ibanCode: "021",
            enName: 'POST_BANK',
        },
        {
            code: "585983",
            ibanCode: "018",
            enName: 'TEJARAT',
        },
        {
            code: "627353",
            ibanCode: "018",
            enName: 'TEJARAT',
        },
        {
            code: "502908",
            ibanCode: "051",
            enName: 'TOSEE_TAAVON',
        },
        {
            code: "207177",
            ibanCode: "020",
            enName: 'TOSEE_SADERAT',
        },
        {
            code: "627648",
            ibanCode: "020",
            enName: 'TOSEE_SADERAT',
        },
        {
            code: "636949",
            ibanCode: "065",
            enName: 'HEKMAT_IRANIAN',
        },
        {
            code: "585949",
            enName: 'KHAVARMIANEH',
        },
        {
            code: "504172",
            ibanCode: "070",
            enName: 'RESALAT',
        },
        {
            code: "589463",
            ibanCode: "013",
            enName: 'REFAH_KARGARAN',
        },
        {
            code: "62198619",
            enName: 'BLUBANK',
        },
        {
            code: "62198610",
            ibanCode: "056",
            enName: 'SAMAN',
        },
        {
            code: "589210",
            ibanCode: "015",
            enName: 'SEPAH',
        },
        {
            code: "639607",
            ibanCode: "058",
            enName: 'SARMAYEH',
        },
        {
            code: "639346",
            ibanCode: "059",
            enName: 'SINA',
        },
        {
            code: "502806",
            ibanCode: "061",
            enName: 'SHAHR',
        },
        {
            code: "504706",
            ibanCode: "061",
            enName: 'SHAHR',
        },
        {
            code: "603769",
            ibanCode: "019",
            enName: 'SADERAT',
        },
        {
            code: "903769",
            ibanCode: "019",
            enName: 'SADERAT',
        },
        {
            code: "627961",
            ibanCode: "011",
            enName: 'SANAT_MADAN',
        },
        {
            code: "639370",
            enName: 'MEHR_IRAN',
        },
        {
            code: "639599",
            ibanCode: "052",
            enName: 'GHAVAMIN',
        },
        {
            code: "627488",
            ibanCode: "053",
            enName: 'KARAAFARIN',
        },
        {
            code: "603770",
            ibanCode: "016",
            enName: 'KESHAVARZI',
        },
        {
            code: "639217",
            ibanCode: "016",
            enName: 'KESHAVARZI',
        },
        {
            code: "505416",
            ibanCode: "064",
            enName: 'GARDESHGARI',
        },
        {
            code: "505426",
            ibanCode: "064",
            enName: 'GARDESHGARI',
        },
        {
            code: "636797",
            ibanCode: '010',
            enName: 'MARKAZI',
        },
        {
            code: "628023",
            ibanCode: "014",
            enName: 'MASKAN',
        },
        {
            code: "610433",
            ibanCode: '012',
            enName: 'MELLAT',
        },
        {
            code: "991975",
            ibanCode: '012',
            enName: 'MELLAT',
        },
        {
            code: "170019",
            ibanCode: "017",
            enName: 'MELLI',
        },
        {
            code: "603799",
            ibanCode: "017",
            enName: 'MELLI',
        },
        {
            code: "606373",
            enName: 'MEHR_IRAN',
        },
        {
            code: "505801",
            enName: 'KOSAR',
        },
        {
            code: "606256",
            enName: 'MELALL',
        },
        {
            code: "628157",
            enName: 'TOSEE',
        },
    ],
}

export default config;
