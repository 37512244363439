// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-theme=compact] .style__ant-drawer-body___D5Geo p{margin-bottom:0}.style__ant-drawer-content-wrapper___OhAVO:has(.style__my-drawer___MzaJE){height:90% !important}`, "",{"version":3,"sources":["webpack://./src/app/mainPage/components/coinSelector/style.css"],"names":[],"mappings":"AAAA,uDACI,eAAA,CAGJ,0EACI,qBAAA","sourcesContent":["[data-theme=\"compact\"] .ant-drawer-body p {\n    margin-bottom: 0;\n}\n\n.ant-drawer-content-wrapper:has(.my-drawer) {\n    height: 90% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ant-drawer-body": `style__ant-drawer-body___D5Geo`,
	"ant-drawer-content-wrapper": `style__ant-drawer-content-wrapper___OhAVO`,
	"my-drawer": `style__my-drawer___MzaJE`
};
export default ___CSS_LOADER_EXPORT___;
