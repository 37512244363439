import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import loadable from '@loadable/component'
import {DisplayWrapper} from "@/src/common/components/DisplayWrapper";

import MainPage from "@/src/app/mainPage/page"
import {useAnalytics} from "use-analytics";

const KYCProcessLate = loadable(() => import("@/src/app/kyc/common/late/page"))
const TelegramRouter = loadable(() => import("@/src/app/telegramRouter"))
const Payment = loadable(() => import("@/src/app/payment"))
const PaymentResult = loadable(() => import("@/src/app/paymentResult"))
const ChequeCaptcha = loadable(() => import("@/src/app/chequeCaptcha"))
const NewPhone = loadable(() => import("@/src/app/newPhone"))
const TelegramPremium = loadable(() => import("@/src/app/shop/telegramPremium"))
const Invoice = loadable(() => import("@/src/app/invoice"))
const Market = loadable(() => import("@/src/app/shop"))
const Giveaway = loadable(() => import("@/src/app/shop/giveaway"))
const NewAdCheque = loadable(() => import("@/src/app/shop/adCheque/new"))
const AdChequeList = loadable(() => import("@/src/app/shop/adCheque/list"))
const AdChequeInfo = loadable(() => import("@/src/app/shop/adCheque/info"))
const ResultPage = loadable(() => import("@/src/app/result/page"))
const Deposit = loadable(() => import("@/src/app/deposit/page"))
const Login = loadable(() => import(/* webpackPrefetch: true */ "@/src/app/login/Login"))

const Withdraw = loadable(() => import("@/src/app/withdraw/page"))
const WithdrawTypes = loadable(() => import("@/src/app/withdraw/types"))
const WithdrawConfirm = loadable(() => import("@/src/app/withdraw/confirm/page"))
const WithdrawInfo = loadable(() => import("@/src/app/withdraw/info/page"))
const WithdrawSendToWallet = loadable(() => import("@/src/app/withdraw/sendToWallet"))
const InsertWithdrawAmount = loadable(() => import("@/src/app/withdraw/insertAmount"))
const SelectContact = loadable(() => import("@/src/app/withdraw/selectContact"))

const Transactions = loadable(() => import("@/src/app/transactions/page"))
const TransactionInfo = loadable(() => import("@/src/app/transactions/fullInfo/page"))
const Swap = loadable(() => import("@/src/app/swap/page"))
const Settings = loadable(() => import("@/src/app/settings/page"))
const RialDeposit = loadable(() => import("@/src/app/rialDeposit/page"))
const Redirect = loadable(() => import("@/src/app/redirect/page"))
const TopUp = loadable(() => import("@/src/app/topup/page"))
const Landing = loadable(() => import("@/src/app/landing"))

const KYCStatus = loadable(() => import("@/src/app/kyc/page"))
const KYCProcess = loadable(() => import("@/src/app/kyc/level1/page"))
const KYCProcessOTP = loadable(() => import("@/src/app/kyc/level1/otp/page"))
const KYCProcessBirthDate = loadable(() => import("@/src/app/kyc/level1/birthDate/page"))
const KYCProcessNationalId = loadable(() => import("@/src/app/kyc/level1/nationalId/page"))
const KYCProcessTos = loadable(() => import("@/src/app/kyc/level1/tos/page"))
const KYCProcessChecking = loadable(() => import("@/src/app/kyc/common/checking/page"))
const KYCProcessOK = loadable(() => import("@/src/app/kyc/common/ok/page"))
const KYCProcessError = loadable(() => import("@/src/app/kyc/common/error/page"))
const KYCProcessDocuments = loadable(() => import("@/src/app/kyc/level2/documents/page"))
const KYCProcessSelfie360 = loadable(() => import("@/src/app/kyc/level2/liveness/page"))
const KYCProcessSelfie = loadable(() => import("@/src/app/kyc/level2/selfie/page"))
const KYCLevel2Intro = loadable(() => import("@/src/app/kyc/level2/page"))
const KYCProcessUpload = loadable(() => import("@/src/app/kyc/level2/upload/page"))

const Information = loadable(() => import("@/src/app/information"))
const RialWithdraw = loadable(() => import("@/src/app/rialWithdraw/page"))
const RialWithdrawConfirm = loadable(() => import("@/src/app/rialWithdraw/confirm/page"))

const CashInCode = loadable(() => import("@/src/app/rialDeposit/cashInCode"))
const Gateway = loadable(() => import("@/src/app/rialDeposit/gateway"))

const UnderConstruction = loadable(() => import("@/src/app/underConstruction/page"))

function ApplicationRouter() {
    const location = useLocation()
    const analytics = useAnalytics()

    useEffect(() => {
        analytics.page()
    }, [location]);

    return (
        <Routes>
            <Route path="/">
                <Route index element={<Landing/>}/>
                <Route path="/telegram-router" element={<TelegramRouter/>}/>
                <Route path="/about" element={<Information type={"ABOUT"}/>}/>
                <Route path="/tos" element={<Information/>}/>
                <Route path="/aml" element={<Information/>}/>
                <Route path="/levels" element={<Information/>}/>

                <Route path="/under-construction" element={<UnderConstruction/>}/>

                <Route path={"login"} element={<Login/>}/>

                <Route path={"/new-phone"} element={<DisplayWrapper><NewPhone/></DisplayWrapper>}/>

                <Route path="result" element={<DisplayWrapper><ResultPage/></DisplayWrapper>}/>
                <Route path="/cheque/captcha" element={<ChequeCaptcha/>}/>
                <Route path="/dashboard">
                    <Route index element={<DisplayWrapper><MainPage/></DisplayWrapper>}/>
                    <Route path="result" element={<DisplayWrapper><ResultPage/></DisplayWrapper>}/>
                    <Route path="cheque/captcha" element={<ChequeCaptcha/>}/>
                </Route>

                <Route path={"invoice"} element={<Invoice/>}/>

                <Route path="payment">
                    <Route index element={<Payment/>}/>
                    <Route path="result" element={<PaymentResult/>}/>
                </Route>

                <Route path="/shop">
                    <Route index element={<Market/>}/>
                    <Route path={"giveaway"} element={<Giveaway/>}/>
                    <Route path={"telegram-premium"} element={<TelegramPremium/>}/>

                    <Route path="ad-cheque">
                        <Route index element={<AdChequeList/>}/>
                        <Route path={"new"} element={<NewAdCheque/>}/>
                        <Route path={"info"} element={<AdChequeInfo/>}/>
                    </Route>
                </Route>

                <Route path="transactions">
                    <Route index element={<DisplayWrapper><Transactions/></DisplayWrapper>}/>
                    <Route path=":id" element={<DisplayWrapper><TransactionInfo/></DisplayWrapper>}/>
                </Route>

                <Route path="swap" element={<DisplayWrapper><Swap/></DisplayWrapper>}/>

                <Route path="rial-deposit" element={<RialDeposit/>}>
                    <Route index element={<Gateway/>}/>
                    <Route path="cash-in-code" element={<CashInCode/>}/>
                </Route>

                <Route path="rial-withdraw">
                    <Route index element={<DisplayWrapper><RialWithdraw/></DisplayWrapper>}/>
                    <Route path="confirm" element={<DisplayWrapper><RialWithdrawConfirm/></DisplayWrapper>}/>
                </Route>

                <Route path="redirect" element={<DisplayWrapper><Redirect/></DisplayWrapper>}/>

                <Route path="topup" element={<DisplayWrapper><TopUp/></DisplayWrapper>}/>

                <Route path="settings" element={<DisplayWrapper><Settings/></DisplayWrapper>}/>

                <Route path="deposit" element={<DisplayWrapper><Deposit/></DisplayWrapper>}/>

                <Route path="withdraw">
                    <Route index element={<DisplayWrapper><Withdraw/></DisplayWrapper>}/>
                    <Route path="confirm" element={<DisplayWrapper><WithdrawConfirm/></DisplayWrapper>}/>
                    <Route path="info" element={<DisplayWrapper><WithdrawInfo/></DisplayWrapper>}/>
                    <Route path="types" element={<DisplayWrapper><WithdrawTypes/></DisplayWrapper>}/>
                    <Route path="send-to-wallet" element={<DisplayWrapper><WithdrawSendToWallet/></DisplayWrapper>}/>
                    <Route path="send-to-contact">
                        <Route path="enter-amount" element={<DisplayWrapper><InsertWithdrawAmount/></DisplayWrapper>}/>
                        <Route path="select-contact"
                            element={<DisplayWrapper><SelectContact/></DisplayWrapper>}>
                        </Route>
                    </Route>
                </Route>

                <Route path="kyc">
                    <Route index element={<DisplayWrapper><KYCStatus/></DisplayWrapper>}/>

                    <Route path="level-1">
                        <Route index element={<DisplayWrapper><KYCProcess/></DisplayWrapper>}/>
                        <Route path="otp" element={<DisplayWrapper><KYCProcessOTP/></DisplayWrapper>}/>
                        <Route path="birth-date"
                               element={<DisplayWrapper><KYCProcessBirthDate/></DisplayWrapper>}/>
                        <Route path="national-id"
                               element={<DisplayWrapper><KYCProcessNationalId/></DisplayWrapper>}/>
                        <Route path="tos" element={<DisplayWrapper><KYCProcessTos/></DisplayWrapper>}/>
                        <Route path="checking"
                               element={<DisplayWrapper><KYCProcessChecking/></DisplayWrapper>}/>
                        <Route path="ok" element={<DisplayWrapper><KYCProcessOK/></DisplayWrapper>}/>
                        <Route path="late" element={<DisplayWrapper><KYCProcessLate/></DisplayWrapper>}/>
                        <Route path="error" element={<DisplayWrapper><KYCProcessError/></DisplayWrapper>}/>
                    </Route>

                    <Route path="level-2">
                        <Route index element={<DisplayWrapper><KYCLevel2Intro/></DisplayWrapper>}/>
                        <Route path="documents"
                               element={<DisplayWrapper><KYCProcessDocuments/></DisplayWrapper>}/>
                        <Route path="liveness"
                               element={<DisplayWrapper><KYCProcessSelfie360/></DisplayWrapper>}/>
                        <Route path="selfie" element={<DisplayWrapper><KYCProcessSelfie/></DisplayWrapper>}/>
                        <Route path="upload" element={<DisplayWrapper><KYCProcessUpload/></DisplayWrapper>}/>
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<DisplayWrapper><MainPage/></DisplayWrapper>}/>
        </Routes>
    )
}

export default ApplicationRouter
