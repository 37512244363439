const productionConfig = require('./config.production')
const stagingConfig = require('./config.staging')
const developmentConfig = require('./config.development')

function getEnvConfig() {
    switch (process.env.REACT_APP_ENV) {
        case 'production':
            return productionConfig
        case 'staging':
            return stagingConfig
        case 'development':
            return developmentConfig
        default:
            throw new Error('REACT_APP_ENV not found!')
    }
}

module.exports = getEnvConfig()
