import {NumberFormatBase, useNumericFormat} from 'react-number-format';
import config from "@/src/config";
import {Input} from "antd";
import {digitsFaToEn} from "@persian-tools/persian-tools";
import {useState} from "react";

function AntInput(props) {
    const {
        highRes,
        displayType,
        valueIsNumericString,
        thousandSeparator,
        decimalScale,
        prefix,
        suffix,
        customInput,
        renderText,
        ...other
    } = props;

    return (
        <Input {...other} prefix={props.antPrefix} suffix={props.antSuffix} lang={"en"}/>
    )
}

const persianNumeral = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

function PersianNumberSupportInput(props) {
    const [separator, setSeparator] = useState(props.separator)
    const {removeFormatting, ...rest} = useNumericFormat({thousandSeparator: separator, ...props});

    const _removeFormatting = (val) => {

        // TODO: Fix cursor for persian input!
        persianNumeral.map(n => {
            if (val.includes(n)) {
                setSeparator("")
            }
        })

        return removeFormatting(digitsFaToEn(val.replace('٫', '.')));
    };

    return (
        <NumberFormatBase
            removeFormatting={_removeFormatting}
            {...rest}
        />
    );
}

function ensureNegativeNumber(n) {
    if (n.startsWith("-")) {
        return n
    }
    return "-" + n
}

function AmountNumber(props) {
    let unit, amount = null
    if (props.money) {
        unit = props.money.unit
        amount = props.isMinus ? ensureNegativeNumber(props.money.number) : props.money.number
    } else {
        unit = props.unit
        amount = props.isMinus ? "-" + props.amount : props.amount
    }

    const fontSize = props.size || 30
    const twoLevel = props.twoLevel === undefined ? true : props.twoLevel
    const fontWeight = props.fontWeight || 900
    const opacity = props.opacity || 1
    const prefix = unit === '$' ? '$' : undefined
    const suffix = unit !== '$' ? unit : undefined
    const decimals = (!!props.decimals || props.decimals === 0) ? props.decimals : (props.highRes ? config.decimals[unit] : config.humanReadableDecimals[unit])
    const separator = props.hideSeparator ? '' : ','

    const mainPartStyle = {
        fontSize,
        fontWeight,
        opacity,
    }

    const secondPartStyle = {
        ...mainPartStyle,
        opacity: opacity * 0.8,
        fontSize: fontSize / 1.2,
        fontWeight: fontWeight / 1,
    }

    const unitStyle = {
        ...secondPartStyle,
    }

    if (props.loading) {
        return (
            <span style={mainPartStyle}>...</span>
        )
    }

    return (
        <span style={{...props.style, color: props.color}}>
            <PersianNumberSupportInput
                value={amount}
                displayType={props.type || "text"}
                valueIsNumericString={true}
                setcaretposition={"false"}
                decimalScale={decimals}
                prefix={prefix}
                separator={separator}
                {...props}
                customInput={AntInput}
                renderText={(value) => {
                    const isMinusNumber = amount?.toString().startsWith('-')
                    if (value.split('.').length === 1 || !twoLevel) {
                        return (
                            <span dir={"ltr"}>
                                {!isMinusNumber && props.showSign && <span style={mainPartStyle}>+</span>}
                                <span style={mainPartStyle}>{value}</span>
                                {suffix && <span style={unitStyle}>{props.noSpaceSuffix ? '' : ' '}{suffix}</span>}
                            </span>
                        )
                    } else {
                        return (
                            <span dir={"ltr"}>
                                {!isMinusNumber && props.showSign && <span style={mainPartStyle}>+</span>}
                                <span style={mainPartStyle}>{value.split('.')[0]}</span>
                                <span style={secondPartStyle}>.</span>
                                <span style={secondPartStyle}>{value.split('.')[1]}</span>
                                {suffix && <span style={unitStyle}>{props.noSpaceSuffix ? '' : ' '}{suffix}</span>}
                            </span>
                        )
                    }
                }}
            />
        </span>
    )
}

export default AmountNumber
