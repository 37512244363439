import {useEffect, useState} from "react";
import Analytics from "analytics";
import config from "@/src/config";
import {AnalyticsProvider} from "use-analytics";
import googleAnalytics from "@analytics/google-analytics"

function SwapWalletAnalyticsProvider(props) {
    const [analytics, setAnalytics] = useState(
        Analytics({
            app: "swapwallet",
            plugins: [
                googleAnalytics({
                    measurementIds: ['G-F9YEYEK0T5'],
                }),
                {
                    name: 'console',
                    page: ({payload}) => {
                        console.log('page-view', payload)
                    },
                    track: ({payload}) => {
                        console.log('track', payload)
                    },
                    identify: ({payload}) => {
                        console.log('identify', payload)
                    }
                }
            ]
        })
    )

    let init = false
    useEffect(() => {
        if (config.enableAnalytics && !init) {
            console.log('NEW SENTRY')
            init = true

            import("@sentry/react").then(Sentry => {
                import("react-hotjar").then(({hotjar}) => {
                    hotjar.initialize(3825944, 6)

                    Sentry.init({
                        environment: config.env,
                        dsn: "https://5f995da89f49676604de31dbbbd5c77c@sentry.swapwallet.tech/2",
                        integrations: [
                            new Sentry.Replay({
                                maskAllText: false,
                                blockAllMedia: false,
                                networkDetailAllowUrls: [/(.*)swapwallet(.*)/, /https:\/\/swapwallet.app\/api\/(.*)/],
                            }),
                            new Sentry.BrowserTracing({
                                tracePropagationTargets: [/(.*)swapwallet(.*)/, /https:\/\/swapwallet.app\/api\/(.*)/],
                            }),
                        ],
                        tracePropagationTargets: [
                            /(.*)swapwallet(.*)/,
                        ],
                        tracesSampleRate: 1.0,
                        replaysSessionSampleRate: 1.0,
                        replaysOnErrorSampleRate: 1.0,
                    });

                    setAnalytics(
                        Analytics({
                            app: 'swapwallet',
                            plugins: [
                                {
                                    name: 'console',
                                    page: ({payload}) => {
                                        console.log('page-view', payload)
                                    },
                                    track: ({payload}) => {
                                        console.log('track', payload)
                                    },
                                    identify: ({payload}) => {
                                        console.log('identify', payload)
                                    }
                                },
                                googleAnalytics({
                                    measurementIds: ['G-F9YEYEK0T5'],
                                }),
                                {
                                    name: 'hotjar',
                                    page: ({payload}) => {
                                        hotjar.stateChange(payload.properties.url)
                                    },
                                    track: ({payload}) => {
                                        hotjar.event(payload.event)
                                    },
                                    identify: ({payload}) => {
                                        hotjar.identify(payload.userId, payload.traits);
                                    }
                                },
                                {
                                    name: 'sentry',
                                    page: ({payload}) => {},
                                    track: ({payload}) => {
                                        Sentry.addBreadcrumb({
                                            category: "event",
                                            message: payload.event,
                                            data: payload.properties,
                                            level: "info",
                                        })
                                    },
                                    identify: ({payload}) => {
                                        Sentry.setUser({id: payload.userId, username: payload.traits.username})
                                        Sentry.setTag("username", payload.traits.username)
                                        Sentry.setTag("name", payload.traits.displayName)
                                        Sentry.setTag("language", payload.traits.language)
                                        Sentry.setTag("kycLevel", payload.traits.level)
                                        Sentry.setTag("region", payload.traits.region)
                                    }
                                }
                            ]
                        })
                    )
                })
            })
        }
    }, [])

    return <>
        <AnalyticsProvider instance={analytics}>
            {props.children}
        </AnalyticsProvider>
    </>
}

export default SwapWalletAnalyticsProvider
