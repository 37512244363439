import common from './common'

const config = {
    ...common,
    enableAnalytics: true,
    api: {
        baseUrl: 'https://swapwallet.app/api',
    },
}

export default config;
