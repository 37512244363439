import common from './common'

const config = {
    ...common,
    api: {
        baseUrl: 'http://localhost:3000/api',
    },
    mockApi: true,
    enableAnalytics: false,
    storageVersion: 'dev-v2'
}

export default config;
