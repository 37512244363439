import {Drawer, Flex, List, message, Space, Tag, Typography} from "antd";
import React from "react";
import config from "@/src/config";
import AmountNumber from "@/src/common/components/amountNumber";
import zuluTheme from "@/src/utils/theme";
import "./style.css"

const {Text} = Typography

function CoinSelector(props) {
    const [messageApi, contextHolder] = message.useMessage();

    if (!props.userBalance) {
        return <></>
    }

    const balanceCopy = JSON.parse(JSON.stringify(props.userBalance))

    const hasIRT = balanceCopy.tokens.find(token => token.available.amount.unit === "IRT")
    if (props.isUserFromIran && !hasIRT) {
        balanceCopy.tokens.push({
            "name": "IRT",
            "available": {
                "amount": {
                    "number": "0",
                    "unit": "IRT",
                },
                "values": [
                    {
                        "number": "0",
                        "unit": "IRT",
                    },
                    {
                        "number": "0",
                        "unit": "USD",
                    }
                ]
            }
        })
    }

    const items = balanceCopy.tokens
        .sort((a, b) => (!!config.networks[b.available.amount.unit]) - (!!config.networks[a.available.amount.unit]))
        .sort((a, b) => (b.available.amount.unit === "HMSTR") - (a.available.amount.unit === "HMSTR"))
        .sort((a, b) => (b.available.amount.unit === "NOT") - (a.available.amount.unit === "NOT"))
        .sort((a, b) => (b.available.amount.unit === "IRT") - (a.available.amount.unit === "IRT"))
        .filter(t => props.type !== 'WITHDRAW' || !config.disabledWithdrawCoins.includes(t.available.amount.unit))
        .map(tokenBalance => tokenBalance.available).map(balance => {
            const balanceAmount = balance.amount
            const coin = balanceAmount.unit
            const usdValue = Number(balance.values.find(i => i.unit === 'USD').number)
            const isDisabled = (props.type === 'WITHDRAW' && config.disabledWithdrawCoins.includes(coin))
            const canBeTransfer = !isDisabled && !!config.networks[coin] || coin === 'IRT'

            return (
                <List.Item key={coin} style={{width: '100%', opacity: canBeTransfer ? 1 : .3}} onClick={() => {
                    if (canBeTransfer) {
                        props.onClick(coin)
                    } else {
                        messageApi.warning(config.local().withdrawAndDepositLimitedWarning)
                    }
                }}>
                    <Flex direction={"horizontal"} style={{width: '100%', alignItems: 'center'}}>
                        <Flex justify={"center"} align={"center"} style={{width: 40}}>
                            <img alt="coin" width={40} height={40} src={config.iconMappingCirclePath[coin]}
                                 style={{display: 'block'}}/>
                        </Flex>
                        <div style={{width: 20}}></div>
                        <Space direction='vertical' style={{width: '100%'}}>
                            <Flex justify={"space-between"} style={{marginTop: 0}}>
                                <Space>
                                    <Text style={{fontWeight: 400, fontSize: 16, marginBottom: -10}}>{coin}</Text>
                                    {coin === 'NOT' && <Tag color={"green"}>New</Tag>}
                                    {coin === 'HMSTR' && <Tag color={"green"}>New</Tag>}
                                </Space>
                                <AmountNumber money={balanceAmount} unit={props.unit} size={17} twoLevel={true}
                                              fontWeight={500}
                                              opacity={1}/>
                            </Flex>
                            <Flex justify={"space-between"} style={{marginTop: -10}}>
                                <Text style={{
                                    fontWeight: 300,
                                    fontSize: 12,
                                    marginBottom: -10,
                                    opacity: .6
                                }}>{config.local()[coin]}</Text>
                                <span dir='ltr' style={{fontWeight: 200, fontSize: 12}}>
                                <AmountNumber amount={usdValue} unit={'$'} size={11} twoLevel={false} fontWeight={100}
                                              opacity={.6}/>
                            </span>
                            </Flex>
                        </Space>
                    </Flex>
                </List.Item>
            )
        })

    return (
        <>
            {contextHolder}
            <Drawer
                title={config.local().pleaseSelectCoin}
                placement={"bottom"}
                // width={500}
                // size={"large"}
                height={'86%'}
                onClose={props.onClose}
                open={props.isOpen}
                className="my-drawer"
                styles={{
                    mask: {
                        backdropFilter: 'blur(1px)',
                    },
                    content: {
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                        backgroundColor: zuluTheme.token.colorBgBase,
                    },
                    body: {
                        padding: 0,
                    }
                }}
            >
                <List itemLayout="horizontal" style={{marginRight: 20, marginLeft: 20}}>
                    {items}
                </List>
            </Drawer>
        </>
    )
}

export default CoinSelector
