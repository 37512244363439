import telegram from './telegram'
import config from "@/src/config/common";

function getNamespacedKey(key, useVersion) {
    const namespace = telegram.getTelegramUserId()

    if (!useVersion) {
        return `${process.env.REACT_APP_ENV}:${namespace}:${key}:ALL`
    }
    const version = namespace === "WEB" ? config.storageWebVersion : config.storageTelegramVersion
    return `${process.env.REACT_APP_ENV}:${namespace}:${key}:${version}`
}

export function set(key, value, useVersion = true) {
    localStorage.setItem(getNamespacedKey(key, useVersion), JSON.stringify(value))
}

export function get(key, useVersion = true) {
    return JSON.parse(localStorage.getItem(getNamespacedKey(key, useVersion)))
}

function unset(key, useVersion = true) {
    localStorage.removeItem(getNamespacedKey(key, useVersion))
}

export default {
    get,
    set,
    unset,
}
